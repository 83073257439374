.select-chip {
  &__input {
    display: none;
  }

  &__label {
    // Import base button styles
    @include button-base;
    @include button-outlined;

    // Reset hover
    &:hover {
      background-color: inherit;
      border-color: inherit;
    }

    // Non Checked State
    &:not(&:has(.select-chip__input:checked)) {
      & .select-chip__icon {
        display: none;
      }

      &:not(&:has(.select-chip__input:disabled)):hover {
        background-color: var(--e-c-primary-01-50);
        border-color: var(--e-c-primary-01-200);
      }

      &:active {
        transition: border-color $trs-default;
        border-color: var(--e-c-primary-01-500);
      }

      &:has(.select-chip__input:disabled) {
        border-color: var(--e-c-mono-500);
        color: var(--e-c-mono-500);
      }
    }

    // Checked State
    &:has(.select-chip__input:checked) {
      transition: background-color $trs-default, border-color $trs-default, color $trs-default;
      background-color: var(--e-c-primary-01-700);
      border-color: var(--e-c-primary-01-700);
      color: white;

      .select-chip__icon e-icon {
        color: white;
        --fill-color: white;
      }

      &:hover {
        transition: background-color $trs-default, border-color $trs-default;
        background-color: var(--e-c-secondary-01-900);
        border-color: var(--e-c-secondary-01-900);
      }

      &:active {
        transition: background-color $trs-default, border-color $trs-default;
        background-color: var(--e-c-secondary-01-700);
        border-color: var(--e-c-secondary-01-700);
      }

      &:has(.select-chip__input:disabled) {
        border-color: var(--e-c-mono-200);
        color: var(--e-c-mono-700);

        &,
        &:hover {
          background-color: var(--e-c-mono-200);
        }

        e-icon {
          --fill-color: var(--e-c-mono-700);
        }
      }
    }

    // Disabled State
    &:has(.select-chip__input:disabled) {
      cursor: not-allowed;
    }
  }
}
