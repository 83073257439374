// stylelint-disable selector-max-universal
.box-product {
  position: relative;
  width: 100%;
  max-width: rem(480);
  padding: var(--e-space-8) var(--e-space-8) var(--e-space-8) var(--e-space-12);
  border-top-right-radius: var(--e-brd-radius-3);
  border-bottom-right-radius: var(--e-brd-radius-3);
  background-color: var(--e-c-mono-50);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: rem(12);
    background-color: var(--e-c-secondary-01-500);
  }

  @include bp(lg) {
    padding: var(--e-space-6) var(--e-space-6) var(--e-space-6) var(--e-space-8);
    max-width: none;
  }

  .box-product__title {
    margin-bottom: var(--e-space-6);

    h2 {
      @include type(400, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-4);

      h2 {
        @include type(300, strong);
      }
    }
  }

  .box-product__list {
    display: flex;
    grid-gap: var(--e-space-4);
    flex-wrap: wrap;

    > * {
      flex: 0 0 100%;
    }

    @include bp(lg) {
      > * {
        flex: 1 1 calc(50% - var(--e-space-2));
      }
    }

    @include bp(s) {
      > * {
        flex: 0 0 100%;
      }
    }
  }
}

e-box-product:not(:defined) {
  opacity: 0;
}
