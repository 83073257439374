[is='e-hotspot'] {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(44);
  height: rem(44);
  border-radius: 100%;
  border: 1px solid var(--e-c-mono-00);
  transform: translate(-50%, -50%);
  color: var(--e-c-mono-00);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2.5px);
  cursor: pointer;
  transition: all var(--e-trs-duration-faster) var(--e-trs-easing-default);

  .hotspot__inner-circle {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(28);
    height: rem(28);
    background-color: var(--e-c-mono-900);
    border: 0 solid var(--e-c-mono-00);
    border-radius: 100%;
    transition: all var(--e-trs-duration-faster) var(--e-trs-easing-default);
  }

  .hotspot__icon,
  .hotspot__icon-close {
    pointer-events: none;
    position: absolute;
  }

  .hotspot__icon-close {
    display: none;
  }

  &:hover {
    .hotspot__inner-circle {
      transform: scale(1.57);
      border-width: 2px;
    }
  }

  // Modifiers
  &.hotspot--is-close {
    z-index: 1;

    .hotspot__inner-circle {
      transform: scale(1.57);
      border-width: 2px;
      background-color: var(--e-c-mono-00);
      border-color: var(--e-c-mono-900);
    }

    .hotspot__icon {
      display: none;
    }

    .hotspot__icon-close {
      display: block;
      color: var(--e-c-mono-900);
    }
  }
}
