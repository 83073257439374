.menu {
  position: relative;
  display: inline-block;

  .menu__list-container {
    z-index: 999;
    position: absolute;
    min-width: 186px;
    border-radius: var(--e-brd-radius-1);
    box-shadow: var(--e-elevation-sm);
    background-color: var(--e-c-mono-00);

    &.menu__list-container--initial {
      display: none;
    }

    &.menu__list-container--open {
      display: flex;
      animation: menu--fade-in var(--e-trs-duration-faster) var(--e-trs-easing-default) forwards;
    }

    &.menu__list-container--close {
      animation: menu--fade-out var(--e-trs-duration-faster) var(--e-trs-easing-default) forwards;
    }

    &.menu__list-container--top {
      bottom: calc(100% + var(--e-space-4));
    }

    &.menu__list-container--bottom {
      top: calc(100% + var(--e-space-4));
    }

    &.menu__list-container--left {
      left: 0;
    }

    &.menu__list-container--right {
      right: 0;
    }
  }
}

@keyframes menu--fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes menu--fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
