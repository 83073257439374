@media print {
  .key-fact.key-fact {
    .key-fact__value {
      ::slotted(*) {
        @include type-200-strong;
      }
    }

    .key-fact__unit {
      ::slotted(*) {
        @include type-100-strong;
      }
    }
  }
}
