@mixin icon-button-icon {
  &::before {
    background-color: transparent;
    border-color: transparent;
  }

  e-icon {
    --fill-color: var(--e-c-primary-01-700);
  }

  &:hover {
    &::before {
      background-color: $c-primary-01-50;
      border-color: $c-primary-01-50;
    }
  }

  &:active {
    &::before {
      background-color: $c-primary-01-100;
      border-color: $c-primary-01-100;
    }
  }

  &:disabled {
    &::before {
      e-icon {
        --fill-color: var(--e-c-mono-500);
      }
    }
  }
}
