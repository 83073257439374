.teaser-grid {
  .teaser-grid__title {
    @include type(800, strong);

    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--e-space-10);
    color: var(--e-c-primary-01-500);
    text-align: center;

    @include bp(m) {
      @include type(700, strong);
      margin-bottom: var(--e-space-8);

      width: 100%;
    }

    @include bp(s) {
      @include type(500, strong);
    }
  }

  .teaser-grid__inner {
    @include grid-row;

    grid-row-gap: var(--e-space-16);

    @include bp(lg) {
      grid-row-gap: var(--e-space-16);
    }

    @include bp(m) {
      grid-row-gap: var(--e-space-6);
    }

    @include bp(s) {
      grid-row-gap: var(--e-space-8);
    }

    > * {
      @include grid-col(4);

      @include bp(m) {
        @include grid-col(12);
      }
    }
  }
}
