@mixin icon-button-icon-inverted {
  &::before {
    background-color: transparent;
    border-color: transparent;
  }

  e-icon {
    --fill-color: var(--e-c-mono-00);
  }

  &:hover {
    &::before {
      background-color: $c-primary-01-900;
      border-color: $c-primary-01-900;
    }
  }

  &:active {
    &::before {
      background-color: $c-secondary-01-900;
      border-color: $c-secondary-01-900;
    }
  }

  &:disabled {
    e-icon {
      --fill-color: var(--e-c-mono-500);
    }
  }
}
