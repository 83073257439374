@mixin button-outlined-inverted {
  background-color: transparent;
  border-color: $c-mono-00;
  color: $c-mono-00;

  e-icon {
    --fill-color: #{$c-mono-00};
  }

  ec-loader {
    --dot-color: #{$c-mono-00};
  }

  &:hover {
    background-color: rgba($c-primary-01-900, 0.7);
    border-color: $c-primary-01-200;
  }

  &:active,
  &[loading] {
    background-color: transparent;
    border-color: $c-primary-01-500;
  }

  &:disabled:not([loading]) {
    color: $c-mono-500;
    border: 2px solid $c-mono-500;
    background-color: transparent;

    e-icon {
      --fill-color: #{$c-mono-500};
    }
  }
}
