.faq-overview {
  padding-top: $section-default-padding-2xl;

  @include bp('2xl') {
    padding-top: $section-default-padding;
  }

  @include bp(lg) {
    padding-top: $section-default-padding-mobile;
  }

  .faq-overview__search-bar {
    @include bp(lg) {
      flex-direction: column-reverse;
      grid-gap: var(--e-space-6);
    }
  }

  .faq-overview__no-results {
    display: none;
    margin-top: var(--e-space-8);
    padding-bottom: $section-default-padding-2xl;

    @include bp('2xl') {
      padding-bottom: $section-default-padding;
    }

    @include bp(lg) {
      padding-bottom: $section-default-padding-mobile;
    }
  }

  .faq-overview__faqs {
    e-accordion + e-accordion .accordion {
      margin-top: calc(#{$section-default-padding-2xl} * -1);

      @include bp('2xl') {
        margin-top: calc(#{$section-default-padding} * -1);
      }

      @include bp(lg) {
        margin-top: calc(#{$section-default-padding-mobile} * -1);
      }
    }
  }

  // States
  &.faq-overview--no-results {
    .faq-overview__no-results {
      display: block;
    }
  }
}

e-faq-overview:not(:defined) {
  opacity: 0;
}
