[is='e-accordion-element'],
.accordion-element {
  .accordion-element__header {
    @include type(200, strong);

    // Unfortunately this is necessary so that gtm works. awesome...
    pointer-events: none;

    display: flex;
    justify-content: space-between;
    padding: calc(var(--e-space-4) - 2px) calc(var(--e-space-3) - 2px); // Subtract 2px for border
    grid-gap: var(--e-space-1);
    background-color: var(--e-c-mono-50);
    border-radius: var(--e-brd-radius-1);
    border: 2px solid var(--e-c-mono-50);
    color: var(--e-c-mono-700);
    cursor: pointer;
    transition: border $trs-default;

    .accordion-element__icon {
      line-height: 0;

      e-icon {
        display: inline-block;
        transition: transform $trs-default;
        perspective-origin: center;
        --fill-color: var(--e-c-primary-01-500);
      }
    }
  }

  // Because .accordion-element__header doesn't have pointer events we'll have to 'listen' to the parent hover state.
  // Analytics is so cool.
  .collapsible__header:hover {
    .accordion-element__header {
      border-color: var(--e-c-mono-100);
    }
  }

  .accordion-element__panel {
    padding: var(--e-space-4) var(--e-space-3);
    color: var(--e-c-mono-900);
  }

  // Modifiers
  &.accordion-element--inverted {
    .accordion-element__header {
      background-color: var(--e-c-mono-00);
      border-color: var(--e-c-mono-00);

      &:hover {
        border-color: var(--e-c-secondary-01-100);
      }
    }

    // Because .accordion-element__header doesn't have pointer events we'll have to 'listen' to the parent hover state.
    // Analytics is so cool.
    .collapsible__header:hover {
      .accordion-element__header {
        border-color: var(--e-c-secondary-01-100);
      }
    }
  }

  // States
  [is='e-collapsible'][data-state='is-expanding'],
  [is='e-collapsible'][data-state='is-expanded'] {
    .accordion-element__icon e-icon {
      transform: rotate(180deg);
    }
  }
}
