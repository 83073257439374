.testimonial {
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  .testimonial__quote {
    color: var(--e-c-secondary-02-500);
    margin-bottom: var(--e-space-8);

    blockquote {
      @include type(800, weak);
    }

    @include bp(m) {
      blockquote {
        @include type(600);
      }
    }

    @include bp(s) {
      blockquote {
        @include type(300);
      }
    }
  }

  .testimonial__name {
    * {
      @include type(200, strong);
    }
  }

  .testimonial__role {
    margin-bottom: var(--e-space-8);

    * {
      @include type(200);
    }
  }
}

e-testimonial:not(:defined) {
  opacity: 0;
}
