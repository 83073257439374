.header {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: var(--e-space-32);
  padding-bottom: var(--e-space-32);

  .header__overlay,
  .header__bg-video,
  .header__bg-img {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    user-select: none;
  }

  .header__bg-img,
  .header__bg-video {
    z-index: 0;
  }

  .header__overlay {
    z-index: 1;
  }

  .header__bg-video {
    // Video will be made visible when it's loaded and ready.
    opacity: 0;
  }

  .header__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .header__eye-brow {
    display: none;

    small,
    p {
      @include type(500, strong);

      @include bp(lg) {
        @include type(400, strong);
      }

      @include bp(m) {
        @include type(300, strong);
      }
    }
  }

  .header__title {
    display: none;

    h1,
    p {
      @include type(1000, strong);

      @include bp(lg) {
        @include type(800, strong);
      }
    }
  }

  .header__content-col {
    @include grid-col(9);

    @include bp(lg) {
      @include grid-col(8);
    }

    @include bp(m) {
      @include grid-col(12);
    }
  }

  .header__content {
    z-index: 4;
    position: relative;
    width: 100%;
    opacity: 0;
  }

  .header__lead {
    display: none;
    margin-top: var(--e-space-4);

    p {
      @include type(600);

      @include bp(lg) {
        @include type(300);
      }
    }
  }

  .header__cta {
    display: none;
    margin-top: var(--e-space-6);
  }

  .header__facts {
    z-index: 4;
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    opacity: 0;
  }

  @include bp(lg) {
    padding-top: var(--e-space-20);
    padding-bottom: var(--e-space-20);

    .header__lead {
      margin-top: var(--e-space-2);
    }
  }

  @include bp(m) {
    padding-top: var(--e-space-10);
    padding-bottom: var(--e-space-10);
    align-items: flex-start;
  }

  // Content column configurations
  &.header--has-bg {
    .header__content-col {
      @include grid-col(6);

      @include bp(lg) {
        @include grid-col(7);
      }

      @include bp(m) {
        @include grid-col(12);
      }
    }
  }

  // Modifiers
  &.header--has-eye-brow .header__eye-brow,
  &.header--has-title .header__title,
  &.header--has-lead .header__lead,
  &.header--has-cta .header__cta {
    display: block;
  }

  &.header--has-bg {
    height: rem(800);
    color: var(--e-c-mono-00);

    @include bp('2xl') {
      height: auto;
      min-height: rem(600);
    }

    @include bp(lg) {
      min-height: rem(500);
      max-height: rem(600);
    }

    .header__bg-img,
    .header__overlay {
      opacity: 0;
      display: block;
    }
  }

  &:not(.header--has-bg) {
    .header__content {
      opacity: 1;
    }
  }

  &.header--has-video {
    .header__bg-video {
      display: block;
    }
  }

  &.header--bg-ready {
    .header__overlay,
    .header__bg-img,
    .header__content,
    .header__facts {
      opacity: 1;
    }
  }

  &.header--has-overlay-opacity-weak,
  &.header--has-overlay-opacity-medium,
  &.header--has-overlay-opacity-strong {
    .header__overlay::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.header--has-overlay-opacity-weak .header__overlay::after {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 31.13%, rgba(0, 0, 0, 0) 90%);

    @include bp(m) {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 31.13%, rgba(0, 0, 0, 0) 90%);
    }
  }

  &.header--has-overlay-opacity-medium .header__overlay::after {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 31.13%, rgba(0, 0, 0, 0) 90%);

    @include bp(m) {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 31.13%, rgba(0, 0, 0, 0) 90%);
    }
  }

  &.header--has-overlay-opacity-strong .header__overlay::after {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 31.13%, rgba(0, 0, 0, 0) 90%);

    @include bp(m) {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 31.13%, rgba(0, 0, 0, 0) 90%);
    }
  }

  &.header--has-facts {
    padding-top: calc(var(--e-space-32) - (#{rem(66)} / 2));
    padding-bottom: calc(var(--e-space-32) + (#{rem(66)} / 2));

    @include bp(xl) {
      padding-top: calc(var(--e-space-32) - (#{rem(88)} / 2));
      padding-bottom: calc(var(--e-space-32) + (#{rem(88)} / 2));
    }

    @include bp(lg) {
      padding-top: var(--e-space-20);
      padding-bottom: calc(var(--e-space-20) + #{rem(168)});
    }

    .header__facts {
      display: block;
    }
  }

  &.header--show-video {
    .header__bg-img,
    .header__bg-video {
      transition: opacity var(--e-trs-duration-faster) var(--e-trs-easing-default);
    }

    .header__bg-img {
      opacity: 0;
    }

    .header__bg-video {
      opacity: 1;
    }
  }

  &.header--has-landingpage-nav {
    @include bp(m) {
      padding-top: var(--e-space-20);
    }
  }
}

e-header:not(:defined) {
  opacity: 0;
}
