.accordion {
  @include cms-section-spacing-normal;

  .accordion__title-row {
    grid-row-gap: var(--e-space-3);
    margin-bottom: var(--e-space-10);

    @include bp(lg) {
      grid-row-gap: var(--e-space-2);
    }
  }

  .accordion__title {
    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
      text-align: center;
      text-wrap: pretty;

      @include bp(lg) {
        @include type(700, strong);
      }

      @include bp(m) {
        @include type(500, strong);
      }
    }
  }

  .accordion__text {
    @include type(300, weak);

    text-align: center;
    text-wrap: pretty;

    @include bp(lg) {
      @include type(200, weak);
    }
  }

  .accordion__items,
  .accordion__left-col,
  .accordion__right-col {
    display: flex;
    flex-direction: column;
    gap: var(--e-space-2);
  }

  .accordion__left-col {
    @include bp(m) {
      margin-bottom: var(--e-space-2);
    }
  }

  &.accordion--bg-green {
    background-color: var(--e-c-secondary-01-50);
  }
}

e-accordion:not(:defined) {
  opacity: 0;
}
