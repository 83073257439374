.product-cards {
  // Don't use width option of parent cms-section wrapper.
  // This way it's easier to position the nav buttons.
  .glide-outer {
    @include grid-col-space(1, 12, 'padding-left');
    @include grid-col-space(1, 12, 'padding-right');

    @include bp('2xl') {
      @include grid-col-space(0, 12, 'padding-left');
      @include grid-col-space(0, 12, 'padding-right');
    }

    @include bp(xl) {
      @include grid-col-space(0, 12, 'padding-left');
      @include grid-col-space(0, 12, 'padding-right');
    }

    @include bp(lg) {
      @include grid-col-space(1, 12, 'padding-left');
      @include grid-col-space(1, 12, 'padding-right');
    }

    @include bp(m) {
      @include grid-col-space(2, 12, 'padding-left');
      @include grid-col-space(2, 12, 'padding-right');
    }

    @include bp(s) {
      @include grid-col-space(0, 12, 'padding-left');
      @include grid-col-space(0, 12, 'padding-right');
    }
  }

  .glide__slide {
    // "height: 100%;" doesn't work well for different height slides.
    height: auto;
  }

  .glide-outer {
    padding-bottom: var(--e-space-10);

    @include bp('lg') {
      padding-bottom: var(--e-space-8);
    }

    @include bp(m) {
      padding-bottom: var(--e-space-30);
    }
  }

  .glide__bullets {
    bottom: calc(var(--e-space-10) * -1);

    @include bp('lg') {
      bottom: calc(var(--e-space-8) * -1);
    }
  }

  .glide__arrow {
    top: calc((100% - var(--e-space-10)) / 2);

    @include bp('lg') {
      top: calc((100% - var(--e-space-8)) / 2);
    }

    @include bp(s) {
      top: auto;
      bottom: 0;
      transform: none;
    }
  }

  .glide__arrow--left {
    left: 0;

    @include bp('2xl') {
      left: calc(var(--glide-nav-button-size) / -2);
    }

    @include bp(lg) {
      left: 0;
    }

    @include bp(s) {
      left: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__arrow--right {
    right: 0;

    @include bp('2xl') {
      right: calc(var(--glide-nav-button-size) / -2);
    }

    @include bp(lg) {
      right: 0;
    }

    @include bp(s) {
      right: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .product-cards__title {
    margin-bottom: var(--e-space-10);
    text-align: center;

    > h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(lg) {
      > h2 {
        @include type(500, strong);
      }
    }
  }

  // Modifiers
  &.product-cards--two-items {
    // Don't use width option of parent cms-section wrapper.
    // This way it's easier to position the nav buttons.
    .glide-outer {
      @include grid-col-space(2, 12, 'padding-left');
      @include grid-col-space(2, 12, 'padding-right');

      @include bp('xl') {
        @include grid-col-space(0, 12, 'padding-left');
        @include grid-col-space(0, 12, 'padding-right');
      }

      @include bp(lg) {
        @include grid-col-space(1, 12, 'padding-left');
        @include grid-col-space(1, 12, 'padding-right');
      }

      @include bp(m) {
        @include grid-col-space(2, 12, 'padding-left');
        @include grid-col-space(2, 12, 'padding-right');
      }

      @include bp(s) {
        @include grid-col-space(0, 12, 'padding-left');
        @include grid-col-space(0, 12, 'padding-right');
      }
    }

    .glide-outer {
      padding-bottom: 0;

      @include bp(m) {
        padding-bottom: var(--e-space-8);
      }

      @include bp(s) {
        padding-bottom: var(--e-space-30);
      }
    }
  }

  &.product-cards--one-item {
    .glide-outer {
      @include grid-col-space(4, 12, 'padding-left');
      @include grid-col-space(4, 12, 'padding-right');

      padding-bottom: 0;

      @include bp('2xl') {
        @include grid-col-space(3.5, 12, 'padding-left');
        @include grid-col-space(3.5, 12, 'padding-right');
      }

      @include bp(xl) {
        @include grid-col-space(3, 12, 'padding-left');
        @include grid-col-space(3, 12, 'padding-right');
      }

      @include bp(lg) {
        @include grid-col-space(3, 12, 'padding-left');
        @include grid-col-space(3, 12, 'padding-right');
      }

      @include bp(m) {
        @include grid-col-space(2, 12, 'padding-left');
        @include grid-col-space(2, 12, 'padding-right');
      }

      @include bp(s) {
        @include grid-col-space(0, 12, 'padding-left');
        @include grid-col-space(0, 12, 'padding-right');
      }
    }
  }
}

e-product-card-carousel:not(:defined) {
  opacity: 0;
}
