[is='e-collapsible'],
.collapsible {
  .collapsible__heading {
    font-size: rem($body-font-size);
    font-weight: $body-font-weight;
    line-height: 1;
    color: inherit;
  }

  .collapsible__header {
    text-align: left;
    width: 100%;

    // Again, pointer is not really correct, but we use it for any interactive element.
    cursor: pointer;
  }

  .collapsible__panel {
    overflow: hidden;
    opacity: 0;
    height: 0;
  }

  // States
  &[data-state='is-expanding'] {
    .collapsible__panel {
      // height ist set by js because it's a calculated value.
      opacity: 1;
      transition: height $trs-default, opacity $trs-default;
    }
  }

  &[data-state='is-collapsing'] {
    .collapsible__panel {
      opacity: 0;
      transition: height $trs-default, opacity $trs-default;
    }
  }

  &[data-state='is-expanded'] {
    .collapsible__panel {
      height: auto;
      opacity: 1;
    }
  }
}
