/* stylelint-disable */
#CybotCookiebotDialog {
  &#CybotCookiebotDialog {
    font-family: Glober, Helvetica Neue, helvetica, arial, sans-serif;
    &.CybotEdge {
      padding-left:  210px;
      padding-right:  210px;
      @media (max-width: 1280px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      #CybotCookiebotDialogBodyEdgeMoreDetails {
        a {
          &:after {
            color: currentColor;
          }
        }
      }

      #CybotCookiebotDialogHeader{
        display: none;
      }
    }
  }
  #CybotCookiebotDialogPoweredByText {
    display: none;
  }
}
