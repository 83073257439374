@media print {
  .media__media {
    max-width: 25%;
    margin-bottom: var(--e-space-6);
  }

  .media {
    &.media--video {
      display: none;
    }
  }

  .media__caption {
    p {
      margin-left: var(--e-space-3);
    }
  }
}
