.download-item {
  position: relative;
  transform: scale(1); // Safari hack

  @include bp(lg) {
    display: grid;
    grid-template-columns: 50% 1fr rem(96);
    grid-template-rows: repeat(2, auto);
    width: 100%;
  }

  @include bp(m) {
    grid-template-columns: 1fr rem(80);
    grid-template-rows: repeat(3, auto);
  }

  .download-item__check {
    display: none;
  }

  .download-item__cell {
    @include type(200);

    border: var(--e-space-0_5) solid transparent;


    padding: var(--e-space-5) var(--e-space-6);
    background-color: var(--e-c-secondary-01-50);

    &:first-child {
      border-bottom-left-radius: var(--e-brd-radius-2);
      border-top-left-radius: var(--e-brd-radius-2);
      border-left: var(--e-space-0_5) solid transparent;
    }

    &:last-child {
      border-bottom-right-radius: var(--e-brd-radius-2);
      border-top-right-radius: var(--e-brd-radius-2);
      border-right: var(--e-space-0_5) solid transparent;
      padding-top: var(--e-space-2);
      padding-bottom: var(--e-space-2);
      text-align: right;
    }

    @include bp(lg) {
      @include type(100);

      display: flex;
      align-items: baseline;

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
        padding-top: var(--e-space-5);
        padding-bottom: var(--e-space-0_5);
        border-bottom-left-radius: 0;
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        padding-top: var(--e-space-0_5);
        padding-bottom: var(--e-space-5);
        border-bottom-left-radius: var(--e-brd-radius-2);
      }

      &:nth-child(3) {
        grid-area: 1 / 2 / 3 / 3;
      }

      &:nth-child(4) {
        grid-area: 1 / 3 / 3 / 4;
        display: flex;
        align-items: center;
      }
    }

    @include bp(m) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
        padding-right: 0;
      }

      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        padding-bottom: var(--e-space-0_5);
        padding-right: 0;
        border-bottom-left-radius: 0;
      }

      &:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;
        padding-top: var(--e-space-0_5);
        padding-right: 0;
        border-bottom-left-radius: var(--e-brd-radius-2);
      }

      &:nth-child(4) {
        align-items: flex-start;
        padding: var(--e-space-4);
        grid-area: 1 / 2 / 4 / 3;
      }
    }
  }

  .download-item__download {
    color: var(--e-c-primary-01-700);
  }

  .download-item__download-file {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(48);
    height: rem(48);
    margin-left: auto;

    e-icon {
      --fill-color: var(--e-c-primary-01-700);
    }
  }

  .download-item__cell-heading {
    @include type(50, strong);

    display: none;
    margin-right: var(--e-space-2);

    @include bp(lg) {
      display: inline-block;
    }
  }

  .download-item__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: var(--e-space-12);
    height: var(--e-space-12);
  }

  &:hover {
    .download-item__cell {
      @include bp(lg) {
        border: var(--e-space-0_5) solid transparent;

        &:first-child {
          border-left: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }

        &:nth-child(2) {
          border-left: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }

        &:nth-child(3) {
          border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }

        &:last-child {
          border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-right: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }
      }

      @include bp(m) {
        border: var(--e-space-0_5) solid transparent;

        &:first-child {
          border-left: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }

        &:nth-child(2) {
          border-left: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-bottom: var(--e-space-0_5) solid transparent;
        }

        &:nth-child(3) {
          border-top: var(--e-space-0_5) solid transparent;
          border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }

        &:last-child {
          border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
          border-right: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
        }
      }


      border-top: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
      border-bottom: var(--e-space-0_5) solid var(--e-c-secondary-01-100);

      &:first-child {
        border-left: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
      }

      &:last-child {
        border-right: var(--e-space-0_5) solid var(--e-c-secondary-01-100);
      }
    }
  }

  .download-item__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  &:focus-within {
    .download-item__cell {
      background: var(--e-c-secondary-01-100);
    }
  }

  &.download-item--visited {
    .download-item__cell {
      border-top: var(--e-space-0_5) solid transparent;
      border-bottom: var(--e-space-0_5) solid transparent;
      background: var(--e-c-mono-50);
    }

    .download-item__check {
      --fill-color: var(--e-c-secondary-05-500);
    }

    &:hover {
      .download-item__cell {
        border-top: var(--e-space-0_5) solid transparent;
        border-bottom: var(--e-space-0_5) solid transparent;

        &:first-child {
          border-left: var(--e-space-0_5) solid transparent;
        }

        &:last-child {
          border-right: var(--e-space-0_5) solid transparent;
        }
      }
    }

    .download-item__check {
      display: block;
    }

    .download-item__download {
      display: none;
    }
  }
}
