@use '../../../scss/01_abstracts/design-tokens' as tokens;

// ==== Helpers ====
@mixin useTransition($timing: tokens.$trs-duration-fast) {
  transition: background-color $timing tokens.$trs-easing-default,
  left $timing tokens.$trs-easing-default,
  right $timing tokens.$trs-easing-default,
  color $timing tokens.$trs-easing-default;
}

.toggle-switch.toggle-switch--big {
  // ==== Position and sizes (local variables) ====
  $iconPosition: tokens.$space-1;
  $iconSize: tokens.$space-10;
  $textActivePosition: tokens.$space-6;
  $textStartPosition: calc(tokens.$space-12 + tokens.$space-1);
  $textTopPosition: tokens.$space-3;
  $variantHeight: tokens.$space-12;
  $variantWidth: calc(tokens.$space-24 + tokens.$space-1);

  // Label (wrapper)
  .toggle-switch__label {
    @include useTransition();
    background-color: tokens.$c-mono-700;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    height: $variantHeight;
    padding: tokens.$space-1;
    place-content: center;
    position: relative;
    width: $variantWidth;

    // States: Hover + Active
    &:hover {
      background-color: tokens.$c-mono-200;
      .toggle-switch__text {
        color: black;
      }
    }

    &:has(~ .toggle-switch__input:checked):not(&:has(~ .toggle-switch__input:disabled)) {
      background-color: tokens.$c-primary-01-700;

      &:hover {
        background-color: tokens.$c-secondary-01-900;
      }
    }

    &:has(~ .toggle-switch__input:disabled) {
      cursor: not-allowed;
      background-color: tokens.$c-mono-50;

      .toggle-switch__text {
        color: tokens.$c-mono-500;
      }

      .toggle-switch__icon {
        color: tokens.$c-mono-500;
      }
    }

    // Label text (span)
    .toggle-switch__text {
      @include type(200, strong);
      color: white;
      left: $textStartPosition;
      position: absolute;
      right: auto;
      top: $textTopPosition;
    }

    // Label text active state
    &:has(~ .toggle-switch__input:checked) .toggle-switch__text {
      right: auto;
      left: $textActivePosition;
    }
    &:has(~ .toggle-switch__input:checked):not(&:has(~ .toggle-switch__input:disabled)) .toggle-switch__text {
      color: white;
    }

    // Label Icon (span)
    .toggle-switch__icon {
      background-color: white;
      border-radius: 100px;
      height: $iconSize;
      left: $iconPosition;
      padding: tokens.$space-2;
      position: absolute;
      right: auto;
      width: $iconSize;
      // Start position
      transform: translateX(0);
      transition: transform tokens.$trs-duration-fast;
    }

    // Label icon active state
    &:has(~ .toggle-switch__input:checked) .toggle-switch__icon {
      // (wrapper width - icon size) - (start position - border)
      transform: translateX(calc(($variantWidth - $iconSize) - ($iconPosition + tokens.$space-1)));
      transition: transform tokens.$trs-duration-fast;
    }
    &:has(~ .toggle-switch__input:checked):not(&:has(~ .toggle-switch__input:disabled)) .toggle-switch__icon {
      color: tokens.$c-primary-01-700;
    }

    &:has(~ .toggle-switch__input:checked):hover:not(&:has(~ .toggle-switch__input:disabled)) .toggle-switch__icon {
      color: tokens.$c-secondary-01-900;
    }
  }
}
