.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    margin-top: auto;
  }

  &.page--sticky-header {
    header {
      z-index: 1000;
      position: sticky;
      top: 0;
    }
  }
}
