@use '../../../scss/01_abstracts/design-tokens' as tokens;

// Local helper for create the bullets
@mixin createStepBullet($size) {
  width: $size;
  height: $size;
}

// Local helper for color states
@mixin useBackgroundColorVariants() {
  &.past {
    background-color: tokens.$c-primary-01-200;
  }
  &.future {
    background-color: tokens.$c-mono-500;
  }
  &.active {
    background-color: tokens.$c-primary-01-700;
  }
}

// Local helper for create each bullet size
@mixin useSizeVariants($small, $medium, $large) {
  &.small {
    @include createStepBullet($small)
  }
  &.medium {
    @include createStepBullet($medium)
  }
  &.large {
    @include createStepBullet($large)
  }
}

.timeline {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-column-gap: tokens.$space-10;
  grid-template-rows: auto;
  grid-template-areas: 'step description';
  position: relative;

  @include bp(lg) {
    grid-template-columns: 48px 1fr;
  }

  @include bp(m) {
    grid-template-columns: 40px 1fr;
    grid-row-gap: 0;
    grid-column-gap: tokens.$space-5;
  }

  // Left side
  .timeline__step {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: tokens.$space-14;
    grid-area: step;

    .timeline__step-line {
      height: 100%;
      width: 2px;
      position: absolute;
      background-color: tokens.$c-mono-500;

      @include useBackgroundColorVariants();

      &.hidden {
        display: none;
      }
    }

    .timeline__step-bullet {
      position: relative;
      z-index: 10;
      border-radius: 100%;
      border: 2px solid;
      background-color: tokens.$c-mono-00;
      display: flex;
      justify-content: center;
      align-items: center;

      @include useSizeVariants(tokens.$space-6, tokens.$space-8, tokens.$space-12);

      // Updates just for the large circle responsiveness
      @include bp(m) {
        &.large {
          @include createStepBullet(tokens.$space-10)
        }
      }

      .timeline__step-inner-bullet {
        border-radius: 100%;
        background-color: tokens.$c-mono-00;

        @include useSizeVariants(0, 10px, tokens.$space-7);

        // Updates just for the large circle responsiveness
        @include bp(m) {
          &.large {
            @include createStepBullet(tokens.$space-5)
          }
        }

        @include useBackgroundColorVariants()
      }
    }

    // Update bullet border based on state
    .timeline__step-bullet:has(> .active) {
      border-color: tokens.$c-primary-01-700;
    }

    .timeline__step-bullet:has(> .past) {
      border-color: tokens.$c-primary-01-200;
    }

    .timeline__step-bullet:has(> .future) {
      border-color: tokens.$c-mono-500;
    }
  }

  // Right side
  .timeline__description {
    display: grid;
    grid-template-columns: 1fr minmax(40%, 372px);
    grid-template-rows: 24px repeat(3, min-content);
    grid-template-areas:
      'date img'
      'title img'
      'text img'
      'links img';
    grid-area: description;
    grid-column-gap: tokens.$space-9;
    padding: tokens.$space-10 0;

    @include bp(lg) {
      display: flex;
      flex-direction: column;
      padding-right: tokens.$space-16;
    }

    @include bp(m) {
      padding-right: 0;
    }

    .timeline__description-date small {
      @include type(200, strong);
      line-height: tokens.$space-6;
      grid-area: date;

      @include bp(m) {
        @include type(100, strong);
      }
    }

    .timeline__description-title {
      @include type(600, strong);
      margin-top: tokens.$space-3;
      line-height: tokens.$space-9;
      color: tokens.$c-primary-01-500;
      grid-area: title;

      @include bp(lg) {
        @include type(500, strong);
        margin-top: tokens.$space-2;
      }

      @include bp(m) {
        @include type(400, strong);
      }
    }

    .timeline__description-image {
      grid-column-start: 2;
      grid-row-start: span 2;
      grid-area: img;
      cursor: pointer;

      @include bp(lg) {
        margin-top: tokens.$space-2;
      }

      @include bp(m) {
        width: 100%;
        max-width: 100%;
        margin-top: tokens.$space-2;
      }
    }

    .timeline__description-text {
      @include type(300);
      margin-top: tokens.$space-2;
      padding-bottom: tokens.$space-4;
      line-height: tokens.$space-7;
      color: tokens.$c-mono-900;
      grid-area: text;

      @include bp(lg) {
        @include type(200);
        margin-top: tokens.$space-4;
      }

      @include bp(m) {
        @include type(100);
        margin-top: 0;
        padding: tokens.$space-4 0;
      }
    }

    .timeline__description-links {
      display: flex;
      align-items: end;
      gap: tokens.$space-6;
      margin-top: tokens.$space-3;
      grid-area: links;

      @include bp(lg) {
        margin-top: tokens.$space-3;
      }

      @include bp(m) {
        flex-direction: column;
        align-items: start;
        margin-top: tokens.$space-2;
        gap: tokens.$space-4;
      }

      .timeline__link {
        display: flex;
        align-items: center;
        color: tokens.$c-primary-01-700;
        padding: 0;
        @include type(200, strong);

        .timeline__link-text {
          margin-left: tokens.$space-1;
          margin-right: tokens.$space-6;

          @include bp(m) {
            @include type(100, strong);
          }
        }
      }
    }
  }
}

