.search-bar {
  background-color: var(--e-c-mono-00);

  .search-bar__inner {
    position: relative;
    padding: var(--e-space-8) var(--e-space-10) var(--e-space-8) 0;
  }

  .search-bar__close-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--e-c-primary-01-700);
    cursor: pointer;
  }
}
