@use 'sass:math';
@import '../../../scss/01_abstracts/abstracts';
@import 'grid.mixin';

.row {
  @include grid-row;

  &.row--centered {
    justify-content: center;
  }
}
