@media print {
  .contact-card.contact-card {
    page-break-inside: avoid;

    .contact-card__card-container {
      padding: var(--e-space-3);

      .contact-card__cta, .contact-card__bg {
        display: none;
      }
    }

    .contact-card__form-container {
      display: none;
    }
  }
}
