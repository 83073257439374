@import 'https://js.arcgis.com/4.23/esri/themes/light/main.css';

// Revert global styles added by this map theme.
:root {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.evp-map {
  padding: var(--e-space-20) 0;

  .evp-map--top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--e-space-8);

    @include bp(lg) {
      align-items: flex-start;
      flex-direction: column;
      grid-gap: var(--e-space-10);
      margin-bottom: var(--e-space-10);
    }
  }

  .evp-map--title {
    @include type(800, strong);
    flex: 1 0 calc(100% - 500px);

    @include bp(xl) {
      flex: 1 0 calc(100% - 420px);
    }

    @include bp(m) {
      flex: 1 1 auto;
    }
  }

  .evp-map--search {
    flex: 1 0 500px;
    position: relative;

    @include bp(xl) {
      flex: 1 0 420px;
    }

    @include bp(lg) {
      flex: 1 1 auto;
      width: 100%;
    }

    .dropdown--suggestions {
      width: 100%;
      position: absolute;
      z-index: 10;
      left: 0;
      top: var(--e-space-15);
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);

      .dropdown--list {
        list-style: none;
        margin: 0;
        padding: 0;

        .dropdown--list-item {
          @include type(200);

          padding: 8px 8px 8px 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;

          &:focus,
          &:hover {
            background-color: rgba(220, 239, 213, 0.35);
            outline: none;
          }

          &.selected {
            background-color: var(--e-c-secondary-01-100)
          }
        }
      }
    }
  }

  .evp-map--map-outer {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 4px;
    //border: 1px solid var(--e-c-mono-500);
    //background-color: var(--e-c-mono-500);
    border: 1px solid var(--e-c-mono-100);
    background-color: var(--e-c-mono-100);

    &.is-ready {
      .evp-map--map {
        opacity: 1;
      }
    }

    @include bp(xl) {
      aspect-ratio: auto;
      height: 600px;
    }

    @include bp(lg) {
      aspect-ratio: 1;
      max-height: 700px;
      min-height: 405px;
      height: 75vh;
    }
  }

  .evp-map--map {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity var(--e-trs-easing-default);
  }

  // States
  &.is-hovering {
    cursor: pointer;
  }

  &.show-overlay {
    .evp-map--info-overlay {
      display: block;
    }
  }

  .evp-map--loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

e-evp-map {
  &:not(:defined) {
    opacity: 0;
  }
}

