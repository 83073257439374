.main-navigation {
  position: relative;
  border-bottom: 1px solid var(--e-c-mono-200);

  .main-navigation__search-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity $trs-default, visibility 1ms linear $trs-duration-faster;
  }

  // States
  &.main-navigation--search-active {
    .main-navigation__search-bar {
      opacity: 1;
      visibility: visible;
      transition: opacity $trs-default;
    }

    .top-bar {
      visibility: hidden;
    }
  }
}

e-main-navigation {
  .main-navigation__flyout-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-column-gap: var(--e-space-16);
    height: 100%;
  }

  .main-navigation__flyout-slot {
    padding-left: var(--e-space-1);
    display: flex;
    flex-direction: column;
  }

  .main-navigation__flyout-slot-bottom {
    margin-top: rem(18); // minimum distance to the links above.
    grid-area: 2 / 1 / 3 / 3;
  }

  [parent-id]:not(.is-visible) {
    display: none;
  }
}

e-main-navigation:not(:defined) {
  opacity: 0;
}
