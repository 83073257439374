.hotspot-modal {
  display: flex;
  flex-wrap: wrap;

  @include bp(xl) {
    flex-wrap: nowrap;
    grid-gap: $grid-gutter-2xl;
  }

  @include bp(lg) {
    grid-gap: $grid-gutter-lg;
  }

  @include bp(m) {
    flex-wrap: wrap;
  }

  .hotspot-modal__image,
  .hotspot-modal__content {
    flex: 1 0 100%;
  }

  .hotspot-modal__image {
    order: 1;
    display: none;
    width: 100%;
    aspect-ratio: 3/2;
    margin-bottom: var(--e-space-8);
    border-radius: var(--e-brd-radius-1);
    overflow: hidden;

    @include bp('2xl') {
      max-width: rem(250);
      margin-bottom: var(--e-space-6);
    }

    @include bp(xl) {
      flex: 0 0 rem(260);
      max-width: none;
      margin-bottom: 0;
    }

    @include bp(m) {
      flex: 1 0 100%;
      margin-bottom: var(--e-space-4);
    }
  }

  .hotspot-modal__content {
    order: 2;

    @include bp(xl) {
      flex: 0 1 auto;
    }

    @include bp(m) {
      flex: 1 0 100%;
    }
  }

  .hotspot-modal__title {
    margin-bottom: var(--e-space-2);

    h3 {
      @include type(700, strong);
    }

    @include bp(lg) {
      @include type(500, strong);
    }
  }

  // Modifiers
  &.hotspot-modal--has-image {
    .hotspot-modal__image {
      display: block;
    }
  }
}
