.svg-add-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-back-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-downward-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-forward-dims {
	width: 24px;
	height: 24px;
}

.svg-arrow-outward-dims {
	width: 24px;
	height: 24px;
}

.svg-backspace-dims {
	width: 24px;
	height: 24px;
}

.svg-burger-dims {
	width: 24px;
	height: 24px;
}

.svg-calendar-dims {
	width: 24px;
	height: 24px;
}

.svg-cancel-dims {
	width: 24px;
	height: 24px;
}

.svg-check-dims {
	width: 24px;
	height: 24px;
}

.svg-check-circle-dims {
	width: 25px;
	height: 24px;
}

.svg-chevron-down-dims {
	width: 24px;
	height: 24px;
}

.svg-chevron-left-dims {
	width: 24px;
	height: 24px;
}

.svg-chevron-right-dims {
	width: 24px;
	height: 24px;
}

.svg-chevron-right-circle-dims {
	width: 24px;
	height: 24px;
}

.svg-close-dims {
	width: 24px;
	height: 24px;
}

.svg-close-circle-dims {
	width: 24px;
	height: 24px;
}

.svg-dialpad-dims {
	width: 24px;
	height: 24px;
}

.svg-door-dims {
	width: 24px;
	height: 24px;
}

.svg-download-dims {
	width: 24px;
	height: 24px;
}

.svg-facebook-dims {
	width: 24px;
	height: 24px;
}

.svg-home-dims {
	width: 48px;
	height: 48px;
}

.svg-info-dims {
	width: 24px;
	height: 24px;
}

.svg-info-outlined-dims {
	width: 24px;
	height: 24px;
}

.svg-instagram-dims {
	width: 24px;
	height: 24px;
}

.svg-link-dims {
	width: 24px;
	height: 24px;
}

.svg-linkedin-dims {
	width: 24px;
	height: 24px;
}

.svg-location-dims {
	width: 24px;
	height: 24px;
}

.svg-menu-search-dims {
	width: 24px;
	height: 24px;
}

.svg-mini-check-dims {
	width: 24px;
	height: 24px;
}

.svg-phone-dims {
	width: 24px;
	height: 24px;
}

.svg-placeholder-dims {
	width: 24px;
	height: 24px;
}

.svg-plus-dims {
	width: 12px;
	height: 12px;
}

.svg-report-dims {
	width: 24px;
	height: 24px;
}

.svg-search-dims {
	width: 24px;
	height: 24px;
}

.svg-share-dims {
	width: 24px;
	height: 24px;
}

.svg-twitter-dims {
	width: 24px;
	height: 24px;
}

.svg-user-dims {
	width: 24px;
	height: 24px;
}

.svg-volume-off-dims {
	width: 24px;
	height: 24px;
}

.svg-volume-up-dims {
	width: 24px;
	height: 24px;
}

.svg-youtube-dims {
	width: 24px;
	height: 24px;
}

