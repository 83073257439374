/* stylelint-disable  selector-max-universal */

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $body-font;
  font-size: rem($body-font-size);
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  color: $body-font-color;
}

input {
  @include type(200); // Set this here because of specificity issues (with ::slotted)

  appearance: none;
  border: 0;
  background: none;

  ::placeholder {
    color: var(--e-c-mono-700);
  }
}

[type='search']::-webkit-search-cancel-button {
  display: none;
}

[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

select {
  @include type(200); // Set this here because of specificity issues (with ::slotted)

  appearance: none;
  border: 0;
  background: none;

  &:invalid {
    color: var(--e-c-mono-700);
  }
}

textarea {
  @include type(200); // Set this here because of specificity issues (with ::slotted)

  appearance: none;
  border: 0;
  background: none;
  resize: vertical;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

// Focus Handling
// There's still room for improvement here.
// Maybe we'll implement floating focus later on.
* {
  outline: none;
}

*:focus-visible {
  outline-style: solid;
  outline-offset: 4px;
  outline-color: rgba($c-mono-500, 0.8);
}

[data-whatintent='mouse'] *:focus {
  outline: none;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important; /* stylelint-disable-line */
    print-color-adjust: exact !important; /* stylelint-disable-line */
  }
}
