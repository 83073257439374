.audio-player {
  // Variables
  $mobile-breakpoint: rem(420);

  display: flex;
  align-items: center;
  width: 100%;
  column-gap: var(--e-space-6);
  row-gap: var(--e-space-5);
  container-type: inline-size;
  flex-wrap: wrap;

  .audio-player__transport {
    flex: 0 0 rem(64);
  }

  .audio-player__controls {
    display: flex;
    align-items: flex-start;
    flex: 1 0 calc(100% - #{rem(64)} - var(--e-space-6));
    column-gap: var(--e-space-6);

    @include bp(m) {
      flex: 0 0 100%;
    }
  }

  .audio-player__track {
    flex: 1 1 auto;
    margin-top: rem(8);
  }

  .audio-player__times {
    @include type(50);

    display: flex;
    justify-content: space-between;
    margin-top: rem(1);
  }

  .audio-player__volume {
    display: flex;
    align-items: center;
    flex: 0 0 rem(104);
    column-gap: var(--e-space-2_5);

    @include bp(m) {
      display: none;
    }
  }

  .audio-player__mute {
    color: var(--e-c-primary-01-900);
    cursor: pointer;
  }

  .audio-player__play {
    position: relative;
    width: var(--e-space-16);
    height: var(--e-space-16);
    background-color: var(--e-c-primary-01-500);
    border-radius: 100%;
    cursor: pointer;
    transition: opacity $trs-default;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(50%);
      display: block;
      width: rem(16);
      height: rem(24);
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h4v24H0zM12 0h4v24h-4z'/%3E%3C/svg%3E");
    }

    &:disabled {
      cursor: default;
      opacity: 0.75;
    }
  }

  .audio-player__range-slider {
    display: block;
    --range-width: 100%;
    --range-height: #{rem(8)};
    --range-border-radius: #{rem(4)};
    --range-thumb-height: #{rem(8)};
    --range-thumb-border-radius: #{rem(4)};
    --range-upper-fill-color: var(--e-c-mono-500);
    --range-lower-fill-color: var(--e-c-primary-01-700);
    --range-thumb-width: #{rem(8)};
    --range-thumb-background-color: var(--e-c-primary-01-700);
    --range-thumb-transition: transform var(--e-trs-duration-faster) ease;

    &:hover {
      --range-thumb-transform: scale(1.5);
    }
  }

  // It works, but the layout behaves still a bit 'unpredictable'.
  // @container (max-width: #{$mobile-breakpoint}) {
  //   .audio-player__volume {
  //     display: none;
  //   }

  //   .audio-player__controls {
  //     flex: 0 0 100%;
  //   }
  // }

  // States
  &.audio-player--paused {
    .audio-player__play {
      &::before {
        opacity: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% + 4px);
        display: block;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        border-style: solid;
        border-width: 16px 0 16px 24px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
}

e-audio-player:not(:defined) {
  opacity: 0;
}
