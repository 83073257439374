.faq-cards {
  .faq-cards__title {
    margin-bottom: var(--e-space-10);
    color: var(--e-c-primary-01-500);
    text-align: center;

    ::slotted(h2) {
      @include type(800, strong);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-5);

      ::slotted(h2) {
        @include type(700, strong);
      }
    }

    @include bp(m) {
      ::slotted(h2) {
        @include type(500, strong);
      }
    }
  }

  .faq-cards__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $grid-gutter-2xl;

    @include bp(lg) {
      grid-gap: $grid-gutter-lg;
    }

    @include bp(m) {
      grid-gap: var(--e-space-10);
      grid-template-columns: 1fr;
    }
  }

  &.faq-cards--single-card {
    .faq-cards__cards {
      @include grid-col-space(1, 10, padding-left);
      @include grid-col-space(1, 10, padding-right);

      display: block;

      @include bp(xl) {
        @include grid-col-space(1, 12, padding-left);
        @include grid-col-space(1, 12, padding-right);
      }

      @include bp(m) {
        @include grid-col-space(0, 12, padding-left);
        @include grid-col-space(0, 12, padding-right);
      }
    }
  }
}

e-faq-cards:not(:defined) {
  opacity: 0;
}
