.paginator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .paginator__counter {
    @include type(200);

    margin-bottom: var(--e-space-2);
    color: var(--e-c-mono-700);
    text-align: center;
  }

  .paginator__progress-bar {
    position: relative;
    margin-bottom: var(--e-space-8);
    width: 100%;
    height: rem(4);
    border-radius: var(--e-brd-radius-1);
    background-color: var(--e-c-mono-100);
    overflow: hidden;
  }

  .paginator__progress-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: rem(4);
    width: 0;
    background-color: var(--e-c-mono-900);
    transition: width $trs-default;
  }

  // States
  &.paginator--done {
    .paginator__next-cta {
      display: none;
    }
  }

  &.paginator--inactive {
    display: none;
  }
}
