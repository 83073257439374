.radio {
  display: flex;

  .radio__label {
    padding-top: var(--e-space-2);
    order: 2;

    label {
      @include type(200);

      display: block;
    }
  }

  .radio__input {
    z-index: 0;
    order: 1;
    position: relative;
    width: rem(40);
    height: rem(40);
    margin-right: var(--e-space-1);
    background-color: transparent;
    transition: background-color $trs-default;
    border-radius: 100%;

    input {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .radio__input-icon {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(20);
    height: rem(20);
    transform: translate(-50%, -50%);
    border: 2px solid var(--e-c-mono-900);
    border-radius: 100%;
    transition: border-color $trs-default;

    &::after {
      content: '';
      z-index: -2;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: rem(10);
      height: rem(10);
      transform: translate(-50%, -50%);
      border-radius: 100%;
      background-color: transparent;
      transition: background-color $trs-default;
    }
  }

  // States
  &.radio--hover {
    .radio__input {
      background-color: var(--e-c-primary-01-50);
    }

    .radio__input-icon {
      border-color: var(--e-c-primary-01-900);
    }
  }

  &.radio--focus {
    .radio__input {
      background-color: var(--e-c-primary-01-50);
    }
  }

  &.radio--checked {
    .radio__input-icon {
      border-color: var(--e-c-primary-01-900);

      &::after {
        background-color: var(--e-c-primary-01-900);
      }
    }

    &.radio--focus {
      .radio__input {
        background-color: var(--e-c-primary-01-100);
      }
    }
  }

  &.radio--disabled {
    pointer-events: none;
    opacity: 0.5;

    .radio__label {
      color: var(--e-c-mono-500);
    }

    .radio__input-icon {
      border-color: var(--e-c-mono-500);
    }

    &.radio--checked {
      .radio__input-icon::after {
        background-color: var(--e-c-mono-500);
      }
    }
  }
}

e-radio {
  display: block;

  &:not(:defined) {
    opacity: 0;
  }
}
