.not-found {
  position: relative;
  padding: var(--e-space-20) 0;
  overflow: hidden;

  @include bp(lg) {
    padding: var(--e-space-16) 0;
  }

  .not-found__spectro {
    position: absolute;
    top: rem(-1300 + 400);
    right: rem(-1300 + 540);
    width: rem(1300);

    @include bp('2xl') {
      right: rem(-1300 + 400);
    }

    @include bp(lg) {
      top: rem(-1300 + 300);
    }

    @include bp(lg) {
      top: rem(-700 + 128);
      right: rem(-700 + 300);
      width: rem(700);
    }
  }

  .not-found__inner {
    position: relative;
    padding-top: var(--e-space-10);
    padding-bottom: var(--e-space-10);

    @include grid-col(8, 12);

    @include grid-col-indent(1);


    @include bp(lg) {
      padding-top: var(--e-space-24);
      padding-bottom: var(--e-space-5);

      @include grid-col(10, 10);
      @include grid-col-indent(0);
    }
  }

  .not-found__title {
    margin-bottom: var(--e-space-4);

    @include type(900, strong);

    color: var(--e-c-primary-01-500);

    @include bp(lg) {
      margin-bottom: var(--e-space-2);

      @include type(700, strong);
    }
  }

  .not-found__text {
    @include type(600);

    @include bp(lg) {
      @include type(300);
    }
  }

  .not-found__form {
    margin-top: var(--e-space-9);
  }

  .not-found__info {
    margin-top: var(--e-space-3);
    color: var(--e-c-mono-900);

    @include type-100-weak;
  }

  .not-found__button {
    margin-top: var(--e-space-12);
    padding: 0;

    @include bp(lg) {
      margin-top: var(--e-space-6);
    }
  }
}
