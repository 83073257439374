@use 'sass:math';
@import '../../../scss/01_abstracts/abstracts';

/**
Helper to colculate col width in %.
Looks complicated because we use grid-gap on the row, which takes away from available width.
 */
@function col-width($size, $columns, $gutter) {
  @return math.div(100% - (($columns - 1) * $gutter), $columns) * $size + (($size - 1) * $gutter);
}

@mixin grid-row() {
  display: flex;
  flex-wrap: wrap;
  grid-gap: $grid-gutter-2xl;

  @include bp(lg) {
    grid-gap: $grid-gutter-lg;
  }

  @include bp(m) {
    grid-gap: $grid-gutter-m;
  }

  @include bp(s) {
    grid-gap: $grid-gutter-s;
  }
}


/**
Mixin to create a grid column cell.

$cols: Column width of cell.
$columns: Grid size (in columns). Default is set by global variable $grid-columns (defined via design token).
 */
@mixin grid-col($cols, $columns: $grid-columns) {
  $width-2xl: col-width($cols, $columns, $grid-gutter-2xl);

  flex: 0 0 $width-2xl;
  max-width: $width-2xl;

  @include bp(lg) {
    $width-lg: col-width($cols, $columns, $grid-gutter-lg);

    flex: 0 0 $width-lg;
    max-width: $width-lg;
  }

  @include bp(m) {
    $width-m: col-width($cols, $columns, $grid-gutter-m);

    flex: 0 0 $width-m;
    max-width: $width-m;
  }

  @include bp(s) {
    $width-s: col-width($cols, $columns, $grid-gutter-s);

    flex: 0 0 $width-s;
    max-width: $width-s;
  }
}

//TODO: Change args order -> $prop, $indent, $columns
@mixin grid-col-space($indent, $columns: $grid-columns, $prop: 'margin-left') {
  #{$prop}: col-width($indent, $columns, $grid-gutter-2xl) + $grid-gutter-2xl;

  @include bp(lg) {
    #{$prop}: col-width($indent, $columns, $grid-gutter-lg) + $grid-gutter-lg;
  }

  @include bp(m) {
    #{$prop}: col-width($indent, $columns, $grid-gutter-m) + $grid-gutter-m;
  }

  @include bp(s) {
    #{$prop}: col-width($indent, $columns, $grid-gutter-s) + $grid-gutter-s;
  }
}

@mixin grid-col-indent($indent, $columns: $grid-columns) {
  @include grid-col-space($indent, $columns);
}
