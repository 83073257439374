.footer {
  .footer__top-section {
    padding-top: var(--e-space-10);
    padding-bottom: var(--e-space-16);
    background-color: var(--e-c-mono-50);

    @include bp(lg) {
      padding-top: var(--e-space-8);
      padding-bottom: var(--e-space-10);
    }
  }

  .footer__top-section-row {
    display: flex;
    justify-content: space-between;

    @include bp(xl) {
      grid-gap: var(--e-space-8);
      flex-direction: column;
    }

    @include bp(m) {
      grid-gap: var(--e-space-10);
    }
  }

  .footer__address-columns {
    @include type(200);

    display: flex;
    grid-gap: var(--e-space-12);
    white-space: nowrap;

    a {
      @include text-link--secondary;
    }

    @include bp(lg) {
      @include type(100);
    }

    @include bp(m) {
      flex-direction: column;
      grid-gap: var(--e-space-6);
    }

    .emergency-column {
      @include bp(lg) {
        p > span {
          display: block;
        }
      }
    }
  }

  .footer__social-media-links {
    display: flex;
    grid-gap: var(--e-space-6);
  }

  .footer__bottom-section {
    background-color: var(--e-c-mono-100);
    padding-top: var(--e-space-8);
    padding-bottom: var(--e-space-6);
  }

  .footer__bottom-section-row {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include bp(m) {
      flex-direction: column;
    }
  }

  .footer__language-nav {
    position: absolute;
    top: 0;
    right: 0;

    @include bp(lg) {
      top: auto;
      bottom: 0;
    }

    @include bp(m) {
      position: relative;
      margin-bottom: calc(var(--e-space-8) + #{rem(18)});
    }
  }

  .footer__meta-navigation {
    @include type(100);

    display: flex;
    grid-gap: var(--e-space-6);
    margin-bottom: var(--e-space-3);

    a {
      @include text-link--secondary;
    }

    @include bp(lg) {
      @include type(50);
    }

    @include bp(m) {
      flex-direction: column;
      grid-gap: var(--e-space-4);
      margin-bottom: var(--e-space-8);
    }
  }

  .footer__copyright {
    @include type(100);

    color: var(--e-c-mono-500);

    @include bp(lg) {
      @include type(50);
    }

    @include bp(m) {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

e-footer:not(:defined) {
  opacity: 0;
}
