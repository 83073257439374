@mixin grid-container {
  margin: 0 auto;
  max-width: rem($layout-site-width);
  width: 100%;
  padding-left: rem($container-edge-2xl);
  padding-right: rem($container-edge-2xl);

  @include bp(lg) {
    padding-left: rem($container-edge-lg);
    padding-right: rem($container-edge-lg);
  }

  @include bp(m) {
    padding-left: rem($container-edge-m);
    padding-right: rem($container-edge-m);
  }

  @include bp(s) {
    padding-left: rem($container-edge-s);
    padding-right: rem($container-edge-s);
  }
}

.container {
  @include grid-container;
}
