@mixin button-outlined {
  background-color: transparent;
  color: $c-primary-01-700;
  border: 2px solid $c-primary-01-700;

  e-icon {
    --fill-color: #{$c-primary-01-700};
  }

  ec-loader {
    --dot-color: #{$c-primary-01-500};
  }

  &:hover {
    background-color: $c-primary-01-50;
    border-color: $c-primary-01-200;
  }

  &:active,
  &[loading] {
    background-color: transparent;
    border-color: $c-primary-01-500;
  }

  &:disabled:not([loading]) {
    border-color: var(--e-c-mono-500);
    background-color: transparent;
    color: var(--e-c-mono-500);

    e-icon {
      --fill-color: var(--e-c-mono-500);
    }
  }
}
