.video {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  // Force correct box-sizing. Just in case
  box-sizing: border-box;

  .video__poster {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .video__poster-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .video__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(100);
    height: rem(100);

    @include bp(lg) {
      width: rem(58);
      height: rem(58);
    }
  }

  .video__iframe {
    display: block;
    width: 100%;
    height: 100%;

    &.cookieconsent-optout-marketing {
      padding: var(--e-space-2);
    }

    // Active cookiebot, but not accepted.
    &[data-cookieblock-src]:not([src]) {
      display: none;
    }

    // Accepted cookies, or no cookiebot at all.
    &[src] + .cookieconsent-optout-marketing {
      display: none;
    }
  }
}
