.search-result-item {
  position: relative;
  display: block;
  padding: var(--e-space-6) var(--e-space-24) var(--e-space-6) var(--e-space-6);
  text-decoration: none;
  color: var(--e-c-mono-900);
  transition: background-color $trs-default;
  border-radius: var(--e-brd-radius-1);

  &:hover {
    background-color: var(--e-c-primary-01-50);

    .icon-button {
      @include icon-button-hover-state;
      @include icon-button-outline-hover-state;
    }
  }

  .search-result-item__title,
  .search-result-item__text,
  .search-result-item__breadcrumb {
    max-width: rem(732);
  }

  .search-result-item__title {
    @include type(300, strong);

    display: flex;
    align-items: center;
    column-gap: var(--e-space-2_5);

    @include bp(lg) {
      align-items: flex-start;
      column-gap: 0;
      row-gap: var(--e-space-2);
      flex-direction: column-reverse;
    }
  }

  .search-result-item__title-tags {
    display: none;
  }

  .search-result-item__text {
    @include type(200);

    margin-bottom: var(--e-space-2);
  }

  .search-result-item__breadcrumb {
    @include type(50);

    margin-top: var(--e-space-2);
    color: var(--e-c-mono-700);
  }

  .search-result-item__icon {
    position: absolute;
    right: var(--e-space-6);
    top: 50%;
    transform: translateY(-50%);

    &.download {
      display: none;
    }
  }

  // Modifiers
  &.search-result-item--is-download {
    .search-result-item__title-tags {
      display: inline;
    }

    .search-result-item__icon {
      display: none;

      &.download {
        display: block;
      }
    }
  }

  &.search-result-item--skeleton {
    pointer-events: none;

    .search-result-item__icon {
      display: none;
    }

    .search-result-item__title {
      margin-bottom: var(--e-space-1);
    }

    .search-result-item__title,
    .search-result-item__text,
    .search-result-item__breadcrumb {
      &::before {
        @include skeleton-background;

        content: '_';
        display: inline-block;
      }
    }

    .search-result-item__title::before {
      width: rem(120);
    }

    .search-result-item__text::before {
      width: rem(300);
      vertical-align: middle;
    }

    .search-result-item__breadcrumb::before {
      width: rem(80);
    }
  }
}
