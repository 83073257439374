// Inspired by https://mui.com/material-ui/react-skeleton/

@keyframes skeleton-bg-loader {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

/**
This mixin will simply add an animated background.
 */
@mixin skeleton-background {
  background-color: var(--e-c-mono-200);
  animation-name: skeleton-bg-loader;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: var(--e-trs-easing-default);
  border-radius: var(--e-brd-radius-1);
  overflow: hidden;
  color: transparent;
}
