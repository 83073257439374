.contact-card,
[is='e-contact-card'] {
  position: relative;
  overflow: hidden;
  border-radius: var(--e-brd-radius-3);
  box-shadow: var(--e-elevation-md);

  .contact-card__card-container {
    opacity: 1;
    padding: var(--e-space-16) rem(440) var(--e-space-16) var(--e-space-20);
    background-color: var(--e-c-secondary-01-50);
    transition: opacity $trs-default 20ms;

    @include bp(xl) {
      padding: var(--e-space-10) var(--e-space-10) var(--e-space-16);
    }

    @include bp(m) {
      padding: var(--e-space-6) var(--e-space-6) var(--e-space-36);
    }
  }

  .contact-card__title {
    margin-bottom: var(--e-space-4);

    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-700);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-2);

      h2 {
        @include type(500);
      }
    }
  }

  .contact-card__text {
    @include type(300);

    margin-bottom: var(--e-space-6);

    @include bp(lg) {
      @include type(200);
    }
  }

  .contact-card__bg {
    pointer-events: none;
    position: absolute;
    top: rem(-200);
    right: calc(#{rem(-1350)} + 500px);
    width: rem(1350);

    @include bp(xl) {
      top: auto;
      right: rem(-650 + 420);
      bottom: rem(-650 + 140);
      width: rem(650);
    }
  }

  .contact-card__contact-container {
    display: flex;
    grid-gap: var(--e-space-6);

    @include bp(m) {
      flex-direction: column;
    }
  }

  .contact-card__image {
    display: none;
    overflow: hidden;
    width: rem(120);
    height: rem(120);
    border-radius: 100%;
    border: 1px solid var(--e-c-mono-200);
  }

  .contact-card__role {
    @include type(300, strong);

    display: none;
    margin-bottom: var(--e-space-4);
  }

  .contact-card__contact {
    display: table;

    @include bp(m) {
      display: block;
    }
  }

  .contact-card__contact-item {
    @include type(200);

    display: table-row;

    dt,
    dd {
      display: table-cell;
      padding-bottom: var(--e-space-4);
    }

    dt {
      padding-right: var(--e-space-2);
    }

    &:last-child {
      dt,
      dd {
        padding-bottom: 0;
      }
    }

    @include bp(m) {
      display: block;

      dt {
        padding-bottom: 0;
      }

      dt,
      dd {
        display: block;
      }
    }
  }

  .contact-card__cta {
    margin-top: var(--e-space-12);

    @include bp(lg) {
      margin-top: var(--e-space-10);
    }
  }

  .contact-card__form-container {
    position: absolute;
    visibility: hidden;
    padding: var(--e-space-16) var(--e-space-20);
    opacity: 0;
    border: 1px solid var(--e-c-mono-200);
    border-radius: var(--e-brd-radius-3);

    @include bp(lg) {
      padding: var(--e-space-10) var(--e-space-10) var(--e-space-16);
    }

    @include bp(m) {
      padding: var(--e-space-10) var(--e-space-5);
    }
  }

  // Modifiers
  &.contact-card--has-job-title {
    .contact-card__role {
      display: block;
    }
  }

  &.contact-card--has-image {
    .contact-card__image {
      display: block;
    }

    .contact-card__text {
      margin-bottom: var(--e-space-10);
    }

    @include bp(lg) {
      .contact-card__text {
        margin-bottom: var(--e-space-8);
      }
    }

    @include bp(m) {
      .contact-card__text {
        margin-bottom: var(--e-space-6);
      }
    }
  }

  &.contact-card--show-form {
    .contact-card__card-container {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    .contact-card__form-container {
      position: static;
      visibility: visible;
      opacity: 1;
      transition: opacity $trs-default 20ms;
    }
  }
}

.hide-e360-branding {
  .contact-card__bg {
    display: none;
  }
}
