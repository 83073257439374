.download-list {
  margin-top: calc(var(--e-space-2) * -1);
  margin-bottom: calc(var(--e-space-2) * -1);
  border-collapse: separate;
  border-spacing: 0 var(--e-space-2);
  width: 100%;

  thead {
    @include bp(lg) {
      @include visually-hidden;
    }
  }

  tbody {
    @include bp(lg) {
      display: block;
    }
  }

  @include bp(lg) {
    display: block;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;

    .download-item + .download-item {
      margin-top: var(--e-space-2);
    }
  }

  .download-list__heading {
    @include type(200, strong);

    padding: var(--e-space-2) var(--e-space-6);
    text-align: left;
  }
}
