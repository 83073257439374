.input {
  position: relative;
  min-height: rem(48);
  width: 100%;

  .input__wrapper {
    position: relative;
  }

  .input__label {
    @include type(200);

    z-index: 1;
    position: absolute;
    top: 50%;
    left: var(--e-space-3);
    padding: 0 var(--e-space-1);
    color: var(--e-c-mono-700);
    transform: translateY(-50%);
    background-color: var(--e-c-mono-00);
    border-radius: var(--e-brd-radius-1);
    transition: top $trs-default, left $trs-default, font-size $trs-default, color $trs-default, line-height $trs-default;
  }

  .input__control {
    @include type(200);

    position: relative;

    select,
    textarea,
    input {
      padding: var(--e-space-3) var(--e-space-4);
      transition: box-shadow $trs-default;
      width: 100%;
      color: transparent;
    }

    textarea {
      display: block;
      min-height: rem(100);
    }
  }

  .input__control-border {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--e-brd-radius-2);
    border: 1px solid var(--e-c-mono-900);
    transition: border $trs-default;
  }

  .input__icon-left {
    position: absolute;
    top: 50%;
    left: var(--e-space-4);
    transform: translateY(-50%);
  }

  .input__icon-right {
    @include type(200);

    position: absolute;
    top: 50%;
    right: var(--e-space-4);
    transform: translateY(-50%);
  }

  .input__error-text,
  .input__help-text {
    @include type(50);

    margin-top: var(--e-space-1);
    padding-left: var(--e-space-4);
    color: var(--e-c-mono-700);
  }

  .input__error-text {
    display: none;
    color: var(--e-c-signal-03-700);
  }

  .input__help-text {
    display: none;

    &.has-content {
      display: block;
    }
  }

  .input__search-clear-button {
    position: absolute;
    top: 50%;
    right: var(--e-space-4);
    display: none;
    transform: translateY(-50%);

    e-icon {
      --fill-color: var(--e-c-primary-01-900);
    }
  }

  // Modifiers

  // --- TEXTAREA ---
  // Maybe use this trick for textarea https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  &.input--textarea {
    .input__label {
      top: rem(24); // Make label appar top aligned
    }
  }

  // --- SELECT ---
  &.input--select {
    .input__control {
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: var(--e-space-4);
        width: rem(16);
        height: rem(16);
        transform: translateY(-50%);
        // TODO: find a better solution for this.
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.06 5.53 8 8.583 4.94 5.53 4 6.47l4 4 4-4-.94-.94Z' fill='%23333'/%3E%3C/svg%3E");
      }

      select {
        padding-right: var(--e-space-12);
      }
    }
  }

  // --- TYPE = SEARCH ---
  &.input--search {
    .input__label {
      left: var(--e-space-12);
    }

    &.input--float-label {
      .input__label {
        left: var(--e-space-3);
      }
    }

    .input__control input {
      padding-left: var(--e-space-12);
    }

    // Imitate default behaviour for showing 'x' clear button.
    &.input--hover,
    &.input--focus {
      &.input--has-value {
        .input__search-clear-button {
          display: block;
        }
      }
    }
  }

  // -- TYPE = DATE ---
  &.input--date {
    .input__icon-right {
      pointer-events: none;
      color: var(--e-c-primary-01-900);
    }

    &.input--error {
      .input__icon-right {
        color: var(--e-c-signal-03-700);
      }
    }
  }

  &.input--has-unit {
    .input__control input {
      padding-right: var(--e-space-12);
    }
  }

  // States
  &.input--hover,
  &.input--focus {
    .input__control-border {
      border: 2px solid var(--e-c-primary-01-900);
    }
  }

  &.input--float-label {
    .input__label {
      @include type(50);

      top: rem(1);
      color: var(--e-c-mono-900);
    }

    .input__control {
      select,
      textarea,
      input {
        color: var(--e-c-mono-900);
      }
    }
  }

  &.input--focus.input--float-label {
    .input__label {
      color: var(--e-c-primary-01-900);
    }
  }

  &.input--disabled {
    pointer-events: none;

    .input__control-border {
      border: 1px solid var(--e-c-mono-500);
    }

    .input__help-text,
    .input__label,
    .input__icon-left,
    .input__icon-right,
    .input__control {
      color: var(--e-c-mono-500);
    }
  }

  &.input--error,
  &.input--error.input--float-label {
    .input__label {
      color: var(--e-c-signal-03-700);
    }

    .input__control-border {
      border: 2px solid var(--e-c-signal-03-700);
    }

    .input__help-text {
      display: none;
    }

    .input__error-text {
      display: block;
    }
  }

  // Layout Modifiers
  &.input--layout-compact {
    min-height: rem(40);

    .input__control-border {
      opacity: 0;
    }

    .input__control {
      select,
      textarea,
      input {
        padding: var(--e-space-2) var(--e-space-3) var(--e-space-2) var(--e-space-8);
      }
    }

    .input__icon-left {
      left: 0;
    }
  }
}

// Because of specificity issues we define some styles like this.
e-input {
  display: block;

  &:not(:defined) {
    opacity: 0;
  }

  textarea,
  input {
    &:disabled {
      color: var(--e-c-mono-500);
    }
  }
}
