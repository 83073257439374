@media print {
  .cms-section {
    padding: 0 !important;
    margin: 0 !important;
  }

  e-cms-section {
    &e-cms-section {
      display: table;
    }
  }
}
