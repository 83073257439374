@use '../../../scss/01_abstracts/design-tokens' as tokens;

.select-tile {
  padding: tokens.$space-6;
  border: 2px solid tokens.$c-mono-500;
  border-radius: tokens.$space-2;
  height: 100%;
  cursor: pointer;

  .select-tile__input, .select-tile__radio {
    display: none;
  }

  .select-tile__label {
    cursor: pointer;
    display: block;

    .select-tile__icon {
      position: relative;
      width: tokens.$space-6;
      height: tokens.$space-6;
      margin-bottom: tokens.$space-4;
      display: block;
    }

    .select-tile__text {
      .select-tile__title {
        display: block;
        @include type(400, strong);
        color: tokens.$c-mono-900;
      }

      .select-tile__subtitle {
        display: block;
        @include type(300);
        color: tokens.$c-mono-900;
      }
    }
  }

  // Label with radio icon variant
  &.select-tile--with-radio {
    .select-tile__label {
      column-gap: tokens.$space-4;
      display: grid;
      grid-template-columns: tokens.$space-10 1fr;
      height: 100%;

      .select-tile__icon {
        display: none;
      }
    }

    &:not(&:has(.select-tile__input:disabled)):hover .select-tile__radio-wrapper {
      background-color: tokens.$c-primary-01-50;
    }

    .select-tile__radio-wrapper {
      width: tokens.$space-10;
      height: tokens.$space-10;
      padding: tokens.$space-2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      align-self: center;

      .select-tile__radio {
        display: block;
        width: tokens.$space-6;
        height: tokens.$space-6;
        border: 2px solid black;
        border-radius: 100%;
      }
    }
  }

  // Checked State (but not disabled)
  &:has(.select-tile__input:checked):not(&:has(.select-tile__input:disabled)) {
    background-color: tokens.$c-primary-01-50;
    border: 2px solid tokens.$c-primary-01-200;

    .select-tile:hover {
      border: 2px solid tokens.$c-primary-01-700;
    }

    .select-tile__label .select-tile__radio-wrapper .select-tile__radio {
      border: 6px solid tokens.$c-primary-01-900;
    }

    .select-tile__label .select-tile__text .select-tile__icon,
    .select-tile__label .select-tile__text .select-tile__title, {
      color: tokens.$c-primary-01-700;
    }
    .select-tile__label .select-tile__text .select-tile__subtitle {
      color: tokens.$c-primary-01-500;
    }
  }

  // Disabled State
  &:has(.select-tile__input:disabled) {
    cursor: not-allowed;
    border: 2px solid tokens.$c-mono-500;
    background-color: transparent;

    &,
    .select-tile__label,
    .select-tile__input {
      pointer-events: none;
    }

    &.select-tile--with-radio .select-tile__label .select-tile__radio-wrapper .select-tile__radio {
      border: 2px solid tokens.$c-mono-200;
    }

    // Checked State (disabled)
    &:has(.select-tile__input:checked) {
      background-color: tokens.$c-mono-50;
    }

    &:has(.select-tile__input:checked).select-tile--with-radio .select-tile__label .select-tile__radio-wrapper {
      border: 2px solid tokens.$c-mono-200;
      width: tokens.$space-6;
      height: tokens.$space-6;
      padding: 0;
      justify-self: center;

      .select-tile__radio {
        width: tokens.$space-2;
        height: tokens.$space-2;
        background-color: tokens.$c-mono-200;
      }
    }

    // Update text color
    .select-tile__label .select-tile__text {
      .select-tile__icon, .select-tile__title {
        color: tokens.$c-mono-500;
      }
      .select-tile__subtitle {
        color: tokens.$c-mono-200;
      }
    }
  }

  // Active State
  &.pressed {
    animation-name: tilePressed;
    animation-delay: 100ms;
    animation-duration: tokens.$trs-duration-fast;

    .select-tile__label .select-tile__radio-wrapper {
      background-color: tokens.$c-primary-01-50;
    }
  }
}

@keyframes tilePressed {
  0% {
    transform: scale(1);
  } 50% {
    transform: scale(.98);
  } 100% {
    transform: scale(1);
  }
}
