.job-card {
  display: block;
  padding: var(--e-space-8);
  border-radius: var(--e-brd-radius-2);
  box-shadow: var(--e-elevation-sm);
  background-color: var(--e-c-mono-00);
  text-decoration: none;
  transition: box-shadow $trs-default;
  height: 100%;

  &:hover {
    box-shadow: 0 0 0 2px var(--e-c-secondary-01-100) inset;

    .job-card__title {
      color: var(--e-c-primary-01-500);
    }

    .job-card__location {
      color: var(--e-c-mono-200);

      e-icon {
        --fill-color: var(--e-c-mono-200);
      }
    }

    .job-card__percentage {
      color: var(--e-c-secondary-05-200);
    }
  }

  @include bp(lg) {
    padding: var(--e-space-6);
  }

  .job-card__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .job-card__title {
    margin-bottom: var(--e-space-6);

    @include type(400, strong);

    color: var(--e-c-mono-900);
    transition: color $trs-default;


    @include bp(lg) {
      margin-bottom: var(--e-space-4);

      @include type(300, strong);
    }
  }

  .job-card__info {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: flex-start;
  }

  .job-card__location {
    @include type(200);

    display: flex;
    align-items: center;
    grid-gap: var(--e-space-1);
    color: var(--e-c-mono-900);
    transition: color $trs-default;

    e-icon {
      --fill-color: var(--e-c-mono-500);
      --transition: fill #{$trs-default};
    }

    @include bp(lg) {
      @include type(100);
    }
  }

  .job-card__percentage {
    @include type(200);

    display: block;
    padding: var(--e-space-0_5) var(--e-space-1);
    color: var(--e-c-secondary-05-900);
    background-color: var(--e-c-secondary-05-100);
    border-radius: var(--e-brd-radius-1);
    transition: color $trs-default;

    @include bp(lg) {
      @include type(100);
    }
  }
}
