@keyframes open-menu {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes close-menu {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes open-level-2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes close-level-2 {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

.mobile-menu {
  .mobile-menu__main-layer {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: var(--e-c-mono-00);
  }

  .mobile-menu__close-button {
    z-index: 50;
    position: absolute;
    top: var(--e-space-4);
    right: var(--e-space-6);

    @include bp(s) {
      right: var(--e-space-4);
    }
  }

  .mobile-menu__search-bar {
    position: relative;
    padding-top: var(--e-space-5);
    padding-bottom: var(--e-space-5);
    padding-right: var(--e-sapce-8);
  }

  .mobile-menu__menu-layer {
    position: absolute;
    overflow: hidden;
    top: rem(80);
    left: 0;
    width: 100vw;
    height: calc(100% - #{rem(80)});
  }

  .mobile-menu__menu-layer-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 200vw;
    height: 100%;
  }

  .mobile-menu__search-layer-inner {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100%;
    overflow: auto;
  }

  .mobile-menu__level-1,
  .mobile-menu__level-2 {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    overflow: auto;
  }

  .mobile-menu__level-1 {
    left: 0;
  }

  .mobile-menu__level-1-inner {
    display: flex;
    flex-direction: column;
    padding-top: var(--e-space-6);
    justify-content: space-between;
    grid-gap: var(--e-space-10);
    min-height: 100%;
  }

  .mobile-menu__level-2 {
    left: 100vw;
  }

  .mobile-menu__level-2-wrapper {
    padding-bottom: var(--e-space-20);
  }

  .mobile-menu__back-container {
    padding: var(--e-space-4) 0;
    margin-bottom: var(--e-space-8);
  }

  .mobile-menu__sub-heading {
    @include type(50, strong);

    margin-bottom: var(--e-space-5);
  }

  .mobile-menu__overview-link {
    margin-top: var(--e-space-6);
  }

  .mobile-menu__search-bar-input {
    position: relative;
    display: none;
    padding-right: var(--e-space-10);
  }

  .mobile-menu__search-close-button {
    position: absolute;
    right: calc(var(--e-space-1) * -1);
    top: 50%;
    transform: translateY(-50%);
    color: var(--e-c-primary-01-700);
  }

  .mobile-menu__quick-access-title {
    @include type(50, strong);

    margin-top: var(--e-space-8);
  }

  .mobile-menu__quick-access-links {
    display: flex;
    flex-direction: column;
  }

  .mobile-menu__search-results {
    display: none;
    padding-bottom: var(--e-space-20);
  }

  .mobile-menu__search-results-cta {
    margin-top: var(--e-space-8);
  }

  // States
  &.mobile-menu--open {
    z-index: $layer-mobile-menu;
    position: relative;
    max-width: 100%;
    min-height: 100%;
    overflow: hidden;

    .mobile-menu__main-layer {
      display: block;
    }
  }

  &.mobile-menu--level-2 {
    .mobile-menu__menu-layer-inner {
      transform: translateX(-50%);
    }
  }

  &.mobile-menu--search-active {
    .mobile-menu__menu-layer-inner {
      display: none;
    }

    .mobile-menu__search-layer-inner {
      display: block;
    }

    .mobile-menu__close-button {
      display: none;
    }

    .mobile-menu__search-bar-cta {
      display: none;
    }

    .mobile-menu__search-bar-input {
      display: block;
    }
  }

  &.mobile-menu--has-search-results {
    .mobile-menu__quick-access {
      display: none;
    }

    .mobile-menu__search-results {
      display: block;
    }
  }

  // Animations
  &.mobile-menu--fade-in {
    .mobile-menu__main-layer {
      animation-name: open-menu;
      animation-duration: var(--e-trs-duration-fast);
      animation-fill-mode: forwards;
      animation-timing-function: var(--e-trs-easing-default);
    }
  }

  &.mobile-menu--fade-out {
    .mobile-menu__main-layer {
      animation-name: close-menu;
      animation-duration: var(--e-trs-duration-fast);
      animation-fill-mode: forwards;
      animation-timing-function: var(--e-trs-easing-default);
    }
  }

  &.mobile-menu--open-level-2 {
    .mobile-menu__menu-layer-inner {
      animation-name: open-level-2;
      animation-duration: var(--e-trs-duration-fast);
      animation-fill-mode: forwards;
      animation-timing-function: var(--e-trs-easing-default);
    }
  }

  &.mobile-menu--close-level-2 {
    .mobile-menu__menu-layer-inner {
      animation-name: close-level-2;
      animation-duration: var(--e-trs-duration-fast);
      animation-fill-mode: forwards;
      animation-timing-function: var(--e-trs-easing-default);
    }
  }
}

.mobile-menu-open {
  overflow: hidden;
  height: 100%;
}

e-mobile-menu:not(:defined) {
  opacity: 0;
}
