.radio-group {
  padding: 0;
  margin: 0;
  border: 0;

  .radio-group__legend {
    margin-bottom: var(--e-space-3);

    h2,
    h3 {
      @include type(200, strong);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-1_5);
    }
  }

  .radio-group__items {
    display: flex;
    flex-wrap: wrap;
    grid-gap: var(--e-space-3) var(--e-space-10);
  }
}

e-radio-group:not(:defined) {
  opacity: 0;
}
