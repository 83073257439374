@mixin button-base {
  @include type(200,strong);

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(10) rem(30);
  grid-gap: rem($space-2);
  border-radius: 24px;
  transition: background-color $trs-default, box-shadow $trs-default, color $trs-default, border $trs-default;
  border-width: 2px;
  border-style: solid;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;

  // Pointer should only be used for actual 'links'.
  // And buttons shouldn't have a pointer cursor. But it seems people still want this.
  cursor: pointer;

  .e-loader-button__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &:disabled {
    pointer-events: none;
  }
}
