.site-search {
  .site-search__title {
    margin-bottom: var(--e-space-10);

    h1 {
      @include type(800, strong);
    }
  }

  .site-search__result-count-text,
  .site-search__result-count-text-skeleton {
    @include type(600);

    margin-top: var(--e-space-8);
  }

  .site-search__search-loader {
    display: none;
  }

  .site-search__result-count-text-skeleton {
    &::before {
      @include skeleton-background;

      content: '_';
      display: inline-block;
      width: rem(200);
    }
  }

  .site-search__results-container {
    margin-top: var(--e-space-6);
    margin-left: calc(var(--e-space-6) * -1);
    margin-right: calc(var(--e-space-6) * -1);
  }

  .site-search__paginator-container {
    display: none;
    margin-top: var(--e-space-11);
  }

  .site-search__results-paginator-skeleton {
    margin-top: 0;
    display: none;
  }

  // States
  &.site-search--has-paginator {
    .site-search__paginator-container {
      display: block;
    }
  }

  &.site-search--is-searching {
    .site-search__search-loader {
      display: block;

      .site-search__results-container {
        display: block;
      }
    }

    .site-search__results-container,
    .site-search__result-count-text,
    .site-search__paginator-container {
      display: none;
    }
  }

  &.site-search--is-paginator-loading {
    .site-search__results-paginator-skeleton {
      display: block;
    }
  }
}

e-site-search:not(:defined) {
  opacity: 0;
}
