// Styles for component in shadowDOM are in range-slider.html.

e-range-slider {
  // Define input styles here, because with ::slotted we can't get the pseudo-elements
  input[type='range'] {
    appearance: none;
    border: none;
    background: none;
    margin: 0;
    height: 100%;
    width: 100%;
  }

  // Define input styles here, because with ::slotted we can't get the pseudo-elements
  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    height: var(--range-thumb-height);
    width: var(--range-thumb-width);
  }

  input[type='range']::-moz-range-thumb {
    border: none;
    border-radius: 0;

    /* custom styles */
    background: none;
    height: var(--range-thumb-height);
    width: var(--range-thumb-width);
  }

  &:not(:defined) {
    opacity: 0;
  }
}
