@media print {
  .footer {
    .footer__top-section,
    .footer__bottom-section {
      background-color: transparent;
    }

    .footer__address-columns {
      @include bp(m) {
        flex-direction: row;
      }

      .emergency-column {
        @include bp(lg) {
          p > span {
            display: inline;
          }
        }
      }

      a {
        color: var(--e-c-mono-900);
      }
    }

    .footer__copyright {
      @include bp(m) {
        position: static;
      }
    }

    .footer__bottom-section {
      padding: 0;
    }

    .footer__language-nav,
    .footer__meta-navigation,
    .footer__social-media-links {
      display: none;
    }
  }
}
