.infograph {
  position: relative;
  background-color: var(--e-c-secondary-01-50);

  .infograph__modal-background {
    position: fixed;
    display: none;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);

    @include bp(m) {
      display: block;
    }
  }

  .infograph__inner {
    // No need to add the 2xl spacing. Would be too much.
    padding: $section-default-padding 0;

    @include bp(lg) {
      padding: $section-default-padding-mobile 0;
    }
  }

  .infograph__row {
    flex-direction: row-reverse;
    padding-top: var(--e-space-30);
    padding-bottom: var(--e-space-30);

    @include bp(xl) {
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      padding: 0;
    }
  }

  .infograph__boembeli {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    width: 78%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 100%;
  }

  .infograph__image-container {
    position: relative;
    padding: var(--e-space-4);
  }

  .infograph__image {
    position: relative;
    aspect-ratio: 3/2;
    width: 100%;
  }

  .infograph__content-col {
    display: flex;
    align-items: center;

    @include bp(xl) {
      margin-top: var(--e-space-28);
    }

    @include bp(lg) {
      margin-top: var(--e-space-20);
    }

    @include bp(m) {
      margin-top: var(--e-space-16);
    }
  }

  .infograph__content {
    transition: opacity $trs-default;
  }

  .infograph__title {
    margin-bottom: var(--e-space-6);

    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-700);

      @include bp(lg) {
        @include type(700, strong);
      }
    }
  }

  .infograph__hotspot-layer,
  .infograph__modal-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .infograph__modal-layer {
    pointer-events: none;
    z-index: -1;
    display: flex;
    align-items: center;
    height: 100%;
    opacity: 0;
    transition: opacity $trs-default, z-index 10ms linear var(--e-trs-duration-faster);

    @include bp(xl) {
      align-items: flex-end;
    }
  }

  .infograph__modal-container {
    @include bp(xl) {
      padding-bottom: var(--e-space-20);
    }

    @include bp(lg) {
      padding-bottom: var(--e-space-16);
    }
  }

  .infograph__modal-col {
    display: flex;
    justify-content: center;
  }

  .infograph__modal {
    pointer-events: auto;
    position: relative;
    max-width: rem(520);
    padding: var(--e-space-8);
    background-color: var(--e-c-mono-00);
    box-shadow: var(--e-elevation-xs);
    border-radius: var(--e-brd-radius-2);

    @include bp('2xl') {
      width: 100%;
      max-width: none;
      padding: var(--e-space-12);
    }

    @include bp(m) {
      padding: var(--e-space-6);
    }
  }

  .infograph__modal-close-button {
    z-index: 10;
    position: absolute;
    top: var(--e-space-2);
    right: var(--e-space-2);
    display: none;

    @include bp(m) {
      display: block;
    }
  }

  // Modifiers
  &.infograph--has-boembeli {
    .infograph__boembeli {
      display: block;
    }
  }

  // States
  &.infograph--modal-visible {
    .infograph__content {
      opacity: 0.2;
      pointer-events: none;
      user-select: none;
    }

    .infograph__modal-layer {
      opacity: 1;
      z-index: 10;
      transition: opacity $trs-default;
    }
  }
}

// Carousel Layout
@import 'infograph-carousel';

e-infograph:not(:defined) {
  opacity: 0;
}

// Global classes
.infograph-modal-visible {
  @include bp(m) {
    overflow: hidden;
  }
}
