.alert {
  @include type(100);

  position: relative;
  padding: var(--e-space-3) var(--e-space-4) var(--e-space-3) var(--e-space-12);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--e-brd-radius-2);

  a {
    @include text-link;

    color: currentColor;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: var(--e-space-4);
    width: rem(24);
    height: rem(24);
    transform: translateY(-50%);
  }

  &.alert--info {
    background-color: rgba($c-secondary-05-50, 0.8);
    border-color: rgba($c-secondary-05-500, 0.4);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm-1 5v2h2V7h-2Zm0 4v6h2v-6h-2Zm-7 1c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8-8 3.59-8 8Z' fill='%230096DC'/%3E%3C/svg%3E");
    }
  }

  &.alert--success {
    background-color: rgba($c-secondary-01-50, 0.8);
    border-color: rgba($c-signal-01-500, 0.4);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23D9D9D9' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath d='m10.6 16.6 7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4 4.25 4.25ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z' fill='%231AA764'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &.alert--warning {
    background-color: rgba($c-secondary-04-50, 0.8);
    border-color: rgba($c-signal-02-500, 0.4);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23D9D9D9' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath d='M1 21 12 2l11 19H1Zm3.45-2h15.1L12 6 4.45 19ZM12 18c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 17a.968.968 0 0 0-.287-.712A.968.968 0 0 0 12 16a.968.968 0 0 0-.713.288A.968.968 0 0 0 11 17c0 .283.096.52.287.712.192.192.43.288.713.288Zm-1-3h2v-5h-2v5Z' fill='%23FF9800'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &.alert--error {
    background-color: rgba($c-secondary-06-50, 0.8);
    border-color: rgba($c-signal-03-500, 0.4);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Cpath fill='%23D9D9D9' d='M0 0h24v24H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath d='M12 17c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 16c0 .283.096.52.287.712.192.192.43.288.713.288Zm-1-4h2V7h-2v6Zm-2.75 8L3 15.75v-7.5L8.25 3h7.5L21 8.25v7.5L15.75 21h-7.5Zm.85-2h5.8l4.1-4.1V9.1L14.9 5H9.1L5 9.1v5.8L9.1 19Z' fill='%23FF0C3E'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
