// This for the custom elements. (Extended from BaseExtensionFactory())
// Should prevent FOUC as much as possible.
// Set opacity of the root element to '0' to make use of this.
// When the component has been initially rendered this attribute is set on the root element of the component.
[data-e-rendered]:not(e-img) {
  // And also exclude e-img from this, because it has it's own visibility handling.

  opacity: 1;
  //transition: opacity var(--e-trs-duration-fastest) var(--e-trs-easing-default);
}

// It seems that with e-something:not(:defined) it works really good.
// Remove data-e-rendered if it becomes obsolete.
