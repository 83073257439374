.job-grid {
  .job-grid__title {
    margin-bottom: var(--e-space-10);
    text-align: center;

    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(m) {
      margin-bottom: var(--e-space-8);

      h2 {
        @include type(500, strong);
      }
    }
  }

  .job-grid__cards {
    grid-row-gap: var(--e-space-10);

    @include bp(xl) {
      grid-row-gap: var(--e-space-6);
    }

    @include bp(m) {
      grid-row-gap: var(--e-space-4);
    }
  }
}

e-job-grid:not(:defined) {
  opacity: 0;
}
