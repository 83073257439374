@mixin icon-button-filled {
  &::before {
    background-color: $c-primary-01-700;
    border-color: $c-primary-01-700;
  }

  e-icon {
    --fill-color: var(--e-c-mono-00);
  }

  &:hover {
    &::before {
      background-color: $c-secondary-01-900;
      border-color: $c-secondary-01-900;
    }
  }

  &:active {
    &::before {
      background-color: $c-secondary-01-700;
      border-color: $c-secondary-01-700;
    }
  }

  &:disabled {
    &::before {
      background-color: $c-mono-500;
      border-color: $c-mono-500;
    }
  }
}
