@mixin icon-button-outline-hover-state {
  &::before {
    border-color: $c-primary-01-200;
  }
}

@mixin icon-button-outlined {
  &::before {
    background-color: transparent;
    border-color: $c-mono-500;
  }

  e-icon {
    --fill-color: var(--e-c-mono-900);
  }

  &:hover {
    @include icon-button-outline-hover-state;
  }

  &:active {
    &::before {
      border-color: $c-primary-01-500;
    }
  }

  &:disabled {
    &::before {
      border-color: $c-mono-500;
    }

    e-icon {
      --fill-color: var(--e-c-mono-500);
    }
  }
}
