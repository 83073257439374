::backdrop,
:root {
  // Render all tokens as css variables.
  // We'll have to find out if this is helpful.
  // All design tokens as scss variables could be sufficient.
  @each $tokenName, $tokenVar in $design-token-map {
    --e-#{$tokenName}: #{$tokenVar};
  }
}

// Currently we use these css-variables only for the patternlab demo.
:root {
  --grid-gutter-2xl: #{$grid-gutter-2xl};
  --grid-gutter-lg: #{$grid-gutter-lg};
  --grid-gutter-s: #{$grid-gutter-s};
}
