.audio-content {
  padding: var(--e-space-10);
  background-color: var(--e-c-mono-50);
  border-radius: var(--e-brd-radius-3);

  @include bp(lg) {
    padding: var(--e-space-8) var(--e-space-6);
  }

  .audio-content__row {
    @include grid-row;

    align-items: center;
    flex-wrap: nowrap;

    @include bp(m) {
      flex-wrap: wrap;
    }
  }

  .audio-content__audio-player-col {
    flex: 1 1 auto;
  }

  .audio-content__image-col {
    @include grid-col(6);

    border-radius: var(--e-brd-radius-1);
    overflow: hidden;

    @include bp(m) {
      @include grid-col(12);

      margin-bottom: var(--e-space-6);
    }
  }

  .audio-content__heading {
    @include type(100);

    margin-bottom: var(--e-space-1);
  }

  .audio-content__title {
    @include type(700, strong);
  }

  .audio-content__description {
    @include type(200);

    margin-top: var(--e-space-4);
    max-width: rem(450);
  }

  .audio-content__audio-player {
    margin-top: var(--e-space-10);
  }

  .audio-content__transcript {
    margin-top: var(--e-space-10);
  }

  // Modifiers
  &.audio-content--image-left {
    .audio-content__image-col {
      order: 1;
    }

    .audio-content__audio-player-col {
      order: 2;
    }
  }

  &.audio-content--image-left,
  &.audio-content--image-right {
    .audio-content__transcript-col {
      max-width: calc(50% - #{$grid-gutter-2xl} / 2); // 6 columns

      @include bp(xl) {
        max-width: calc(66.66% - #{$grid-gutter-2xl} / 2); // 8 columns
      }

      @include bp(lg) {
        max-width: calc(83.33% - #{$grid-gutter-lg} / 2); // 10 columns
      }

      @include bp(m) {
        max-width: none;
      }
    }
  }

  @include bp(m) {
    .audio-content__image-col {
      order: 1;
    }

    .audio-content__audio-player-col {
      order: 2;
    }
  }
}
