@keyframes menu--fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes menu--fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.context-menu {
  .context-menu__menu {
    z-index: 10;
    position: absolute;
    display: none;
    min-width: rem(168);
  }

  &.context-menu--open {
    .context-menu__menu {
      display: block;
      animation: menu--fade-in var(--e-trs-duration-faster) var(--e-trs-easing-default) forwards;
    }
  }

  &.context-menu--close {
    .context-menu__menu {
      display: block;
      animation: menu--fade-out var(--e-trs-duration-faster) var(--e-trs-easing-default) forwards;
    }
  }
}

e-context-menu:not(:defined) {
  opacity: 0;
}
