.article-overview {
  .article-overview__filter {
    z-index: 10;
    position: relative;
  }

  .article-overview__result {
    position: relative;

    transition: opacity $trs-default;

    e-article-list {
      visibility: hidden;
    }

    .article-overview__skeleton-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &.article-overview--loading {
    .article-overview__filter {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.article-overview--is-updating {
    .article-overview__result {
      opacity: 0;
      transition: none;
    }
  }

  &.article-overview--is-ready {
    .article-overview__skeleton-loader {
      display: none;
    }

    .article-overview__result {
      e-article-list {
        visibility: visible;
      }
    }
  }
}
