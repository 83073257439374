@import '../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import '../../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme';

:root {
  --glide-nav-button-size: var(--e-space-12);
  --glide-bottom-space-mobile: var(--e-space-12);
}

// Glide style overrides
.glide__arrow {
  padding: 0;
  text-shadow: none;
  box-shadow: none;
  border: 0;

  &.glide__arrow--disabled {
    opacity: 1;
  }
}

.glide__bullets {
  pointer-events: none;
}

.glide__bullet {
  pointer-events: none;
  width: var(--e-space-2);
  height: var(--e-space-2);
  margin: 0 var(--e-space-1);
  border-radius: 100%;
  cursor: default;
  border: 0;
  background-color: var(--e-c-mono-200);
  box-shadow: none;
  transition: background-color $trs-default;

  &:hover {
    border: 0;
  }

  &.glide__bullet--active {
    background-color: var(--e-c-secondary-01-700);
  }
}

.glide {
  &.glide--disabled {
    cursor: default;
  }

  &.carousel--sliding {
    .glide__slide--hidden {
      visibility: visible;
    }
  }

  .glide__slide--hidden {
    visibility: hidden;
  }
}

.glide-outer {
  position: relative;
}

e-carousel:not(:defined) {
  opacity: 0;
}
