.breadcrumbs {
  @include grid-container;
  @include type(100);

  display: block;
  white-space: nowrap;
  padding-top: var(--e-space-5);
  padding-bottom: var(--e-space-5);
  grid-gap: var(--e-space-2);
  color: var(--e-c-mono-700);
  overflow: hidden;
  text-overflow: ellipsis;

  .breadcrumbs__item {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    white-space: nowrap;
    vertical-align: middle;

    .breadcrumbs__prev-icon {
      display: none;
      width: rem(16);
      height: rem(16);
    }
  }

  .breadcrumbs__home {
    display: block;
    width: rem(20);
    height: rem(20);
  }

  // stylelint-disable  selector-no-qualifying-type
  a.breadcrumbs__item {
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: rem(-2);
      right: rem(-6);
      bottom: rem(-2);
      left: rem(-6);
      background-color: var(--e-c-primary-01-50);
      border-radius: var(--e-brd-radius-1);
      opacity: 0;
      transition: opacity $trs-default;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
  // stylelint-enable

  .breadcrumbs__separator {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    margin: 0 var(--e-space-1);
    color: var(--e-c-mono-500);

    e-icon {
      width: rem(16);
      height: rem(16);
    }
  }

  @include bp(lg) {
    @include type(50);
  }

  @include bp(m) {
    @include type(100);

    .breadcrumbs__separator,
    .breadcrumbs__item:not(.direct-parent) {
      display: none;
    }

    .breadcrumbs__item.direct-parent {
      display: inline-flex;
      align-items: center;

      e-icon {
        display: inline-block;
        margin-right: var(--e-space-2);
      }
    }
  }
}
