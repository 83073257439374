.content-aside {
  .content-aside__aside-col {
    display: flex;

    @include bp(lg) {
      margin-top: var(--e-space-12);
    }
  }

  // Modifiers
  &.content-aside--top {
    .content-aside__aside-col {
      align-items: flex-start;
    }
  }

  &.content-aside--middle {
    .content-aside__aside-col {
      align-items: center;
    }
  }

  &.content-aside--bottom {
    .content-aside__aside-col {
      align-items: flex-end;
    }
  }
}

e-content-aside:not(:defined) {
  opacity: 0;
}
