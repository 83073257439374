.article-outro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: var(--e-space-6);
  border-top: 1px solid var(--e-c-mono-500);
  gap: var(--e-space-6);

  .article-outro__info {
    @include type(200);

    > * + * {
      margin-top: var(--e-space-2);
    }

    @include bp(lg) {
      @include type(100);
    }
  }

  .article-outro__share {
    margin-left: auto;
  }
}

e-article-outro:not(:defined) {
  opacity: 0;
}
