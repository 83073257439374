.product-card {
  background-color: var(--e-c-secondary-01-500);
  border-radius: var(--e-brd-radius-3);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: var(--e-space-12) var(--e-space-10);
  color: var(--e-c-mono-00);
  width: 100%;
  height: 100%;

  @include bp('lg') {
    padding: var(--e-space-10) var(--e-space-8);
  }

  .product-card__title {
    @include type(800, strong);

    text-align: center;

    @include bp(lg) {
      @include type(400, strong);
    }
  }

  .product-card__subtitle {
    margin-top: var(--e-space-1);

    @include type(300, strong);
  }

  .product-card__img-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 3/2;
    height: rem(152);
    margin-bottom: var(--e-space-6);
    margin-top: var(--e-space-6);
    overflow: hidden;
    border-radius: var(--e-brd-radius-1);
    background-color: var(--e-c-mono-00);

    @include bp('lg') {
      height: rem(104);
      margin-top: var(--e-space-4);
      margin-bottom: var(--e-space-4);
    }
  }

  .product-card__text {
    width: 100%;
  }

  .product-card__list {
    width: 100%;
    margin-top: var(--e-space-6);
    padding-top: var(--e-space-6);
    border-top: var(--e-space-0_5) solid var(--e-c-primary-01-500);

    @include bp(lg) {
      margin-top: var(--e-space-4);
      padding-top: var(--e-space-4);
    }
  }

  .product-card__cta {
    margin-top: auto;
    padding-top: var(--e-space-14);

    @include bp(lg) {
      padding-top: var(--e-space-12);
    }
  }
}
