.quicklinks {
  display: flex;
  justify-content: center;
  padding: var(--e-space-4) 0;
  gap: var(--e-space-16);
  border-bottom: 1px solid var(--e-c-secondary-01-100);

  @include bp(lg) {
    gap: var(--e-space-6);
  }

  @include bp(m) {
    padding: var(--e-space-2) var(--e-space-8);
    flex-direction: column;
    gap: 0;
  }

  > a {
    @include button-base;
    @include button-plain-big;

    padding: var(--e-space-6) var(--e-space-8);
    border-radius: var(--e-brd-radius-1);
    transition: background-color $trs-default, box-shadow $trs-default;

    &:hover {
      background-color: var(--e-c-primary-01-50);
      box-shadow: var(--e-elevation-xs);
      color: var(--e-c-primary-01-700);

      e-icon {
        --fill-color: var(--e-c-primary-01-700);
      }
    }

    @include bp(lg) {
      padding: var(--e-space-6);
    }

    @include bp(m) {
      &:not(:last-child) {
        border-bottom: 1px solid var(--e-c-secondary-01-100);
      }

      &:hover {
        background-color: transparent;
        box-shadow: none;
      }
    }

    @include bp(s) {
      justify-content: left;
      text-align: left;
    }
  }
}
