.key-fact {
  display: flex;
  align-items: center;
  gap: 0 var(--e-space-3);
  flex-wrap: wrap;

  @include bp(xl) {
    display: block;
  }

  .key-fact__value {
    @include type(800);

    font-weight: var(--e-type-weight-strong);
    color: var(--e-c-mono-00);
    white-space: nowrap;

    @include bp(m) {
      @include type(700);
    }
  }

  .key-fact__unit {
    @include type(300);

    font-weight: var(--e-type-weight-strong);
    color: var(--e-c-mono-00);

    @include bp(m) {
      @include type(100);
    }
  }
}
