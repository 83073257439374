.panel {
  position: relative;
  padding: var(--e-space-14) rem(400) rem(88) var(--e-space-14);
  box-shadow: var(--e-elevation-md);
  background-color: var(--e-c-mono-00);
  border-radius: var(--e-brd-radius-3);
  border: 1px solid var(--e-c-mono-200);
  overflow: hidden;

  @include bp('2xl') {
    padding: var(--e-space-10) rem(344) rem(72) var(--e-space-10);
  }

  @include bp('lg') {
    padding: var(--e-space-6) var(--e-space-6) rem(104) var(--e-space-6);
  }

  .panel__title {
    margin-bottom: var(--e-space-3);

    h2 {
      @include type(500, strong);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-2);

      h2 {
        @include type(300, strong);
      }
    }
  }

  .panel__text {
    @include type(300);

    @include bp(lg) {
      @include type(200);
    }
  }

  .panel__spectro {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 700px);
    right: rem(-1560 + 400);
    width: rem(1560);
    height: rem(1560);
    user-select: none;

    @include bp('2xl') {
      right: rem(-1400 + 344);
      width: rem(1400);
      height: rem(1400);
    }

    @include bp(lg) {
      top: calc(100% - 95px);
      right: -180px;
      width: rem(600);
      height: rem(600);
    }

    @include bp(s) {
      right: calc(50% - 300px);
    }
  }
}

e-panel:not(:defined) {
  opacity: 0;
}

.hide-e360-branding {
  .panel__spectro {
    display: none;
  }
}
