.teaser-wall-item {
  .teaser-wall-item__inner {
    position: relative;
    display: flex;
    border-radius: var(--e-space-1);
    overflow: hidden;
    aspect-ratio: 16/25;

    @include bp(lg) {
      aspect-ratio: 1;
    }
  }

  .teaser-wall-item__content {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: auto var(--e-space-6) var(--e-space-6) var(--e-space-6);
    padding: var(--e-space-6);
    background-color: var(--e-c-mono-00);
    border-radius: var(--e-space-1);

    @include bp(xl) {
      border-radius: var(--e-space-3);
    }

    @include bp(lg) {
      margin: auto var(--e-space-4) var(--e-space-4) var(--e-space-4);
      padding: var(--e-space-4);
    }
  }

  .teaser-wall-item__title h2 {
    @include type(700, strong);

    margin-bottom: var(--e-space-1);
    color: var(--e-c-primary-01-500);

    @include bp(lg) {
      @include type(500, strong);
    }

    @include bp(lg) {
      @include type(400, strong);
    }
  }

  .teaser-wall-item__text {
    @include type(300);

    @include bp(xl) {
      @include visually-hidden;
    }
  }

  .teaser-wall-item__cta {
    margin-top: var(--e-space-4);

    @include bp(lg) {
      margin-top: var(--e-space-2);
    }
  }

  .teaser-wall-item__background {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // Large teaser layout
  &[teaser-index='2'],
  [teaser-index='2'] & {
    .teaser-wall-item__inner {
      aspect-ratio: 4/3;

      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: rem(1061);
        height: rem(1061);
        background-color: var(--e-c-secondary-01-500);
        opacity: 0.85;
        border-radius: 50%;
        transform: translate(-50%, 50%);

        @include bp(xl) {
          transform: translate(-50%, 75%);
        }

        @include bp(lg) {
          width: rem(676);
          height: rem(676);
          transform: translate(-50%, 50%);
        }

        @include bp(m) {
          content: none;
        }
      }

      @include bp(m) {
        aspect-ratio: 1;
      }
    }

    .teaser-wall-item__content {
      max-width: rem(636);

      @include bp('2xl') {
        max-width: rem(476);
      }

      @include bp(lg) {
        max-width: rem(500);
      }

      @include bp(m) {
        max-width: none;
      }
    }
  }
}

e-teaser-wall-item:not(:defined) {
  opacity: 0;
}
