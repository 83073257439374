.teaser-carousel {
  .teaser-carousel__title {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--e-space-10);
    text-align: center;

    > h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(m) {
      margin-bottom: var(--e-space-8);

      width: 100%;

      > h2 {
        @include type(700, strong);
      }
    }

    @include bp(s) {
      > h2 {
        @include type(500, strong);
      }
    }
  }

  .teaser-carousel__carousel-col {
    @include grid-col(12);
  }

  // Modifiers
  &.teaser-carousel--two-items {
    .teaser-carousel__carousel-col {
      @include grid-col(8);

      @include bp(lg) {
        @include grid-col(10);
      }

      @include bp(m) {
        @include grid-col(12);
      }
    }
  }

  // Glide style overrides
  .glide-outer {
    @include bp(s) {
      padding-bottom: calc(var(--glide-nav-button-size) + var(--glide-bottom-space-mobile));
    }
  }

  .glide__arrow {
    top: 0;
    transform: none;
    padding-top: calc(11% - var(--glide-nav-button-size) / 2); // 11% is an estimated value. it appears vertically centered enough to images.

    @include bp(m) {
      padding-top: calc(16% - var(--glide-nav-button-size) / 2); // 16% is an estimated value. it appears vertically centered enough to images.
    }

    @include bp(s) {
      top: auto;
      bottom: 0;
    }
  }

  .glide__arrow--left {
    left: calc(var(--glide-nav-button-size) / -2);

    @include bp(s) {
      left: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__arrow--right {
    right: calc(var(--glide-nav-button-size) / -2);

    @include bp(s) {
      right: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__bullets {
    bottom: 0;

    @include bp(s) {
      display: none;
    }
  }

  .glide--has-bullets {
    .glide__slide {
      padding-bottom: var(--e-space-12);

      @include bp(s) {
        padding-bottom: 0;
      }
    }
  }
}

e-teaser-carousel:not(:defined) {
  opacity: 0;
}
