@media print {
  [is='e-article-header'][is='e-article-header'],
  .article-header.article-header {
    .article-header__share, .article-header__tags.article-header__tags {
      display: none;
    }

    &.article-header--has-tags {
      .article-header__tags.article-header__tags {
        display: none;
      }
    }

    .article-header__article-info {
      display: inline;
    }

    .article-header__meta {
      display: none;
    }
  }
}
