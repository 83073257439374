@use 'sass:math';

// Global variables
// These are abstractions of design tokens

$body-font: #{$type-font-body}, #{$type-font-fallback};
$body-font-color: $c-mono-900;
$body-line-height: $type-line-height-300;
$body-font-size: $type-size-300;
$body-font-weight: $type-weight-weak;

$base-font-size: $type-font-base-size;

// Layout: Container
$container-edge-2xl: $space-20;
$container-edge-lg: $space-10;
$container-edge-m: $space-10;
$container-edge-s: $space-5;
$container-inner-2xl: $layout-site-width - 2 * $container-edge-2xl;
$container-inner-lg: $layout-site-width-lg - 2 * $container-edge-lg;
$container-inner-m: $layout-site-width-m - 2 * $container-edge-m;
$container-inner-s: $layout-site-width-s - 2 * $container-edge-s;

// Layout: Grid
$grid-columns: $layout-grid-columns; // Default columns in grid. Can be overridden in mixin.
$grid-gutter-2xl: math.div($space-10, $container-inner-2xl) * 100%;
$grid-gutter-lg: math.div($space-6, $container-inner-lg) * 100%;
$grid-gutter-m: math.div($space-6, $container-inner-m) * 100%;
$grid-gutter-s: math.div($space-5, $container-inner-s) * 100%;

// Transition
$trs-default: $trs-duration-faster $trs-easing-default;

// CMS-Section
$section-default-padding-2xl: var(--e-space-28);
$section-default-padding: var(--e-space-20);
$section-default-padding-mobile: var(--e-space-16);
