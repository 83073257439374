.box-product-item {
  display: flex;
  align-items: center;
  grid-gap: var(--e-space-4);

  .box-product-item__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: rem(48);
    height: rem(48);
    border-radius: rem(48);
    background-color: var(--e-c-mono-00);
  }

  .box-product-item__name {
    @include type(300, strong);

    text-wrap: pretty;

    @include bp(lg) {
      @include type(200, strong);
    }
  }
}
