.button-group {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: var(--e-space-4);
  grid-column-gap: var(--e-space-2);

  &.button-group--centered {
    justify-content: center;
  }
}
