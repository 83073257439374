$icon-button-size: 48px;

@mixin icon-button-hover-state {
  &::before {
    transform: scale(1.05);
  }
}

@mixin icon-button-base {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: rem($icon-button-size);
  height: rem($icon-button-size);
  border-radius: 100%;
  color: transparent;

  // Pointer should only be used for actual 'links'.
  // And buttons shouldn't have a pointer cursor. But it seems people still want this.
  cursor: pointer;

  e-icon {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    transition: background-color $trs-default, border-color $trs-default, transform $trs-default;
  }

  &:hover:not(:disabled) {
    @include icon-button-hover-state;
  }

  &:active {
    &::before {
      transform: scale(1);
    }
  }

  &:disabled {
    cursor: default;
  }
}
