.media {
  .media__media {
    aspect-ratio: 16/9;
    width: 100%;
    border-radius: var(--e-brd-radius-1);
    border: 1px solid var(--e-c-mono-200);
    overflow: hidden;

    // Force correct box-sizing. Just in case.
    box-sizing: border-box;
  }

  .media__caption {
    margin-top: var(--e-space-4);
    font-size: var(--e-type-size-200);
    line-height: var(--e-type-line-height-200);

    @include bp(m) {
      margin-top: var(--e-space-2);
      font-size: var(--e-type-size-100);
      line-height: var(--e-type-line-height-100);
    }
  }
}
