.summary-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--e-space-10);

  @include bp('lg') {
    grid-gap: var(--e-space-6);
  }

  @include bp('m') {
    grid-gap: var(--e-space-5);
    grid-template-columns: 1fr;
  }
}
