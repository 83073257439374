.tag {
  @include type(100, strong);

  display: inline-block;
  padding: var(--e-space-2) var(--e-space-6);
  color: var(--e-c-mono-00);
  background-color: var(--e-c-secondary-02-500);
  border-radius: var(--e-brd-radius-6);
  text-decoration: none;
  transition: background $trs-default;

  &:hover {
    background-color: var(--e-c-secondary-02-900);
  }

  &:active {
    background-color: var(--e-c-secondary-02-700);
  }

  @include bp(lg) {
    @include type(50, strong);

    padding: var(--e-space-1) var(--e-space-4);
  }

  // Modifiers
  &.tag--small {
    @include type(50, strong);

    padding: var(--e-space-1) var(--e-space-2);
  }

  &.tag--inactive {
    background-color: var(--e-c-mono-100);
    color: var(--e-c-mono-700);
  }
}
