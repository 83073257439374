.testimonials {
  @include cms-section-spacing-normal;

  overflow: hidden;

  // Glide overrides
  &:not(.testimonials--single) {
    .glide-outer {
      @include bp(s) {
        padding-bottom: calc(var(--glide-nav-button-size) + var(--glide-bottom-space-mobile));
      }
    }
  }

  .glide {
    // Force default cursor. `.glide__swipeable` is still added even when swiping is disabled.
    cursor: default;
  }

  .glide__track,
  .glide__slides {
    overflow: visible;
  }

  .glide__slide {
    height: auto;
  }

  .glide__bullets {
    bottom: 0;
  }

  .glide__arrow {
    @include bp(s) {
      top: auto;
      bottom: 0;
    }
  }

  .glide__arrow--left {
    left: var(--e-space-4);

    @include bp(s) {
      left: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__arrow--right {
    right: var(--e-space-4);

    @include bp(s) {
      right: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }
}

e-testimonial:not(:defined) {
  opacity: 0;
}
