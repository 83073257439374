@mixin button-filled {
  background-color: $c-primary-01-700;
  color: $c-mono-00;
  border-color: $c-primary-01-700;

  e-icon {
    --fill-color: #{$c-mono-00};
  }

  ec-loader {
    --dot-color: #{$c-mono-00};
  }

  &:hover {
    background-color: $c-secondary-01-900;
    border-color: $c-secondary-01-900;
  }

  &:active,
  &[loading] {
    background-color: $c-secondary-01-700;
    border-color: $c-secondary-01-700;
  }

  &:disabled:not([loading]) {
    background-color: $c-mono-500;
    border-color: $c-mono-500;
  }
}
