.share-links {
  .share-links__nav {
    display: grid;
    padding: var(--e-space-2) 0;
    background-color: var(--e-c-mono-00);
    border: 1px solid var(--e-c-mono-100);
    border-radius: var(--e-brd-radius-1);
    box-shadow: var(--e-elevation-sm);
  }

  .share-links__link {
    @include type(100, strong);

    display: flex;
    align-items: center;
    padding: var(--e-space-2) var(--e-space-4);
    color: var(--e-c-mono-700);
    grid-gap: var(--e-space-3);
    white-space: nowrap;
    cursor: pointer;

    e-icon {
      width: rem(16);
      height: rem(16);
    }

    &:hover {
      color: var(--e-c-secondary-01-900);
      background-color: var(--e-c-primary-01-50);
    }

    @include bp(lg) {
      padding-top: var(--e-space-4);
      padding-bottom: var(--e-space-4);
    }
  }
}
