.evp-map--info-overlay {
  position: absolute;
  bottom: var(--e-space-7);
  left: 34px;
  display: none;
  width: 380px;
  background-color: #ffffff;
  border: 1px solid var(--e-c-mono-200);
  box-shadow: var(--e-space-1) var(--e-space-1) var(--e-space-3) rgba(0, 0, 0, 0.2);
  border-radius: var(--e-space-1);

  &.is-loading {
    min-height: 200px;
  }

  @include bp(lg) {
    width: 320px;
  }

  @include bp(m) {
    right: -1px;
    bottom: 0;
    left: -1px;
    width: auto;
    transform: translateY(calc(100% - 100px));
    transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);

    @include bp(lg) {
      transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);
      transform: translateY(calc(100% - 200px));
    }

    &.is-panel-open {
      transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);
      transform: translateY(0);
      transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);
    }
  }
}

.evp-map--info-overlay-container {
  position: relative;
  padding: var(--e-space-8);
  border-radius: var(--e-space-1);

  @include bp(m) {
    padding: 0 var(--e-space-6) var(--e-space-8) var(--e-space-6);
  }
}

.evp-map--overlay-image {
  margin-bottom: var(--e-space-6);

  @include bp(lg) {
    margin-bottom: var(--e-space-2);
  }

  img {
    border-radius: var(--e-space-1);
    @include bp(lg) {
      max-height: 20vh;
    }
  }
}

.evp-map--overlay-close {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: var(--e-space-2);
  right: var(--e-space-2);

  &.button.plain.small {
    padding: 0;
  }

  @include bp(m) {
    display: none;
  }
}

.evp-map--overlay-panel-trigger {
  cursor: pointer;
  margin: var(--e-space-3) auto;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--e-space-6);
  color: var(--e-c-primary-01-700);
  transform: rotateX(180deg);
  transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);

  &.is-panel-open e-icon {
    transform: rotateX(-180deg);
    transition: transform var(--e-trs-duration-faster) var(--e-trs-easing-default);
  }

  // We'll keep panel always open.
  // So don't show trigger.
  @include bp(m) {
    display: flex;
  }
}

.evp-map--overlay-section__intro {
  display: flex;
  flex-direction: column;

  .evp-map--overlay-image {
    @include bp(lg) {
      order: 4;
      margin: var(--e-space-2) 0 var(--e-space-4) 0;
    }
  }
}

.evp-map--overlay-title {
  @include type(500, strong);

  @include bp(lg) {
    margin-bottom: var(--e-space-2);
  }
}

.evp-map--overlay-description {
  @include type(200);

  margin-top: 16px;
}

.evp-map--overlay-op-img {
  display: block;
  margin: 0 auto;
  width: 168px;

  @include bp(lg) {
    width: 144px;
  }
}

.evp-map--overlay-op-text {
  @include type(500, strong);
  margin-top: var(--e-space-4);
  text-align: center;
}

.evp-map--overlay-event-title {
  @include type(200, strong);
  margin-bottom: var(--e-space-2);
}

.evp-map--overlay-energy-source {
  @include type(200);
  margin-bottom: var(--e-space-8);

  @include bp(lg) {
    margin-bottom: var(--e-space-2);
  }
}

.evp-map--overlay-event-item {
  @include type(200);
  display: flex;
  gap: var(--e-space-2);

  &:not(:last-child) {
    margin-bottom: var(--e-space-2);
  }
}

.evp-map--overlay-cta {
  margin-top: var(--e-space-6);
  width: 100%;

  // Avoid line-breaks in buttons as much as possible.
  padding-left: rem(16);
  padding-right: rem(16);
}
