.teaser-single {
  &.teaser-single--image-left {
    .teaser-single__image-col {
      order: 1;
    }

    .teaser-single__text-col {
      order: 2;
    }
  }

  // --------------------------------------------------------------------------------
  // PLAIN
  // --------------------------------------------------------------------------------
  .teaser-single__text-col {
    @include grid-col(5);
    @include grid-col-indent(1);

    @include bp('2xl') {
      @include grid-col(6);
    }

    @include bp(xl) {
      @include grid-col-indent(0);
    }

    @include bp(m) {
      @include grid-col(12);

      order: 2;
    }
  }

  .teaser-single__image-col {
    @include grid-col(6);

    @include bp('2xl') {
      @include grid-col(5);
    }

    @include bp(xl) {
      @include grid-col(6);
    }

    @include bp(m) {
      @include grid-col(12);

      margin-bottom: var(--e-space-6);
      order: 1;
    }
  }

  .teaser-single__inner {
    align-items: center;

    @include bp('xl') {
      align-items: normal;
    }
  }

  .teaser-single__title {
    margin-bottom: var(--e-space-6);

    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-4);

      h2 {
        @include type(700, strong);
      }
    }
  }

  .teaser-single__image-col {
    @include bp(m) {
      margin-bottom: var(--e-space-6);
      order: 1;
    }
  }

  .teaser-single__img {
    aspect-ratio: 3/2;
    border-radius: var(--e-brd-radius-1);
    overflow: hidden;
  }

  .teaser-single__text {
    margin-bottom: var(--e-space-8);

    @include bp(m) {
      margin-bottom: var(--e-space-6);
    }
  }

  // MODIFIERS
  &.teaser-single--plain.teaser-single--image-left {
    .teaser-single__text-col {
      @include grid-col-indent(0);
    }
  }

  // --------------------------------------------------------------------------------
  // CARD
  // --------------------------------------------------------------------------------
  &.teaser-single--card {
    .teaser-single__inner {
      @include grid-col-space(1, 12, margin-left);
      @include grid-col-space(1, 12, margin-right);

      padding: var(--e-space-6);
      border-radius: var(--e-brd-radius-3);
      background-color: var(--e-c-secondary-01-50);

      @include bp('2xl') {
        align-items: normal;
      }

      @include bp(m) {
        @include grid-col-space(0, 12, margin-left);
        @include grid-col-space(0, 12, margin-right);

        padding: var(--e-space-4);
      }
    }

    .teaser-single__text-col {
      @include grid-col(4, 10);
      @include grid-col-indent(0);

      padding: var(--e-space-6);

      @include bp('2xl') {
        @include grid-col(5, 10);
      }

      @include bp(lg) {
        @include grid-col(12, 12);

        order: 2;
        padding: 0 var(--e-space-2) var(--e-space-4) var(--e-space-2);
      }
    }

    .teaser-single__image-col {
      @include grid-col(6, 10);

      @include bp('2xl') {
        @include grid-col(5, 10);
      }

      @include bp(lg) {
        @include grid-col(12, 12);

        margin-bottom: var(--e-space-8);
        order: 1;
      }
    }

    .teaser-single__title {
      margin-bottom: var(--e-space-2);

      * {
        @include type(800, strong);

        color: var(--e-c-primary-01-700);
      }

      @include bp(lg) {
        * {
          @include type(500, strong);
        }
      }

      @include bp(m) {
        * {
          @include type(400, strong);
        }
      }
    }

    .teaser-single__text {
      margin-bottom: var(--e-space-6);
    }

    .teaser-single__img {
      aspect-ratio: 16/9;

      @include bp('2xl') {
        aspect-ratio: auto;
        height: 100%;
      }

      @include bp(lg) {
        aspect-ratio: 16/9;
        height: auto;
      }
    }
  }

  // --------------------------------------------------------------------------------
  // SPOTLIGHT
  // --------------------------------------------------------------------------------
  &.teaser-single--spotlight {
    .teaser-single__inner {
      position: relative;
      align-items: flex-start;
      padding: var(--e-space-8) var(--e-space-8) var(--e-space-32);
      overflow: hidden;
      border-radius: var(--e-brd-radius-1);

      @include bp(lg) {
        padding-bottom: var(--e-space-8);
      }

      @include bp(m) {
        padding: var(--e-space-40) var(--e-space-4) var(--e-space-4);
      }

      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: rem(1061);
        height: rem(1061);
        background-color: var(--e-c-secondary-01-500);
        opacity: 0.85;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        @include bp(m) {
          top: auto;
          bottom: calc(var(--e-space-40) * -1);
          transform: translate(-50%, 50%);
        }
      }
    }

    .teaser-single__text-col {
      @include grid-col-indent(0);
      @include grid-col(6);

      z-index: 1;
      padding: var(--e-space-8);
      background-color: var(--e-c-mono-00);
      border-radius: var(--e-brd-radius-3);

      @include bp(m) {
        @include grid-col(12);

        padding: var(--e-space-6) var(--e-space-4);
      }
    }

    .teaser-single__image-col {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      height: 100%;
      touch-action: none;
    }

    .teaser-single__img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .teaser-single__title {
      margin-bottom: var(--e-space-2);

      h2 {
        @include type(500, strong);
      }
    }

    &.teaser-single--image-left {
      .teaser-single__inner {
        justify-content: flex-end;

        &::after {
          left: auto;
          right: 0;
          transform: translate(50%, -50%);

          @include bp(m) {
            top: auto;
            left: 0;
            right: auto;
            bottom: calc(var(--e-space-40) * -1);
            transform: translate(-50%, 50%);
          }
        }
      }
    }
  }
}

e-teaser-single:not(:defined) {
  opacity: 0;
}
