.landingpage-nav {
  z-index: 100;
  position: relative;
  height: rem(80);
  padding: 0 var(--e-space-6);
  background-color: rgba($c-mono-900, 0.16);
  margin-bottom: rem(-80);
  color: var(--e-c-mono-00);
  backdrop-filter: blur(10px);
  transition: background-color var(--e-trs-duration-faster) var(--e-trs-easing-default), color var(--e-trs-duration-faster) var(--e-trs-easing-default);

  .landingpage-nav__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .landingpage-nav__logo {
    position: absolute;
    display: block;
    left: 0;
    height: rem(32);
  }

  .landingpage-nav__logo-img {
    height: 100%;

    &.inverted {
      display: block;
    }

    &.normal {
      display: none;
    }
  }

  .landingpage-nav__nav {
    display: flex;
    gap: rem(16);
  }

  .landingpage-nav__nav-link {
    @include type(200, strong);

    padding: rem(8) rem(12);
    color: inherit;
    text-decoration: none;

    &.active,
    &:active,
    &:hover {
      transition: color var(--e-trs-duration-faster) var(--e-trs-easing-default), font-weight var(--e-trs-duration-faster) var(--e-trs-easing-default);
    }

    &:hover {
      color: var(--e-c-primary-01-100);
    }

    &:active,
    &.active {
      color: var(--e-c-primary-01-200);
    }
  }

  .landingpage-nav__ctas {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: var(--e-space-8);
  }

  .landingpage-nav__burger {
    display: none;
    cursor: pointer;

    svg {
      fill: var(--e-c-mono-00);
    }
  }

  .landingpage-nav__menu-top-bar {
    display: none;
  }

  // Mobile layout
  @include bp(lg) {
    .landingpage-nav__menu {
      position: fixed;
      display: none;
      padding: 0 $container-edge-lg;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--e-c-mono-00);
      z-index: 1;
    }

    .landingpage-nav__menu-top-bar {
      display: flex;
      justify-content: space-between;
      padding-top: var(--e-space-6);
      padding-bottom: var(--e-space-12);
    }

    .landingpage-nav__close-button,
    .landingpage-nav__burger {
      display: block;
    }

    .landingpage-nav__close-button {
      position: relative;
      right: rem(-24);
    }

    .landingpage-nav__nav {
      flex-direction: column;
      gap: 0;
    }

    .landingpage-nav__nav-link {
      @include type(200, weak);

      padding: var(--e-space-4) 0;
      color: var(--e-c-mono-900);

      &:hover,
      &.active {
        font-weight: bold;
        color: var(--e-c-primary-01-700);
      }
    }

    &.landingpage-nav--menu-open {
      .landingpage-nav__menu {
        display: block;
      }
    }
  }

  @include bp(m) {
    height: rem(56);

    .landingpage-nav__cta {
      display: none;
    }

    .landingpage-nav__logo {
      height: rem(24);
    }

    .landingpage-nav__menu-top-bar {
      padding-top: var(--e-space-4);
      padding-bottom: var(--e-space-10);
    }
  }

  @include bp(s) {
    padding: 0 var(--e-space-5);

    .landingpage-nav__menu {
      padding: 0 $grid-gutter-s;
    }

    .landingpage-nav__close-button {
      right: rem(-4);
    }
  }

  // Modifiers
  &.landingpage-nav--only-logo {
    .landingpage-nav__logo {
      position: static;
    }

    .landingpage-nav__burger {
      display: none;
    }
  }

  &.landingpage-nav--no-menu {
    .landingpage-nav__burger {
      display: none;
    }
  }

  &.landingpage-nav--no-overlap {
    margin-bottom: 0;
  }

  // States
  &.landingpage-nav--is-in-content:not(.landingpage-nav--menu-open) {
    background-color: rgba($c-mono-00, 0.5);
    color: var(--e-c-mono-900);

    .landingpage-nav__logo-img {
      &.inverted {
        display: none;
      }

      &.normal {
        display: block;
      }
    }

    .landingpage-nav__burger svg {
      fill: var(--e-c-mono-900);
    }

    .landingpage-nav__nav-link {
      &:hover {
        color: var(--e-c-secondary-01-900);
      }

      &:active,
      &.active {
        color: var(--e-c-primary-01-700);
      }
    }
  }
}

e-landingpage-nav:not(:defined) {
  opacity: 0;
}
