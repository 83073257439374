// stylelint-disable selector-no-qualifying-type
[is='e-tabs'],
.tabs {
  $scroll-space: 18px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--e-c-mono-100);
  }

  .tabs__wrapper {
    position: relative;
    width: 100%;
    height: calc(100% + #{$scroll-space});

    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: calc(100% - #{$scroll-space} - 1px);
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .tabs__list {
    position: relative;
    display: flex;
    align-items: flex-start;
    height: 100%;
    flex-wrap: nowrap;
    overflow: auto;

    a,
    button {
      @include type(200, strong);

      display: block;
      padding: var(--e-space-4);
      text-decoration: none;
      color: var(--e-c-mono-700);
      transition: color $trs-default;
      white-space: nowrap;
      outline-offset: -4px;

      &.active,
      &:focus,
      &:hover {
        color: var(--e-c-primary-01-700);
      }

      @include bp(lg) {
        @include type(100, strong);

        padding: var(--e-space-2) var(--e-space-3);
      }
    }
  }

  .tabs__active-bar {
    position: absolute;
    bottom: #{$scroll-space};
    height: 2px;
    background-color: var(--e-c-primary-01-200);
    transition: left $trs-default, width $trs-default;
  }
}

[is='e-tabs']:not(:defined) {
  opacity: 0;
}
