.icon-button {
  @include icon-button-base;

  // Default style 'FILLED'
  @include icon-button-filled;

  // Variants

  // --- FILLED INVERTED ---
  &.inverted {
    @include icon-button-filled-inverted;
  }

  // --- OUTLINED ---
  &.outlined {
    @include icon-button-outlined;
  }

  &.outlined.filled {
    &::before {
      background-color: var(--e-c-mono-00);
    }
  }

  // --- OUTLINED INVERTED ---
  &.outlined.inverted {
    @include icon-button-outlined-inverted;
  }

  // --- ICON ---
  &.icon-button--icon {
    @include icon-button-icon;
  }

  // --- ICON ---
  &.icon-button--icon.inverted {
    @include icon-button-icon-inverted;
  }
}
