@keyframes fadeInMessage {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutMessage {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

.message {
  opacity: 0;

  .message__inner {
    @include type(100, strong);
    position: relative;
    max-width: rem(405);

    margin: 0 auto;
    padding: var(--e-space-4) var(--e-space-14);
    border-radius: var(--e-space-2);
    color: var(--e-c-mono-00);
  }

  .message__icon {
    position: absolute;
    top: 50%;
    left: var(--e-space-4);
    transform: translateY(-50%);
  }

  .message__close-button {
    position: absolute;
    color: var(--e-c-mono-00);
    right: var(--e-space-4);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  // Modifiers
  &.message--success {
    .message__icon {
      color: var(--e-c-signal-01-500);
    }

    .message__inner {
      background-color: var(--e-c-signal-01-700);
    }
  }

  &.message--error {
    .message__icon {
      color: var(--e-c-signal-03-500);
    }

    .message__inner {
      background-color: var(--e-c-signal-03-700);
    }
  }

  // Animations
  &.message--fade-in {
    animation-name: fadeInMessage;
    animation-duration: var(--e-trs-duration-faster);
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  &.message--fade-out {
    animation-name: fadeOutMessage;
    animation-duration: var(--e-trs-duration-faster);
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
}

.message-container {
  z-index: $layer-message;
  position: fixed;
  bottom: var(--e-space-6);
  left: 0;
  display: grid;
  width: 100%;
  grid-gap: var(--e-space-4);
}
