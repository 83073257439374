.take-away-card {
  background-color: var(--e-c-secondary-02-500);
  border-radius: var(--e-space-3);
  color: var(--e-c-mono-00);
  padding: var(--e-space-8);
  height: 100%;

  @include bp('lg') {
    padding: var(--e-space-6);
  }

  .take-away-card__subtitle {
    margin-bottom: var(--e-space-4);

    @include type-300-strong;

    @include bp('lg') {
      @include type-200-strong;
    }
  }

  .take-away-card__title {
    padding-bottom: var(--e-space-2);

    > * {
      @include type(800, strong);
    }

    @include bp('lg') {
      padding-bottom: 0;

      > * {
        @include type(500, strong);
      }
    }
  }

  e-collapsible-content > *,
  .take-away-card__text {
    padding-top: var(--e-space-2);
    position: relative;
    display: block;

    @include type-300-weak;

    @include bp('lg') {
      @include type-200-weak;
    }
  }

  .take-away-card__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--e-c-mono-00);
    width: 100%;
    cursor: pointer;

    .take-away-card__header {
      @include type(300, strong);

      @include bp('lg') {
        @include type(200, strong);
      }
    }

    .take-away-card__button__icon {
      transition: transform var(--e-trs-duration-faster);
      display: none;

      @include bp('lg') {
        display: block;
      }
    }

    &.take-away-card__button--expanded {
      .take-away-card__button__icon {
        transform: rotate(180deg);
      }
    }
  }
}
