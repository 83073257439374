.top-bar-mobile {
  padding: var(--e-space-4) 0;
  background-color: var(--e-c-mono-00);
  border-bottom: 1px solid var(--e-c-mono-200);

  .top-bar-mobile__inner {
    display: flex;
    justify-content: space-between;
  }

  .top-bar-mobile__logo {
    .img {
      height: rem(24);
    }
  }

  top-bar-mobile__actions {
    display: flex;
    margin-left: auto;
  }

  .top-bar-mobile__action {
    display: block;

    &.menu {
      color: var(--e-c-primary-01-500);
    }
  }
}
