.teaser-wall {
  .teaser-wall__title {
    @include type(800, strong);

    margin-bottom: var(--e-space-10);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: var(--e-c-primary-01-500);
    max-width: 66.66%;

    @include bp('2xl') {
      max-width: 83.33%;
    }

    @include bp(xl) {
      max-width: 66.66%;
    }

    @include bp(lg) {
      @include type(700, strong);

      max-width: 50%;
    }

    @include bp(m) {
      @include type(500, strong);

      max-width: none;
      margin-bottom: var(--e-space-8);
    }
  }

  .teaser-wall__inner {
    @include grid-row;

    grid-row-gap: var(--e-space-10);

    [teaser-index='1'],
    [teaser-index='3'],
    [teaser-index='4'],
    [teaser-index='5'] {
      @include grid-col(4);

      //aspect-ratio: 16/25;
    }

    [teaser-index='2'] {
      @include grid-col(8);

      //aspect-ratio: 4/3;
    }

    @include bp(lg) {
      grid-row-gap: var(--e-space-6);

      [teaser-index='1'],
      [teaser-index='3'],
      [teaser-index='4'],
      [teaser-index='5'] {
        @include grid-col(6);
      }

      [teaser-index='2'] {
        @include grid-col(12);

        order: 1;
      }

      [teaser-index='1'] {
        order: 2;
      }

      [teaser-index='3'] {
        order: 3;
      }

      [teaser-index='4'] {
        order: 4;
      }

      [teaser-index='5'] {
        order: 5;
      }
    }

    @include bp(m) {
      grid-row-gap: var(--e-space-5);

      [teaser-index='1'],
      [teaser-index='2'],
      [teaser-index='3'],
      [teaser-index='4'],
      [teaser-index='5'] {
        @include grid-col(12);
      }
    }
  }
}
