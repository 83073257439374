.spectro {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;

  .spectro__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
