@media print {
  .richtext.richtext {
    ol, ul {
      li {
        list-style-position: outside;

        &::before {
          display: none;
        }
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }
  }
}
