.article-list-item {
  .article-list-item__inner {
    display: flex;
    grid-gap: var(--e-space-6);
    color: var(--e-c-mono-900);
    text-decoration: none;

    @include bp(lg) {
      grid-gap: var(--e-space-4);
    }
  }

  .article-list-item__content {
    order: 2;
    padding-top: var(--e-space-3);

    @include bp(lg) {
      padding-top: var(--e-space-1);
    }

    @include bp(m) {
      padding-top: 0;
    }

    @include bp(m) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .article-list-item__image {
    order: 1;
    flex: 0 0 rem(160);
    height: rem(160);
    aspect-ratio: 1;
    border-radius: var(--e-brd-radius-1);
    overflow: hidden;

    @include bp(m) {
      flex: 0 0 rem(110);
      height: rem(110);
    }
  }

  .article-list-item__title {
    margin-bottom: var(--e-space-2);

    h3 {
      @include type(400, strong);
    }

    @include bp(lg) {
      h3 {
        @include type(300, strong);
      }
    }

    @include bp(m) {
      h3 {
        @include type(200, strong);
      }
    }
  }

  .article-list-item__text {
    p {
      @include type(200);
    }

    @include bp(lg) {
      p {
        @include type(100);
      }
    }

    @include bp(m) {
      @include visually-hidden;
    }
  }

  .article-list-item__meta {
    @include type(100);

    display: flex;
    margin-top: var(--e-space-8);
    grid-gap: var(--e-space-1);
    color: var(--e-c-mono-700);

    @include bp(lg) {
      margin-top: var(--e-space-3);
    }

    @include bp(lg) {
      @include type(50);
    }

    @include bp(m) {
      margin-top: 0;
    }

    .article-list-item__meta__reading-time {
      &:not(:empty) {
        display: flex;
        grid-gap: var(--e-space-1);

        &::before {
          content: '•';
          display: inline;
        }
      }
    }
  }

  // Modifiers
  &.article-list-item--skeleton {
    + .article-list-item--skeleton {
      margin-top: var(--e-space-10);
    }

    .article-list-item__image {
      background-color: var(--e-c-mono-100);
    }

    .article-list-item__title,
    .article-list-item__meta,
    .article-list-item__text {
      background-color: var(--e-c-mono-100);
      color: transparent;

      &::before {
        content: '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _';
      }
    }
  }
}

e-article-list-item:not(:defined) {
  opacity: 0;
}
