[is='e-richtext'],
.richtext {
  // Spacing rules
  * + * {
    margin-top: var(--e-space-3);
  }

  ul + *,
  ol + * {
    margin-top: var(--e-space-8);
  }

  * + h2,
  * + h3,
  * + .richtext__title-large,
  * + .richtext__title-small {
    margin-top: var(--e-space-16);
  }

  * + ul,
  * + ol, {
    margin-top: var(--e-space-6);
  }

  @include bp(lg) {
    * + * {
      margin-top: var(--e-space-2);
    }

    ul + *,
    ol + * {
      margin-top: var(--e-space-6);
    }

    * + h2,
    * + h3,
    * + .richtext__title-large,
    * + .richtext__title-small {
      margin-top: var(--e-space-10);
    }
  }

  // Element styles
  h2,
  .richtext__title-large {
    @include type(700, strong);

    color: var(--e-c-primary-01-500);

    @include bp(lg) {
      @include type(400, strong);
    }
  }

  h3,
  .richtext__title-small {
    @include type(400, strong);

    color: var(--e-c-primary-01-500);

    @include bp(lg) {
      @include type(300, strong);
    }
  }

  p {
    @include type(300);

    @include bp(lg) {
      @include type(200);
    }
  }

  a {
    @include text-link;
  }

  ul,
  ol {
    @include type(300);

    padding-left: var(--e-space-4);

    > li {
      position: relative;
    }

    br {
      font-size: 0; // Firefox hack
    }

    @include bp(lg) {
      @include type(200);
    }
  }

  ul {
    > li {
      &::before {
        content: '';
        position: absolute;
        top: var(--e-space-2_5);
        left: calc(var(--e-space-4) * -1);
        display: block;
        width: rem(8);
        height: rem(8);
        border-radius: 100%;
        background-color: var(--e-c-secondary-05-500);
      }

      @include bp(lg) {
        &::before {
          top: var(--e-space-2);
        }
      }
    }
  }

  ol {
    counter-reset: li;

    > li {
      counter-increment: li;

      &::before {
        @include type(200, strong);

        content: counter(li);
        position: absolute;
        top: 2px;
        left: calc(var(--e-space-4) * -1);
        display: block;
        color: var(--e-c-secondary-05-500);
        font-weight: 700;
      }
    }

    @include bp(lg) {
      > li::before {
        top: 0;
      }
    }
  }

  u {
    text-underline-offset: rem(4);
  }

  p,
  h2,
  h3,
  a,
  li {
    text-wrap: pretty;
  }

  // Modifiers
  &.richtext--inverted {
    color: var(--e-c-mono-00);

    h2,
    h3,
    .richtext__title-small,
    .richtext__title-large {
      color: var(--e-c-mono-00);
    }

    a {
      @include text-link--inverted;
    }

    ul > li::before {
      background-color: var(--e-c-mono-00);
    }

    ol > li::before {
      color: var(--e-c-mono-00);
    }
  }

  // TODO: Spacing between must also be adjusted for richtext--small.
  &.richtext--small {
    p {
      @include type(200);
    }

    ul,
    ol {
      @include type(200);
    }

    ul {
      > li {
        &::before {
          top: var(--e-space-2);
        }
      }
    }

    ol {
      > li::before {
        top: 0;
      }
    }
  }
}
