@use '../../../scss/01_abstracts/design-tokens' as tokens;

.icon-grid-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &--bg-white .icon-grid-element__image-container {
    background-color: tokens.$c-primary-01-100;
  }

  &--bg-grey .icon-grid-element__image-container {
    background-color: white;
  }

  .icon-grid-element__image-container {
    flex: 0 0 auto;
    padding: tokens.$space-8;
    width: rem(120);
    height: rem(120);
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: tokens.$space-4;

    @include bp(m) {
      width: rem(96);
      height: rem(96);
    }
  }

  .icon-grid-element__title {
    color: tokens.$c-mono-900;
    margin-bottom: tokens.$space-3;

    @include type(400, strong);

    @include bp(m) {
      margin-bottom: tokens.$space-1;
    }
  }

  .icon-grid-element__description {
    line-height: tokens.$type-size-700;

    @include type(100);
  }
}
