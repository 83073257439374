.top-bar {
  .top-bar__inner {
    display: flex;
    padding: var(--e-space-8) 0;
  }

  .top-bar__logo {
    width: rem(196);

    @include bp(lg) {
      display: flex;
      align-items: center;
      width: rem(118);
    }
  }

  .top-bar__nav {
    margin-left: auto;
    display: flex;
  }

  .top-bar__secondary-nav {
    display: flex;

    &::before {
      content: '';
      margin-left: var(--e-space-8);
      margin-right: var(--e-space-8);
      display: inline-block;
      height: rem(40);
      width: 1px;
      background-color: var(--e-c-mono-500);
    }

    .nav-link {
      &:focus-visible {
        // what-input doesn't work in shadowDOM.
        // Add outline style here.
        outline-style: solid;
        outline-offset: 4px;
        outline-color: rgba($c-mono-500, 0.8);
      }
    }
  }

  // Modifiers
  &.top-bar--standalone {
    border-bottom: 1px solid var(--e-c-mono-200);

    @include bp(lg) {
      .top-bar__inner {
        padding: var(--e-space-4) 0;
      }
    }
  }
}
