@mixin cms-section-spacing-normal {
  padding: $section-default-padding-2xl 0;

  @include bp('2xl') {
    padding: $section-default-padding 0;
  }

  @include bp(lg) {
    padding: $section-default-padding-mobile 0;
  }
}

.cms-section {
  // TODO: check if we need to have position:relative here.
  position: relative;

  .cms-section__row {
    justify-content: center;
  }

  // This the default width, [width="full"]
  .cms-section__inner {
    @include grid-col(12);
  }

  // ----------------------------------- Width Variants ---------------------------------
  &.cms-section--width-content {
    .cms-section__inner {
      @include grid-col(10);
    }

    @include bp('xl') {
      .cms-section__inner {
        @include grid-col(12);
      }
    }

    @include bp(lg) {
      .cms-section__inner {
        @include grid-col(10);
      }
    }

    @include bp(m) {
      .cms-section__inner {
        @include grid-col(12);
      }
    }
  }

  &.cms-section--width-content-wide {
    .cms-section__inner {
      @include grid-col(10);
    }

    @include bp('2xl') {
      .cms-section__inner {
        @include grid-col(12);
      }
    }
  }

  // Nothing to style for this variant.
  // Inner layout will not be rendered by component.
  //&.cms-section--width-full-bleed {}

  // ----------------------------------- Spacing Variants ---------------------------------
  &.cms-section--space-normal {
    @include cms-section-spacing-normal;
  }

  // ----------------------------------- Background color Variants ---------------------------------
  // Currently only 'light' background, which is just transparent, is defined. Can easily be expanded.
  &.cms-section--bg-green {
    background-color: var(--e-c-secondary-01-50);
  }

  &.cms-section--bg-secondary-01 {
    background-color: var(--e-c-secondary-01-50);
  }

  &.cms-section--bg-gray {
    background-color: var(--e-c-mono-50);
  }
}

// We apply the collapsing behaviour logic on the web-component element,
// because all the layout markup is in the shadowDOM.
e-cms-section {
  @mixin collapse-behaviour($color-mod-selector) {

    &[space="normal"]#{$color-mod-selector} + [space="normal"]#{$color-mod-selector},
    &[space="normal"]#{$color-mod-selector} + [space="none"]#{$color-mod-selector},
    &[space="none"]#{$color-mod-selector} + [space="normal"]#{$color-mod-selector},
    &[space="none"]#{$color-mod-selector} + [space="none"]#{$color-mod-selector} {
      margin-top: calc(#{$section-default-padding-2xl} * -1);

      @include bp('2xl') {
        margin-top: calc(#{$section-default-padding} * -1);
      }

      @include bp(lg) {
        margin-top: calc(#{$section-default-padding-mobile} * -1);
      }
    }
  }

  display: block;

  @include collapse-behaviour('[bg="green"]');
  @include collapse-behaviour('[bg="white"]');
  @include collapse-behaviour('[bg="light"]'); // Remove this. Refactor in components used.
  @include collapse-behaviour('[bg="secondary-01"]'); // Remove this. Refactor in components used.
}

e-cms-section:not(:defined) {
  opacity: 0;
}
