.job-header {
  .job-header__inner {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: var(--e-space-8);
  }

  .job-header__intro {
    @include type(300);

    margin-bottom: var(--e-space-10);

    @include bp(lg) {
      @include type(200);
    }
  }

  .job-header__title {
    h1 {
      @include type(900, strong);
    }

    @include bp(lg) {
      h1 {
        @include type(800, strong);
      }
    }

    @include bp(m) {
      h1 {
        @include type(700, strong);
      }
    }
  }

  .job-header__meta {
    display: flex;
    margin-top: var(--e-space-8);
    align-items: center;
    grid-gap: var(--e-space-4);

    @include bp(lg) {
      margin-top: var(--e-space-4);
    }
  }

  .job-header__meta-item {
    &.location {
      @include type(300);

      display: flex;
      grid-gap: var(--e-space-1);

      e-icon {
        --fill-color: var(--e-c-mono-500);
      }

      @include bp(lg) {
        @include type(200);
      }
    }

    &.percentage {
      @include type(300);

      display: block;
      padding: var(--e-space-0_5) var(--e-space-1);
      color: var(--e-c-secondary-05-900);
      background-color: var(--e-c-secondary-05-100);
      border-radius: var(--e-brd-radius-1);

      @include bp(lg) {
        @include type(200);
      }
    }
  }

  .job-header__content {
    flex: 0 1 auto;
  }

  .job-header__actions {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    grid-gap: var(--e-space-8);

    @include bp(lg) {
      justify-content: space-between;
      padding-top: var(--e-space-6);
      padding-bottom: var(--e-space-6);
    }
  }

  .job-header__bottom-sticky {
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
    width: 100vw;
    border-top: 1px solid var(--e-c-mono-200);
    background-color: var(--e-c-mono-00);

    @include bp(lg) {
      display: block;
    }
  }
}

e-job-header:not(:defined) {
  opacity: 0;
}
