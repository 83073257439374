@use 'sass:math';
@import '../../../scss/01_abstracts/abstracts';
@import 'grid.mixin';

@import 'grid-row';

// These loops create column classes for the breakpoints 2xl, xl, lg, m and s.
// Obviously it will create classes that will probably never be used (CSS bloat).
// We could be using only the grid-col mixins.
// Or also define some commonly used responsive column configurations.
// We'll have to figure it out...
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    @include grid-col($i);
  }

  @if ($i < $grid-columns) {
    .col-indent-#{$i} {
      @include grid-col-indent($i);
    }
  }
}

// For "2xl" breakpoint
@include bp('2xl') {
  @for $i from 1 through $grid-columns {
    .col-2xl-#{$i} {
      @include grid-col($i);
    }

    @if ($i < $grid-columns) {
      .col-indent-2xl-#{$i} {
        @include grid-col-indent($i);
      }
    }

    // To clear indent on breakpoint xl
    .col-indent-2xl-0 {
      margin-left: 0;
    }
  }
}

// For "xl" breakpoint
@include bp(xl) {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      @include grid-col($i);
    }

    @if ($i < $grid-columns) {
      .col-indent-xl-#{$i} {
        @include grid-col-indent($i);
      }
    }

    // To clear indent on breakpoint xl
    .col-indent-xl-0 {
      margin-left: 0;
    }
  }
}

// For "lg" breakpoint
@include bp(lg) {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      @include grid-col($i);
    }

    @if ($i < $grid-columns) {
      .col-indent-lg-#{$i} {
        @include grid-col-indent($i);
      }
    }

    // To clear indent on breakpoint lg
    .col-indent-lg-0 {
      margin-left: 0;
    }
  }
}

// For "m" breakpoint
@include bp(m) {
  @for $i from 1 through $grid-columns {
    .col-m-#{$i} {
      @include grid-col($i);
    }

    @if ($i < $grid-columns) {
      .col-indent-m-#{$i} {
        @include grid-col-indent($i);
      }
    }

    // To clear indent on breakpoint m
    .col-indent-m-0 {
      margin-left: 0;
    }
  }
}

// For "s" breakpoint
@include bp(s) {
  @for $i from 1 through $grid-columns {
    .col-s-#{$i} {
      @include grid-col($i);
    }

    @if ($i < $grid-columns) {
      .col-indent-s-#{$i} {
        @include grid-col-indent($i);
      }
    }

    // To clear indent on breakpoint s
    .col-indent-s-0 {
      margin-left: 0;
    }
  }
}
