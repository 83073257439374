h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
figure,
blockquote {
  margin: 0;
}

button {
  padding: 0;
  margin: 0;
  appearance: none;
  border: 0;
  background: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

dl,
dt,
dd {
  margin: 0;
}

address {
  font-style: normal;
}
