.text-image {
  .text-image__text-col {
    @include grid-col(5);
    @include grid-col-indent(1);

    @include bp('2xl') {
      @include grid-col(6);
    }

    @include bp(xl) {
      @include grid-col-indent(0);
    }

    @include bp(m) {
      @include grid-col(12);

      order: 2;
    }
  }

  .text-image__image-col {
    @include grid-col(6);

    @include bp('2xl') {
      @include grid-col(5);
    }

    @include bp(xl) {
      @include grid-col(6);
    }

    @include bp(m) {
      @include grid-col(12);

      margin-bottom: var(--e-space-6);
      order: 1;
    }
  }

  .text-image__inner {
    align-items: center;

    @include bp('xl') {
      align-items: normal;
    }
  }

  .text-image__img {
    aspect-ratio: 3/2;
    border-radius: var(--e-brd-radius-1);
    overflow: hidden;
  }

  .text-image__caption {
    @include type(200);

    margin-top: var(--e-space-4);

    @include bp(lg) {
      @include type(100);

      margin-top: var(--e-space-2);
    }
  }

  // MODIFIERS
  &.text-image--image-position-left {
    .text-image__image-col {
      order: 1;
    }

    .text-image__text-col {
      @include grid-col-indent(0);

      order: 2;
    }
  }
}

e-text-image:not(:defined) {
  opacity: 0;
}
