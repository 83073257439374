.navigation-flyout {
  z-index: $layer-nav-flyout;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: opacity $trs-default, visibility $trs-default $trs-duration-faster;

  .navigation-flyout__inner {
    position: relative;
  }

  .navigation-flyout__container {
    position: absolute;
    top: 1px;
    left: 0;
    display: grid;
    width: 100%;
    padding: var(--e-space-10);
    grid-template-columns: 2fr 1fr;
    grid-gap: var(--e-space-16);
    box-shadow: var(--e-elevation-md);
    border-bottom-left-radius: var(--e-brd-radius-3);
    border-bottom-right-radius: var(--e-brd-radius-3);
    background-color: var(--e-c-mono-00);
    transform: translateY(-5%);
    transition: transform $trs-default;
  }

  .navigation-flyout__teaser-image {
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: var(--e-space-4);
    border-radius: var(--e-brd-radius-1);
    overflow: hidden;
  }

  .navigation-flyout__teaser-title {
    @include type(200, strong);
    min-height: rem(24);

    margin-bottom: var(--e-space-2);
  }

  .navigation-flyout__teaser-cta {
    display: flex;
    min-height: rem(40);
  }

  // Modifiers

  // SEARCH FLYOUT
  &.navigation-flyout--search {
    .navigation-flyout__container {
      display: block;
    }

    .navigation-flyout__subtitle {
      @include type(50, strong);

      padding-left: var(--e-space-2);
    }

    .navigation-flyout__quick-access-links,
    .navigation-flyout__search-results-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .navigation-flyout__search-results-all-link {
      margin-top: var(--e-space-8);
    }

    .navigation-flyout__search-results {
      display: none;
    }

    &.navigation-flyout--has-search-results {
      .navigation-flyout__quick-access {
        display: none;
      }

      .navigation-flyout__search-results {
        display: block;
      }
    }
  }

  // States
  &.navigation-flyout--open {
    opacity: 1;
    visibility: visible;
    transition: opacity $trs-default 20ms;

    .navigation-flyout__container {
      transform: translateY(0);
      transition: transform $trs-default 20ms;
    }
  }

  .navigation-flyout__teaser.loading {
    opacity: 1;

    .navigation-flyout__teaser-image,
    .navigation-flyout__teaser-title,
    .navigation-flyout__teaser-cta {
      @include skeleton-background;
    }

    .navigation-flyout__teaser-cta {
      width: 50%;
    }
  }
}
