// TODO: We'll need to define a general focus style. I'll disable the focus style for this link for the moment.

@mixin text-link() {
  font-weight: var(--type-weight-weak);
  color: var(--e-c-primary-01-700);
  text-underline-offset: $space-1;
  transition: all $trs-duration-faster ease-in-out;
  //outline-width: 0;
  position: relative;

  // make sure text-links are only active with class text-link
  //&.text-link {
  //  font-size: $type-size-200;
  //  line-height: $type-line-height-200;
  //}

  //&:focus-visible {
  //  &::before {
  //    border: 2px solid var(--c-mono-01--500);
  //  }
  //}

  //&::before {
  //  content: ' ';
  //  z-index: -1;
  //  position: absolute;
  //  top: -$sp-space-1_5;
  //  right: -$sp-space-1_5;
  //  bottom: -$sp-space-1_5;
  //  left: -$sp-space-1_5;
  //  border-radius: $sp-space-0_5;
  //  background: $c-mono-01--00;
  //  box-shadow: $e-elevation-xs, $e-elevation-xs, $e-elevation-sm, $e-elevation-md;
  //  opacity: 0;
  //  border-width: 0;
  //  transition: all $trs-duration-faster ease-in-out;
  //}

  &:hover {
    text-decoration-color: var(--e-c-primary-01-100);
  }

  &:active {
    text-decoration-color: var(--e-c-primary-01-700);
  }

  //&:focus-visible {
  //  &::before {
  //    opacity: 1;
  //  }
  //}

  &:active {
    color: var(--e-c-primary-01-700);
  }


  &.text-link--external {
    &::after {
      content: '\f8ce';
      font-family: 'Material Icons'; // stylelint-disable font-family-no-missing-generic-family-keyword
      font-size: var(--e-type-size-50);
    }
  }

  &.text-link--download {
    &::before {
      content: '\e5db';
      font-family: 'Material Icons';
      font-size: var(--e-type-size-50);
    }
  }
}

@mixin text-link--inverted {
  color: var(--e-c-primary-01-50);
  text-decoration-color: var(--e-c-primary-01-50);

  &:hover {
    text-decoration-color: var(--e-c-primary-01-200);
  }

  &:active {
    color: var(--e-c-primary-01-50);
    text-decoration-color: var(--e-c-primary-01-50);
  }
}

@mixin text-link--secondary {
  color: var(--e-c-mono-700);
  text-decoration: none;
  transition: color $trs-default;

  &:hover {
    color: var(--e-c-mono-900);
  }
}

.text-link {
  @include text-link;

  &.text-link--inverted {
    @include text-link--inverted;
  }

  &.text-link--secondary {
    @include text-link--secondary;
  }
}
