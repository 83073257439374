@media print {
  .header.header {
    &.header--has-facts {
      padding: 0 var(--e-space-6);
    }

    &.header--has-bg {
      display: flex;
      flex-flow: column;
      padding: var(--e-space-6) var(--e-space-10);
      min-height: auto;

      .header__bg-img {
        order: -1;
        position: relative;
        max-width: 50%;
        margin: 0 auto;
      }

      //display: none;
      .header__facts {
        position: relative;
      }

      .header__content {
        padding: var(--e-space-6) 0;
        color: var(--e-c-mono-900);

        h1 {
          @include type-200-strong;
        }
      }
    }
  }
}
