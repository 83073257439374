.contact-box {
  .contact-box__title {
    margin-bottom: var(--e-space-4);

    h2 {
      @include type(300, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(m) {
      margin-bottom: var(--e-space-2);

      h2 {
        @include type(300, strong);
      }
    }
  }

  .contact-box__contact {
    display: flex;
    align-items: center;
    grid-gap: var(--e-space-8);
    padding: var(--e-space-6) var(--e-space-8) var(--e-space-6) var(--e-space-6);
    background-color: var(--e-c-mono-50);
    border-radius: var(--e-brd-radius-2);

    @include bp(m) {
      flex-direction: column;
      grid-gap: var(--e-space-4);
      padding: var(--e-space-8) var(--e-space-6) var(--e-space-10) var(--e-space-6);
    }
  }

  .contact-box__image {
    flex: 0 0 auto;
    width: rem(120);
    height: rem(120);
    border-radius: 100%;
    border: 1px solid var(--e-c-mono-200);
    overflow: hidden;

    @include bp(m) {
      width: rem(96);
      height: rem(96);
    }
  }

  .contact-box__content {
    @include bp(m) {
      text-align: center;
    }
  }

  .contact-box__name {
    @include type(700, strong);

    @include bp(m) {
      @include type(500, strong);
    }
  }

  .contact-box__function {
    @include type(300);

    color: var(--e-c-mono-700);

    @include bp(m) {
      @include type(200);
    }
  }

  .contact-box__phone {
    @include type(300);

    display: flex;
    grid-gap: var(--e-space-2);
    margin-top: var(--e-space-4);
    color: var(--e-c-mono-700);

    a {
      @include text-link;
    }

    @include bp(m) {
      @include type(200);

      margin-top: var(--e-space-6);
    }
  }
}

e-contact-box:not(:defined) {
  opacity: 0;
}
