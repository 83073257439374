@mixin button-filled-inverted {
  background-color: $c-mono-00;
  border-color: $c-mono-00;
  color: $c-primary-01-700;

  ec-loader {
    --dot-color: #{$c-secondary-01-900};
  }

  e-icon {
    --fill-color: #{$c-primary-01-700};
  }

  &:hover {
    background-color: $c-primary-01-100;
    border-color: $c-primary-01-100;
    color: $c-secondary-01-700;

    e-icon {
      --fill-color: #{$c-secondary-01-700};
    }
  }

  &:active,
  &[loading] {
    background-color: $c-primary-01-200;
    border-color: $c-primary-01-200;
  }

  &:disabled:not([loading]) {
    color: $c-mono-700;

    e-icon {
      --fill-color: #{$c-mono-700};
    }
  }
}
