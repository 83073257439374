.form-grid {
  width: 100%;

  .form-row {
    @include grid-row;
  }

  .form-col {
    // Default column width
    @include grid-col(6);
  }

  .form-col-1\/4 {
    @include grid-col(3);
  }

  .form-col-3\/4 {
    @include grid-col(9);
  }

  .form-col,
  .form-col-1\/4,
  .form-col-3\/4 {
    @include bp(lg) {
      @include grid-col(12);
    }
  }
}
