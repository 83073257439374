@import 'button-base';
@import 'button-filled';
@import 'button-filled-inverted';
@import 'button-outlined';
@import 'button-outlined-inverted';

.button {
  @include button-base;

  //---- PRIMARY 01 ----

  // ------ FILLED
  @include button-filled;

  // ------ FILLED / INVERTED
  &.inverted {
    @include button-filled-inverted;
  }

  // ------ OUTLINED
  &.outlined {
    @include button-outlined;
  }

  // Sub variant of OUTLINED
  &.outlined.filled {
    background-color: var(--e-c-mono-00);
  }

  // ------ OUTLINED / INVERTED
  &.outlined.inverted {
    @include button-outlined-inverted;
  }

  // ------ PLAIN / BIG
  &.plain {
    @include button-plain-big;

    &.no-h-space {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // ------ PLAIN / SMALL
  &.plain.small {
    @include button-plain-small;
  }

  // ---- SECONDARY 02

  // ------ OUTLINED
  &.secondary-02.outlined {
    @include button-secondary-02-outlined;
  }

  &.secondary-02.outlined.small {
    @include button-secondary-02-outlined-small;
  }

  // States
  &[is='e-loader-button'][loading] {
    color: transparent;

    e-icon {
      --fill-color: transparent;
    }
  }
}
