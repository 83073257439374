//@import '../../../scss/03_base/svg-sprite';

e-icon {
  --fill-color: currentColor;
  --transition: none;

  // Prevent FOUC. https://leoneck.de/blog/fouc-web-components
  &:not(:defined) {
    opacity: 0;
    display: block;

    // We don't actually know yet the size of the icon
    // 24x24 is the most common size.
    width: rem(24);
    height: rem(24);
  }
}

.icon {
  display: block;
  fill: var(--fill-color);
  // TODO: refactor this. per default there should be no transitions. (buttons need transition on icons.)
  //transition: fill var(--e-trs-duration-faster) var(--e-trs-easing-default);

  // solution for the above
  transition: var(--transition);

  &.icon--custom-size {
    width: 100%;
    height: 100%;
  }

  &.icon--inline {
    display: inline-block;
    vertical-align: baseline;
  }
}
