@media print {
  .top-navigation.top-navigation,
  [is='e-top-navigation'][is='e-top-navigation'] {
    border: none;

    nav {
      display: none;
    }

    .main-navigation__search-bar, .top-bar-mobile__actions {
      display: none;
    }
  }
}
