.nav-menu-link,
[is='e-nav-menu-link'] {
  @include type(200);

  position: relative;
  display: inline-flex;
  padding: var(--e-space-3) var(--e-space-2);
  text-decoration: none;
  color: var(--e-c-mono-900);
  transition: color $trs-default;

  @include bp(lg) {
    padding: var(--e-space-4) 0;
    width: 100%;
  }

  .nav-menu-link__icon {
    pointer-events: none;
    position: absolute;
    left: 0;

    @include bp(lg) {
      right: 0;
      left: auto;
      display: none;
    }
  }

  .nav-menu-link__text {

    // why gtm, why?
    pointer-events: none;
    position: relative;
    display: inline-block;
    background-color: var(--e-c-mono-00);
    transition: transform var(--e-trs-duration-faster) cubic-bezier(0.250, 0.460, 0.450, 0.940);

    > span:first-child {
      word-break: break-word;
    }
  }

  &:active,
  &:hover,
  &.selected {
    color: var(--e-c-secondary-01-900);

    .nav-menu-link__text {
      transform: translateX(#{rem(20)});
    }

    @include bp(lg) {
      .nav-menu-link__text {
        transform: none;
      }
    }
  }

  &:active {
    @include type(200, strong);
  }

  &.selected {
    @include type(200, strong);

    color: var(--e-c-primary-01-700);
  }

  &.nav-menu-link--has-children {
    @include bp(lg) {
      .nav-menu-link__icon {
        display: block;
      }
    }
  }

  // Modifiers
  &.nav-menu-link--is-download {
    .nav-menu-link__text {
      display: flex;
      column-gap: var(--e-space-2);

      @include bp(lg) {
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &.nav-menu-link--skeleton {
    pointer-events: none;

    .nav-menu-link__icon {
      opacity: 0;
    }

    .nav-menu-link__text {
      @include skeleton-background;

      &::before {
        content: '_';
        display: inline-block;
        width: 200px;
      }
    }
  }
}
