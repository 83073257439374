.facts {
  display: flex;
  align-items: center;
  min-height: rem(66);
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: var(--e-space-4);
  padding-bottom: var(--e-space-4);

  @include bp(xl) {
    min-height: rem(88);
  }

  @include bp(m) {
    height: auto;
  }

  .facts__inner {
    width: 100%;
  }

  .facts__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: var(--e-space-2);
    flex-wrap: nowrap;

    @include bp(lg) {
      grid-gap: var(--e-space-6) $grid-gutter-lg;
    }

    @include bp(m) {
      justify-content: initial;
      flex-wrap: wrap;
    }
  }

  .facts__item {
    @include bp(m) {
      @include grid-col(6);
      word-break: break-word;
    }
  }
}

e-facts:not(:defined) {
  opacity: 0;
}
