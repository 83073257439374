.info-box {
  position: relative;
  padding: var(--e-space-8) var(--e-space-8) var(--e-space-8) var(--e-space-12);
  border-top-right-radius: var(--e-brd-radius-3);
  border-bottom-right-radius: var(--e-brd-radius-3);
  background-color: var(--e-c-mono-50);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: rem(12);
    background-color: var(--e-c-secondary-01-500);
  }

  .info-box__title {
    margin-bottom: var(--e-space-2);

    > * {
      @include type(700, strong);

      color: var(--e-c-primary-01-500);

      @include bp(lg) {
        @include type(400, strong);
      }
    }
  }
}

e-info-box:not(:defined) {
  opacity: 0;
}
