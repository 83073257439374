.form {
  .form__messages {
    display: none;
  }

  .form__title {
    @include type(800, strong);

    color: var(--e-c-primary-01-500);

    @include bp(lg) {
      @include type(700, strong);
    }
  }

  .form__lead {
    @include type(200, weak);

    .form__subtitle-info {
      color: var(--e-c-secondary-01-900);
      margin-left: var(--e-space-1);

      e-icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .form__title + .form__lead {
    @include type(300);

    @include bp(lg) {
      @include type(200);
    }
  }

  .form__subtitle {
    @include type(300, strong);

    color: var(--e-c-primary-01-500);

    .form__subtitle-info {
      color: var(--e-c-secondary-01-900);
      margin-left: var(--e-space-1);

      e-icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .form__cta-group {
    display: flex;
    grid-gap: var(--e-space-5);

    @include bp(m) {
      flex-direction: column;
    }
  }

  // Spacing rules for text elements.
  .form__title + .form__lead,
  .form__subtitle + .form__lead {
    margin-top: var(--e-space-3); // get 12px from title to lead

    @include bp(lg) {
      margin-top: var(--e-space-1_5); // get 6px from title to lead
    }
  }

  // Vertical spacing classes
  // These classes will be set programatically for 'e-form' extension.

  // Special case
  .form__lead-row + * {
    margin-top: var(--e-space-5);

    @include bp(lg) {
      margin-top: var(--e-space-3);
    }
  }

  .form__subtitle-row + *,
  .form__lead-row.form__subtitle-row + * {
    margin-top: var(--e-space-6);

    @include bp(lg) {
      margin-top: var(--e-space-3);
    }
  }

  .form__input-row + .form__input-row,
  .form__title-row + *,
  *:not(legend) + .form__lead-row {
    margin-top: var(--e-space-10);

    @include bp(lg) {
      margin-top: var(--e-space-6);
    }
  }

  *:not(legend) + .form__title-row,
  *:not(legend) + .form__cta-row,
  *:not(legend) + .form__subtitle-row {
    margin-top: var(--e-space-16);

    @include bp(lg) {
      margin-top: var(--e-space-10);
    }
  }

  .form__input-col + .form__input-col {
    @include bp(lg) {
      margin-top: var(--e-space-6);
    }
  }

  .form__messages-row {
    display: none;
    margin-top: var(--e-space-10);
  }

  .form__cookiebot-message {
    margin-bottom: var(--e-space-6);
  }
  //.alert + .button {
  //  margin-top: var(--e-space-6);
  //}

  // States
  &.form--has-general-error {
    .form__messages-row,
    .form__messages {
      display: block;
    }
  }

  &.form--hide-form-elements {
    > *:not(.success-screen) {
      display: none;
    }
  }

  &.form--is-submitting {
    .form-row:not(.form__cta-row) {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .form__recaptcha {
    font-size: rem(10);
    line-height: 1.5;
    margin-top: var(--e-space-6);
    color: var(--e-c-mono-700);

    a {
      color: var(--e-c-primary-01-700);
      text-underline-offset: var(--e-space-1);
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
