.language-nav {
  @include type(100, weak);

  display: flex;
  grid-gap: var(--e-space-4);

  .language-nav__active-lang,
  > p {
    color: var(--e-c-primary-01-900);
  }

  .language-nav__link,
  > a {
    @include text-link--secondary;
  }

  @include bp(lg) {
    @include type(50);
  }
}
