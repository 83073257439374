.faq-card {
  border-radius: var(--e-brd-radius-3);
  box-shadow: var(--e-elevation-md);
  overflow: hidden;

  .faq-card__content {
    padding: var(--e-space-8);

    @include bp(xl) {
      padding: var(--e-space-6);
    }
  }

  .faq-card__img-container {
    position: relative;
    width: 100%;
    padding-top: 56%;

    .faq-card__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .faq-card__title {
    color: var(--e-c-primary-01-500);

    ::slotted(h3) {
      @include type(700, strong);
    }

    @include bp(lg) {
      ::slotted(h3) {
        @include type(500, strong);
      }
    }

    @include bp(m) {
      ::slotted(h3) {
        @include type(400, strong);
      }
    }
  }

  .faq-card__text {
    margin-top: var(--e-space-4);

    @include bp(m) {
      margin-top: var(--e-space-2);
    }
  }

  .faq-card__faqs {
    margin-top: var(--e-space-6);
    display: flex;
    flex-flow: column;
    gap: var(--e-space-1);
  }
}

e-faq-card:not(:defined) {
  opacity: 0;
}
