// esri-map overrides
.esri-zoom {
  display: flex;
  grid-gap: var(--e-space-4);
  padding: var(--e-space-4);
  width: var(--e-space-20);
  height: var(--e-space-36);
  border-radius: var(--e-brd-radius-2);
  border: 0;
  //border: 1px solid var(--e-c-mono-500);
  box-shadow: none;

  @include bp(m) {
    visibility: hidden;
  }
}

.esri-ui-corner .esri-component {
  box-shadow: none;
}

.esri-zoom .esri-widget--button {
  width: var(--e-space-12);
  height: var(--e-space-12);
  border: 2px solid var(--e-c-mono-500);
  border-radius: 100%;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--e-c-mono-100);
  }
}

.esri-zoom .esri-widget--button:last-child {
  border-top: 2px solid var(--e-c-mono-500);
}

.esri-icon {
  position: relative;
  width: 24px;
  height: 24px;
}

.esri-icon-plus {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
    display: block;
    width: 18px;
    height: 2px;
    background-color: var(--e-c-mono-900);
  }

  &::after {
    transform: rotate(90deg);
  }
}

.esri-icon-minus {
  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 9px);
    display: block;
    width: 18px;
    height: 2px;
    background-color: var(--e-c-mono-900);
  }
}

.esri-attribution {
  // For legal reasons we should still show the attribution.
  // TODO: Check if we can really hide it.
  //display: none;
  font-size: 8px;
  line-height: 12px;
}
