@use '../../../scss/01_abstracts/design-tokens' as tokens;

.select-tile-group {
  padding: tokens.$space-10;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(570px, 1fr));
  grid-auto-rows: 1fr;
  align-items: stretch;
  grid-gap: tokens.$space-6;
  margin-bottom: tokens.$space-6;

  @include bp(m) {
    display: flex;
    flex-wrap: wrap;
  }
}
