e-collapsible-content {
  display: block;
  transition: height var(--e-trs-duration-faster);
  height: 0;
  overflow: hidden;
}

.collapsible-content__content {
  display: inline;
}
