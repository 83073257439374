.usp {
  display: flex;
  grid-gap: var(--e-space-3);
  align-items: center;
  padding: var(--e-space-4) 0;

  @include bp(xl) {
    flex-direction: column;
    grid-gap: var(--e-space-2);
  }

  @include bp(m) {
    padding: 0;
    flex-direction: row;
    grid-gap: var(--e-space-3);
  }

  .usp__icon {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    width: rem(48);
    height: rem(48);
    border-radius: 100%;
    background-color: var(--e-c-mono-00);

    @include bp(xl) {
      width: rem(40);
      height: rem(40);
    }
  }

  e-icon {
    --fill-color: var(--e-c-primary-01-500);

    @include bp(xl) {
      transform: scale(0.833);
    }
  }

  .usp__value {
    @include type(300, strong);

    color: var(--e-c-mono-00);

    @include bp(xl) {
      @include type(100, strong);
    }
  }
}
