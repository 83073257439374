// TODO: remove this as soon the depending components are refactored.

@import '../../../scss/01_abstracts/abstracts';

@mixin cms-section-spacing-normal {
  padding: $section-default-padding-2xl 0;

  @include bp('2xl') {
    padding: $section-default-padding 0;
  }

  @include bp(lg) {
    padding: $section-default-padding-mobile 0;
  }
}
