@mixin icon-button-filled-inverted {
  &::before {
    background-color: $c-mono-00;
    border-color: $c-mono-00;
  }

  e-icon {
    --fill-color: var(--e-c-primary-01-700);
  }

  &:hover {
    &::before {
      background-color: $c-primary-01-100;
      border-color: $c-primary-01-100;
    }

    e-icon {
      --fill-color: var(--e-c-secondary-01-700);
    }
  }

  &:active {
    &::before {
      background-color: $c-primary-01-200;
      border-color: $c-primary-01-200;
    }
  }

  &:disabled {
    &::before {
      background-color: $c-mono-500;
      border-color: $c-mono-500;
    }

    e-icon {
      --fill-color: var(--e-c-mono-700);
    }
  }
}
