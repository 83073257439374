.downloads {
  .downloads__search-bar {
    margin-bottom: var(--e-space-8);

    @include bp(xl) {
      margin-bottom: var(--e-space-6);
    }

    @include bp(lg) {
      //flex-direction: column-reverse;
      grid-gap: var(--e-space-6);
    }
  }

  .downloads__search-info {
    @include type(600);

    @include bp(xl) {
      @include type(300);
    }
  }

  .downloads__no-results {
    @include type(200);

    margin-top: var(--e-space-1);
    display: none;
  }

  .downloads__download-list {
    margin-top: var(--e-space-8);

    @include bp(xl) {
      margin-top: var(--e-space-6);
    }
  }

  .downloads__paginator {
    display: none;
  }

  // Modifiers
  &.downloads--has-pagination {
    .downloads__paginator {
      @include grid-row;

      justify-content: center;
      margin-top: var(--e-space-10);
    }
  }

  // States
  &.downloads--is-searching {
    .downloads__paginator {
      display: none;
    }
  }

  &.downloads--no-results {
    .downloads__download-list {
      display: none;
    }

    .downloads__no-results {
      display: block;
    }
  }
}

e-downloads:not(:defined) {
  opacity: 0;
}
