[is='e-loader-button'] {
  // States
  &[loading] {
    color: transparent;

    e-icon {
      --fill-color: transparent;
    }
  }
}
