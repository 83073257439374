@mixin button-plain-big {
  padding: var(--e-space-2) var(--e-space-3);
  color: var(--e-c-primary-01-700);
  background: none;
  border: 0;
  text-decoration: none;
  border-radius: 0;
  grid-gap: var(--e-space-1);

  e-icon {
    --fill-color: var(--e-c-primary-01-700);
  }

  &:hover {
    color: var(--e-c-secondary-01-900);

    // TODO: Refactor/remove this. Because icon uses currentColor.
    e-icon {
      --fill-color: var(--e-c-secondary-01-900);
    }
  }

  &:active {
    color: var(--e-c-secondary-01-700);

    e-icon {
      --fill-color: var(--e-c-secondary-01-700);
    }
  }

  &:disabled:not([loading]) {
    background: none;
    color: var(--e-c-mono-500);

    e-icon {
      --fill-color: var(--e-c-mono-500);
    }
  }

  &[loading] {
    ec-loader {
      --dot-color: var(--e-c-secondary-01-700);
    }
  }
}
