@use '../../../scss/01_abstracts/design-tokens' as tokens;

$colors-map: (
  // Dark mode?
  "1": transparent,
  "2": transparent,
  "3": tokens.$c-primary-01-100,
  "4": tokens.$c-primary-01-200,
  "5": tokens.$c-primary-01-500,
);

$th_image_width: 80px;
//$th_image_height: 40px;

[is='e-richtext'] {
  * + .richtext__table {
    margin-top: var(--e-space-4);
  }

  .richtext__table + * {
    margin-top: var(--e-space-4);
  }

  .richtext__table {
    position: relative;
    width: calc((10 / 12) * (#{rem($container-inner-2xl)} - (2 * #{$container-edge-2xl})));
    overflow: auto;

    @include bp('2xl') {
      width: calc((10 / 12) * (100vw - (2 * #{$container-edge-2xl})));
    }

    @include bp(lg) {
      width: calc((10 / 12) * (100vw - (2 * #{$container-edge-lg})));
    }

    @include bp(m) {
      width: 100%;
    }
  }

  // stylelint-disable
  table {
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    min-width: rem(320);

    &:not(.table-fixed-width) {
      width: auto !important; // Because ckeditor adds a fixed width in px.
    }

    &.zebra-horizontal {
      thead {
        tr {
          background-color: transparent;
        }
      }

      tr {
        background-color: var(--e-c-mono-50);

        &:nth-child(2n) {
          background-color: transparent;
        }
      }
    }

    &.zebra-vertical {
      td,
      th {
        background-color: transparent;

        &:nth-child(2n) {
          background-color: var(--e-c-mono-50);
        }
      }
    }

    &.gas-price-table {
      table-layout: auto;
      width: 1120px !important;

      td,
      th {
        background-color: transparent;

        @each $position, $color in $colors-map {
          &:nth-child(#{$position}) {
            background-color: $color;
            border-left: tokens.$space-3 solid white;
            padding-left: tokens.$space-5;
          }
        }
      }

      &.gas-price-table__fixed-header {
        @include bp('2xl') {
          td:first-child,
          th:first-child {
            position: sticky;
            left: 0;
            margin-left: 0;
            border-left: initial;
            background-color: white;
            z-index: 2;
          }
        }
      }

      .gas-price-table__heading-container {
        display: grid;
        column-gap: tokens.$space-3;
        grid-template-columns: 1fr $th_image_width;
      }
    }

    p {
      @include type(300, weak);
    }
  }

  td,
  th {
    @include type(300);

    border: none;
    padding: var(--e-space-2);
    max-width: 460px;
    min-width: var(--e-space-32);

    @include bp(lg) {
      @include type(200);
    }
  }

  th {
    vertical-align: top;
    text-align: left;
    font-weight: var(--e-type-weight-strong);

    &:has(img) {
      margin-top: 0;
    }

    img {
      order: 2;
      width: $th_image_width;
      //height: $th_image_height;
      height: auto;
      object-position: center;
      padding-left: 0;
      margin-top: 0;
    }
  }
}
