.media-light-box {
  border: 0;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: none;

  @include bp(xl) {
    padding: 0;
    margin: 0;
  }

  &[open] {
    display: flex;
  }

  .media-light-box__close-cta-container {
    align-items: center;
    background-color: white;
    border-radius: var(--e-space-12);
    display: flex;
    height: var(--e-space-12);
    justify-content: center;
    position: absolute;
    right: var(--e-space-8);
    top: var(--e-space-8);
    width: var(--e-space-12);
    z-index: 10;

    .media-light-box__close-cta {
      cursor: pointer;
      padding: var(--e-space-8);
    }
  }


  .media-light-box__container {
    margin: 3% 10%;
    width: 100%;

    @include bp(lg) {
      margin: 0;
    }

    .media-light-box__content-container {
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;

      e-video, e-img {
        width: 100%;
      }
      e-img {
        height: 100%;
      }

      @include bp(xl) {
        height: 80vh;
      }

      @include bp(lg) {
        height: 100vh;
        e-img {
          height: auto;
        }
      }
    }
  }

  &::backdrop {
    background-color: var(--e-c-mono-900);
    opacity: 0.4;
  }
}

body:has(.media-light-box[open]) {
  overflow: hidden;
}
