@use 'sass:map';

// Accessibility
@mixin visually-hidden {
  position: absolute;
  display: block;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
}

// Media query helper mixin
@mixin bp($query) {
  @media (max-width: #{map.get($mediaquery-token-map, #{'mq-breakpoint-' + $query})}) {
    @content;
  }
}

// Type (common configurations)

// ----------------------------   STRONG   -------------------------------
@mixin type-100-strong {
  font-size: $type-size-100;
  line-height: $type-line-height-100;
  font-weight: $type-weight-strong;
}

@mixin type-200-strong {
  font-size: $type-size-200;
  line-height: $type-line-height-200;
  font-weight: $type-weight-strong;
}

@mixin type-300-strong {
  font-size: $type-size-300;
  line-height: $type-line-height-300;
  font-weight: $type-weight-strong;
}

@mixin type-500-strong {
  font-size: $type-size-500;
  line-height: $type-line-height-500;
  font-weight: $type-weight-strong;
}

@mixin type-700-strong {
  font-size: $type-size-700;
  line-height: $type-line-height-700;
  font-weight: $type-weight-strong;
}

@mixin type-800-strong {
  font-size: $type-size-800;
  line-height: $type-line-height-800;
  font-weight: $type-weight-strong;
}

// ----------------------------   WEAK   -------------------------------

@mixin type-100-weak {
  font-size: $type-size-100;
  line-height: $type-line-height-100;
  font-weight: $type-weight-weak;
}

@mixin type-200-weak {
  font-size: $type-size-200;
  line-height: $type-line-height-200;
  font-weight: $type-weight-weak;
}

@mixin type-300-weak {
  font-size: $type-size-300;
  line-height: $type-line-height-300;
  font-weight: $type-weight-weak;
}

@mixin type-600-weak {
  font-size: $type-size-600;
  line-height: $type-line-height-600;
  font-weight: $type-weight-weak;
}


// Small helper because font-size and line-height are basically are always the same size.
@mixin type($size, $weight: null) {
  font-size: var(--e-type-size-#{$size});
  line-height: var(--e-type-line-height-#{$size});

  @media print {
    font-size: calc(var(--e-type-size-#{$size}) * 0.75);
    line-height: calc(var(--e-type-line-height-#{$size}) * 0.75);
  }

  @if $weight {
    font-weight: var(--e-type-weight-#{$weight});

    @if $weight == strong {
      // TODO: find a better way to set this smoothing properties.
      // Now they would be rendered multiple times on every type(xxx, strong).
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
