.tooltip {
  pointer-events: none;
  z-index: $layer-tooltip;
  position: absolute;
  top: 0;
  left: 0;
  max-width: rem(360);
  min-width: rem(84);
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity $trs-default, transform $trs-default;

  @include bp(lg) {
    max-width: rem(280);
  }

  .tooltip__content {
    @include type(100);
    z-index: 0;

    position: relative;
    padding: var(--e-space-2);
    background-color: var(--e-c-mono-00);
    box-shadow: var(--e-elevation-sm);
    border: 1px solid var(--e-c-mono-100);
    border-radius: var(--e-brd-radius-1);

    @include bp(lg) {
      @include type(50);

      padding: var(--e-space-3);
    }
  }

  .tooltip__pointer {
    position: absolute;
    bottom: -4px;
    left: calc(50% - 6px);

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 9.6px;
      transform: rotate(53.13deg) skewX(15.36deg);
      box-shadow: var(--e-elevation-sm);
      background-color: var(--e-c-mono-00);
      border-right: 1px solid var(--e-c-mono-100);
      border-bottom: 1px solid var(--e-c-mono-100);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: -2px;
      width: 14px;
      height: 7px;
      background: var(--e-c-mono-00);
    }
  }

  // States
  &.tooltip--open {
    opacity: 1;
    transform: translateY(0);
  }
}
