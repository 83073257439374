@use '../../../scss/01_abstracts/design-tokens' as tokens;

.toggle-switch.toggle-switch--small {
  // ==== Position and sizes (local variables) ====
  $iconPosition: tokens.$space-2;
  $iconSize: tokens.$space-6;
  $iconStartPosition: 0;
  $switchHeight: tokens.$space-8;
  $switchWidth: calc(tokens.$space-14);
  // (wrapper width - icon size) - (start position - border)
  $iconActivePosition: calc(($switchWidth - $iconSize) - ($iconPosition));
  $iconActivePositionNegative: (-$iconActivePosition);

  // ==== Start Styles ====
  column-gap: tokens.$space-4;
  cursor: pointer;
  display: grid;
  grid-template-areas: 'background label';
  grid-template-columns: $switchWidth 1fr;
  grid-template-rows: $switchHeight;
  padding: tokens.$space-1;
  position: relative;

  .toggle-switch__background {
    background-color: tokens.$c-mono-700;
    border-radius: 100px;
    grid-area: background;
    transition: background-color tokens.$trs-duration-fast tokens.$trs-easing-default;
  }

  .toggle-switch__input:checked ~ .toggle-switch__background {
    background-color: tokens.$c-primary-01-700;
  }
  .toggle-switch__input:disabled ~ .toggle-switch__background {
    background-color: tokens.$c-mono-50;
  }

  // Label (wrapper)
  .toggle-switch__label {
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    grid-area: label;

    // Label text (span)
    .toggle-switch__text {
      @include type(200);
    }

    // Label Icon (span)
    .toggle-switch__icon {
      background-color: white;
      border-radius: 100px;
      height: $iconSize;
      left: $iconPosition;
      padding: tokens.$space-1;
      position: absolute;
      right: auto;
      top: tokens.$space-2;
      width: $iconSize;
      // Start position
      transform: translateX(0);
      transition: transform tokens.$trs-duration-fast;
    }

    // Label icon active state
    &:has(~ .toggle-switch__input:checked) .toggle-switch__icon {
      transform: translateX($iconActivePosition);
    }
    &:has(~ .toggle-switch__input:checked):not(&:has(~ .toggle-switch__input:disabled)) .toggle-switch__icon {
      color: tokens.$c-primary-01-700;
    }
  }

  // ==== Left position label layout ====
  &.toggle-switch--label-left {
    column-gap: tokens.$space-4;
    grid-template-areas: 'label background';
    grid-template-columns: 1fr $switchWidth;

    .toggle-switch__label .toggle-switch__icon {
      right: $iconPosition;
      left: auto;
      transform: translateX($iconActivePositionNegative);
    }

    &:has(.toggle-switch__input:checked) .toggle-switch__label .toggle-switch__icon {
      // Reset to start position
      transform: translateX($iconStartPosition);
    }
  }

  // ==== Hover State ====
  &:not(&:has(.toggle-switch__input:disabled)) {
    // Active hover: icon or background hover in a input check state
    &:has(.toggle-switch__input:checked) {
      .toggle-switch__background:hover,
      &:has(.toggle-switch__icon:hover) .toggle-switch__background {
        background-color: tokens.$c-secondary-01-900;
        color: tokens.$c-secondary-01-900;
      }
    }

    // Non Active hover: icon or background hover in a non input check state
    &:not(&:has(.toggle-switch__input:checked)) {
      .toggle-switch__background:hover,
      &:has(.toggle-switch__icon:hover) .toggle-switch__background {
        background-color: tokens.$c-mono-500;
      }
    }
  }

  // ==== Disable cursor ====
  &:has(.toggle-switch__input:disabled),
  &:has(.toggle-switch__input:disabled) .toggle-switch__background,
  &:has(.toggle-switch__input:disabled) .toggle-switch__icon {
    cursor: not-allowed;
  }
}
