.success-screen {
  display: flex;
  justify-content: center;
  padding: var(--e-space-20) 0;

  @include bp(lg) {
    padding: var(--e-space-14) 0;
  }

  .success-screen__inner {
    max-width: rem(1224);

    @include bp(lg) {
      max-width: rem(404);
    }

    @include bp(m) {
      max-width: none;
    }
  }

  .success-screen__image {
    display: flex;
    justify-content: center;
    margin-bottom: var(--e-space-6);

    .img {
      width: rem(168);
      height: rem(168);
    }
  }

  .success-screen__title {
    @include type(700, strong);

    margin-bottom: var(--e-space-1);
    text-align: center;

    @include bp(m) {
      @include type(500, strong);
    }
  }

  .success-screen__description {
    @include type(300);

    text-align: center;

    @include bp(m) {
      @include type(200);
    }
  }

  .success-screen__cta-container {
    margin-top: var(--e-space-12);
  }
}
