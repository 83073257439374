e-checkbox {
  display: block;
}

.checkbox {
  display: inline-flex;
  flex-wrap: wrap;

  .checkbox__label {
    order: 2;
    flex: 0 0 calc(100% - var(--e-space-11));
    padding-top: var(--e-space-2);

    label {
      display: block;
      font-size: var(--e-type-size-200);
    }
  }

  .checkbox__input {
    z-index: 0;
    order: 1;
    position: relative;
    width: rem(40);
    height: rem(40);
    margin-right: var(--e-space-1);
    background-color: transparent;
    transition: background-color var(--e-trs-duration-faster) var(--e-trs-easing-default);
    border-radius: 100%;

    input {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .checkbox__error-text {
    @include type(50);
    order: 3;

    display: none;
    flex: 1 0 100%;
    margin-top: var(--e-space-1);
    padding-left: var(--e-space-11);
    color: var(--e-c-signal-03-700);
  }

  .checkbox__input-icon {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(18);
    height: rem(18);
    transform: translate(-50%, -50%);
    border: 2px solid var(--e-c-mono-900);
    border-radius: var(--e-brd-radius-1);
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m7 14.42-5-5 1.41-1.41L7 11.59 14.59 4 16 5.42l-9 9Z' fill='%23FFF'/%3E%3C/svg%3E");
    transition: border-color var(--e-trs-duration-faster) var(--e-trs-easing-default), background var(--e-trs-duration-faster) var(--e-trs-easing-default);
  }

  // States
  &.checkbox--checked {
    .checkbox__input-icon {
      border-color: var(--e-c-primary-01-900);
      background-color: var(--e-c-primary-01-900);
    }
  }

  &.checkbox--hover {
    .checkbox__input {
      background-color: var(--e-c-primary-01-50);
    }

    .checkbox__input-icon {
      border-color: var(--e-c-primary-01-900);
    }
  }

  &.checkbox--focus {
    .checkbox__input-icon {
      border-color: var(--e-c-primary-01-900);
    }
  }

  &.checkbox--disabled {
    pointer-events: none;
    opacity: 0.5;

    .checkbox__label {
      color: var(--e-c-mono-500);
    }

    .checkbox__input-icon {
      border-color: var(--e-c-mono-500);
      background-color: var(--e-c-mono-500);
    }
  }

  &.checkbox--error {
    .checkbox__label {
      color: var(--e-c-signal-03-700);
    }

    .checkbox__control-border {
      border: 2px solid var(--e-c-signal-03-700);
    }

    .checkbox__error-text {
      display: block;
    }
  }
}

e-checkbox:not(:defined) {
  opacity: 0;
}
