@use '../../../scss/01_abstracts/design-tokens' as tokens;

.icon-grid {
  .icon-grid__title {
    color: tokens.$c-primary-01-500;
    line-height: tokens.$type-size-500;
    text-align: center;
    margin-bottom: tokens.$space-10;

    @include type(800, strong);

    @include bp(m) {
      margin-bottom: tokens.$space-8;

      @include type(600, strong);
    }

    @include bp(s) {
      @include type(500, strong);
    }
  }

  .icon-grid__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: tokens.$space-10;

    @include bp(lg) {
      grid-gap: tokens.$space-6;
    }

    @include bp(m) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &--bg-grey {
    background-color: tokens.$c-mono-50;
  }
}
