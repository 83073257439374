@mixin img-full-width {
  width: 100%;
  max-width: none;
}

@mixin img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@mixin img-cover {
  max-width: none;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

@mixin img-rounded {
  border-radius: var(--e-brd-radius-1);
}

.img {
  display: block;
  max-width: 100%;
  height: auto;

  // Modifiers
  &.img--full-width {
    @include img-full-width;
  }

  &.img--contain {
    @include img-contain;
  }

  &.img--cover {
    @include img-cover;
  }

  &.img--rounded {
    @include img-rounded;
  }
}

e-img {
  &[fade-in] {
    opacity: 0;
    transition: opacity var(--e-trs-duration-fastest) var(--e-trs-easing-default);
  }

  &[data-state='loaded'] {
    opacity: 1;
  }
}
