.infograph {
  .glide-outer {
    padding-bottom: calc(var(--glide-nav-button-size) + var(--glide-bottom-space-mobile));
  }

  .glide__bullets {
    display: none;
  }

  .glide__arrow--left,
  .glide__arrow--right {
    transform: none;
  }

  .glide__arrow--left {
    top: auto;
    bottom: 0;
    left: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
  }

  .glide__arrow--right {
    top: auto;
    bottom: 0;
    right: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
  }

  @include bp('2xl') {
    .glide-outer {
      padding-bottom: 0;
    }

    .glide__arrow--left,
    .glide__arrow--right {
      top: calc(50% - (var(--glide-nav-button-size) / 2));
      bottom: auto;
    }

    .glide__arrow--left {
      left: calc(0px - var(--e-space-12) - (var(--glide-nav-button-size) / 2));
    }

    .glide__arrow--right {
      right: calc(0px - var(--e-space-12) - (var(--glide-nav-button-size) / 2));
    }
  }

  @include bp(m) {
    .glide__slide {
      padding-bottom: var(--e-space-14);
    }

    .glide__arrow--left,
    .glide__arrow--right {
      display: none;
    }

    .glide__bullets {
      bottom: 0;
      display: inline-flex;
    }
  }
}
