.teaser {
  .teaser__image {
    margin-bottom: var(--e-space-6);
    border-radius: var(--e-brd-radius-1);
    aspect-ratio: 3/2;
    width: 100%;
    overflow: hidden;

    @include bp(m) {
      margin-bottom: var(--e-space-4);
    }
  }

  .teaser__title {
    margin-bottom: var(--e-space-3);

    h2,
    h3 {
      @include type(300, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(m) {
      margin-bottom: var(--e-space-1);

      h2,
      h3 {
        @include type(200, strong);
      }
    }
  }

  .teaser__text {
    @include type(200);

    margin-bottom: var(--e-space-2);
    color: var(--e-c-mono-900);

    @include bp(m) {
      @include type(100);
    }
  }

  &.teaser--tablet-layout {
    @include bp(m) {
      @include grid-row;
    }

    @include bp(s) {
      display: block;
    }

    .teaser__image {
      @include bp(m) {
        @include grid-col(5, 10);

        margin-bottom: 0;
      }

      @include bp(s) {
        @include grid-col(10, 10);

        margin-bottom: var(--e-space-4);
      }
    }

    .teaser__content {
      @include bp(m) {
        @include grid-col(5, 10);
      }

      @include bp(s) {
        @include grid-col(10, 10);
      }
    }
  }
}

e-teaser:not(:defined) {
  opacity: 0;
}

.teaser-link {
  text-decoration: none;
  color: inherit;
}
