.media-carousel {
  --glide-bottom-space-mobile: var(--e-space-7);

  // Glide style overrides
  .glide-outer {
    @include bp(s) {
      padding-bottom: calc(var(--glide-nav-button-size) + var(--glide-bottom-space-mobile));
    }
  }

  // Center nav buttons vertically to image.
  .glide__arrow {
    top: 0;
    margin-top: 0;
    transform: none;
    // We assume that the image has an aspect ratio of 16/9.
    // For different aspect-ratios we could create other modifiers if needed.
    padding-top: calc(((9 / 16 * 100%) / 2) - (var(--glide-nav-button-size) / 2));

    @include bp(s) {
      top: auto;
      bottom: 0;
    }
  }

  .glide__arrow--left {
    left: var(--e-space-4);

    @include bp(s) {
      left: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__arrow--right {
    right: var(--e-space-4);

    @include bp(s) {
      right: calc(50% - var(--glide-nav-button-size) - var(--e-space-2_5));
    }
  }

  .glide__bullets {
    top: 0;
    bottom: auto;
    display: flex;
    padding-top: calc(((9 / 16) * 100%) - var(--e-space-2) - var(--e-space-6));

    @include bp(s) {
      padding-top: calc(((9 / 16) * 100%) - var(--e-space-2) - var(--e-space-2_5));
    }
  }
}

e-media-carousel:not(:defined) {
  opacity: 0;
}
