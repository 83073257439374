.social-links {
  .social-links__list {
    display: flex;
    flex-flow: column;
    min-width: var(--e-space-48);
    padding: var(--e-space-2) 0;
  }

  .social-links__link {
    display: flex;
    align-items: center;
    gap: var(--e-space-3);
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    color: var(--e-c-mono-700);
    padding: var(--e-space-2) var(--e-space-4);
    width: 100%;
    white-space: nowrap;

    @include type-100-strong;

    &:hover {
      color: var(--e-c-secondary-01-900);
      background-color: var(--e-c-primary-01-50);
    }

    svg {
      width: var(--e-space-4);
      height: var(--e-space-4);
    }
  }
}
