.nav-link {
  @include type(200, strong);

  display: inline-flex;
  align-items: center;
  grid-gap: var(--e-space-1);
  padding: var(--e-space-2) var(--e-space-3);
  appearance: none;
  color: var(--e-c-mono-900);
  cursor: pointer;
  text-align: center;
  transition: color $trs-default;
  text-decoration: none;
  vertical-align: middle;

  &:hover,
  &:active {
    color: var(--e-c-secondary-01-900);
  }

  // Won't apply if the nav-link is an <a> tag, obviously.
  &:disabled {
    color: var(--e-c-mono-500);
    pointer-events: none;
  }

  &.active {
    cursor: default;
    color: var(--e-c-primary-01-700);
  }

  e-icon {
    // because of gtm
    pointer-events: none;
  }

  @include bp(lg) {
    padding: var(--e-space-2) 0;
  }
}
