.meta-navigation {
  background-color: var(--e-c-mono-50);

  .meta-navigation__inner {
    display: flex;
    justify-content: flex-end;
    padding: var(--e-space-2) 0;

    @include bp(lg) {
      justify-content: flex-start;
      padding: var(--e-space-3) 0;
    }
  }

  .meta-navigation__list {
    display: flex;
    grid-gap: var(--e-space-4);

    @include bp(lg) {
      flex-direction: column;
      grid-gap: 0;
    }

    @include bp(m) {
      width: 100%;
    }
  }

  .meta-navigation__link {
    @include type(100);

    color: var(--e-c-mono-900);
    text-decoration: none;

    @include bp(lg) {
      @include type(200);

      display: block;
      padding: var(--e-space-3) 0;
    }
  }
}

e-meta-navigation:not(:defined) {
  opacity: 0;
}
