.article-list {
  .article-list__title {
    margin-bottom: var(--e-space-10);

    h2 {
      @include type(800, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-8);

      h2 {
        @include type(700, strong);
      }
    }

    @include bp(m) {
      margin-bottom: var(--e-space-5);

      h2 {
        @include type(500, strong);
      }
    }
  }

  .article-list__list {
    display: flex;
    flex-direction: column;
    grid-gap: var(--e-space-10);

    + .article-list__paginator {
      margin-top: var(--e-space-20);
    }

    @include bp(lg) {
      grid-gap: var(--e-space-8);
    }

    @include bp(m) {
      grid-gap: var(--e-space-5);
    }
  }

  // Modifiers
  &.article-list--inactive-pagination {
    .article-list__paginator {
      display: none;
    }
  }
}

e-article-list:not(:defined) {
  opacity: 0;
}
