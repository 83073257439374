[is='e-article-header'],
.article-header {
  opacity: 0;

  .article-header__intro {
    margin-bottom: calc(var(--e-space-10) * -1);

    @include bp(lg) {
      margin-bottom: calc(var(--e-space-12) * -1);
    }
  }

  .article-header__tags {
    position: relative;
    display: none;
    margin-bottom: var(--e-space-12);
  }

  .article-header__meta {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: var(--e-space-20);

    @include bp(lg) {
      margin-bottom: var(--e-space-10);
    }
  }

  .article-header__modification-date {
    display: none;
  }

  .article-header__article-info {
    position: relative;

    span {
      @include type(200);
    }

    span + span {
      &::before {
        content: '•';
      }
    }

    @include bp(lg) {
      span {
        @include type(100);
      }
    }

    @include bp(m) {
      display: grid;

      span + span {
        &::before {
          content: none;
        }
      }
    }
  }

  .article-header__share {
    margin-left: auto;
  }

  // Modifiers
  &.article-header--has-tags {
    .article-header__tags {
      display: block;
    }
  }

  &.article-header--has-mod-date {
    .article-header__modification-date {
      display: inline;
    }
  }
}
