.intro {
  @include cms-section-spacing-normal;

  position: relative;
  overflow: hidden;

  .intro__spectro {
    position: absolute;
    top: rem(-1300 + 400);
    right: rem(-1300 + 540);
    display: none;
    width: rem(1300);

    @include bp('2xl') {
      right: rem(-1300 + 400);
    }

    @include bp(lg) {
      top: rem(-1300 + 300);
    }

    @include bp(lg) {
      top: rem(-700 + 128);
      right: rem(-700 + 300);
      width: rem(700);
    }
  }

  .intro__inner {
    position: relative;
    padding-top: var(--e-space-10);
    padding-bottom: var(--e-space-10);

    @include bp(lg) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .intro__title {
    margin-bottom: var(--e-space-4);

    h1 {
      @include type(900, strong);

      color: var(--e-c-primary-01-500);
    }

    @include bp(xl) {
      h1 {
        @include type(800, strong);
      }
    }

    @include bp(lg) {
      margin-bottom: var(--e-space-2);

      h1 {
        @include type(700, strong);
      }
    }
  }

  .intro__lead {
    p {
      @include type(600);
    }

    @include bp(xl) {
      p {
        @include type(400);
      }
    }

    @include bp(lg) {
      p {
        @include type(300);
      }
    }
  }

  // Modifiers
  &.intro--has-spectro {
    .intro__spectro {
      display: block;
    }

    .intro__inner {
      @include bp(lg) {
        padding-top: var(--e-space-14);
      }
    }

    .intro__col {
      @include grid-col-space(1, 10, padding-right);

      @include bp(lg) {
        padding-right: 0;
      }
    }
  }
}

e-intro {
  opacity: 0;
}

.hide-e360-branding {
  .intro--has-spectro .intro__spectro {
    display: none;
  }
}
