@mixin button-secondary-02-outlined {
  background-color: transparent;
  color: var(--e-c-secondary-02-500);
  border: 2px solid var(--e-c-secondary-02-500);

  e-icon {
    --fill-color: var(--e-c-secondary-02-500);
  }

  ec-loader {
    --dot-color: var(--e-c-secondary-02-500);
  }

  &:hover {
    background-color: var(--e-c-secondary-02-50);
    border-color: var(--e-c-secondary-02-200);
  }

  &:active,
  &[loading] {
    background-color: transparent;
    border-color: var(--e-c-secondary-02-200);
  }

  &:disabled:not([loading]) {
    border-color: var(--e-c-mono-500);
    background-color: transparent;
    color: var(--e-c-mono-500);

    e-icon {
      --fill-color: var(--e-c-mono-500);
    }
  }
}
